import axios from 'axios'
import {setupCache} from 'axios-cache-adapter'
import cfg from '@/services/cfg'
import auth from '@/services/auth'
import errors from '@/services/errors'
import NetworkError from '@/errors/network'

/**
 * Http service class
 */
class HttpService {

  /**
   * Constructor
   */
  constructor() {

    //Setup
    this.setupCache()
    this.setupAdapters()
    this.setupAxios()
  }

  /**
   * Setup cache
   */
  setupCache() {

    //Create and expose cache for GET requests
    this.cache = setupCache({
      maxAge: 5 * 60 * 1000, //ms
      limit: 100,
      exclude: {
        query: true,
      },
    })
  }

  /**
   * Setup adapters
   */
  setupAdapters() {

    //Get cache
    const {cache} = this

    //Expose adapter
    this.adapter = cache.adapter
  }

  /**
   * Setup axios
   */
  setupAxios() {

    //Get adapter
    const {adapter} = this

    //Setup axios instance
    this.axios = axios.create({
      baseURL: cfg.api.baseUrl,
      adapter,
      headers: {
        common: {'X-Club': cfg.club}, //TEMP: So we know what club to get in production
        post: {'Content-Type': 'application/json'},
        put: {'Content-Type': 'application/json'},
        patch: {'Content-Type': 'application/json'},
      },
    })
  }

  /**
   * Generic request wrapper
   */
  request(config = {}) {

    //Append headers
    this.appendHeaders(config)

    //Convert to JSON
    config.params = this.convertToJSON(config.params)
    config.data = this.convertToJSON(config.data)

    //Perform request
    return this.axios
      .request(config)
      .then(response => {

        //Pass on response data
        return response.data
      })
      .catch(error => this.intercept401Error(error, config))
      .catch(error => this.intercept500Error(error, config))
      .catch(error => {

        //Network error
        if (error.message === 'Network Error') {
          throw new NetworkError()
        }

        //No response?
        if (!error.response) {
          throw error
        }

        //Convert to response error
        const {response} = error
        const ErrorClass = errors.getClass(response)

        //Throw new response error
        throw new ErrorClass(response)
      })
  }

  /**
   * Get request wrapper
   */
  get(url, params, config) {
    config = Object.assign({method: 'get', url, params}, config || {})
    return this.request(config)
  }

  /**
   * Delete request wrapper
   */
  delete(url, params, config) {
    config = Object.assign({method: 'delete', url, params}, config || {})
    return this.request(config)
  }

  /**
   * Post request wrapper
   */
  post(url, data, config) {
    config = Object.assign({method: 'post', url, data}, config || {})
    return this.request(config)
  }

  /**
   * Put request wrapper
   */
  put(url, data, config) {
    config = Object.assign({method: 'put', url, data}, config || {})
    return this.request(config)
  }

  /**
   * Patch request wrapper
   */
  patch(url, data, config) {
    config = Object.assign({method: 'patch', url, data}, config || {})
    return this.request(config)
  }

  /**
   * Upload (POST multipart/form-data) request wrapper
   */
  upload(url, data, config) {
    config = Object.assign({
      method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
      url, data,
    }, config || {})
    return this.request(config)
  }

  /**
   * Convert to JSON
   */
  convertToJSON(data) {

    //Only objects
    if (!data || typeof data !== 'object') {
      return
    }

    //Initialize copy
    //NOTE: We have to make a copy, because otherwise if we pass in say a model,
    //and there's a moment object on that model, it will be converted to a string
    //and break date pickers as a result. This way, we make a copy for the HTTP
    //request and leave the underlying data untouched.
    const json = {}

    //Copy data
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key]
        const isObject = (typeof value === 'object')
        if (value && isObject && typeof value.toJSON === 'function') {
          json[key] = value.toJSON()
        }
        else {
          json[key] = value
        }
      }
    }

    //Return JSON
    return json
  }

  /**
   * Append headers helper
   */
  appendHeaders(config) {

    //Ensure headers set
    if (!config.headers) {
      config.headers = {}
    }

    //Append authentication headers
    if (!this.hasAuthHeader(config)) {
      this.appendAuthHeader(config)
    }
  }

  /**
   * Check if we have an authorization header
   */
  hasAuthHeader(config) {
    const {headers} = config
    return (headers.Authorization || headers.authorization)
  }

  /**
   * Append auth header
   */
  appendAuthHeader(config) {

    //Don't have a token
    if (!auth.hasToken()) {
      return
    }

    //Get underlying access token
    const {accessToken} = auth.getToken()

    //Append to headers
    Object.assign(config.headers, {
      Authorization: `Bearer ${accessToken}`,
    })
  }

  /**
   * Retry a not authenticated request if possible
   */
  async intercept401Error(error, config) {

    //Not a 401 error or ignoring intercepts?
    const {response} = error
    if (!response || response.status !== 401 || config.ignore401) {
      throw error
    }

    //No token?
    if (!auth.hasToken()) {
      throw error
    }

    //Ignore subsequent intercepts
    config.ignore401 = true

    //Refresh access token
    await auth.refresh()

    //Re-append new auth headers
    this.appendAuthHeader(config)

    //Retry request
    return this.request(config)
  }

  /**
   * Intercept server errors
   */
  async intercept500Error(error, config) {

    //Not a server error or ignoring intercepts?
    const {response} = error
    if (!response || response.status !== 500 || config.ignore500) {
      throw error
    }

    //Process error
    errors.process(error)

    //Rethrow
    throw error
  }
}

/**
 * Export singleton instance
 */
export default new HttpService()
