import moment from '@/services/moment'
import BaseModel from './base.model'
import Plan from './plan.model'
import Comment from './comment.model'
import Member from './member.model'
import Address from './shared/address.model'
import ClubBilling from './club/billing.model'
import ClubStripe from './club/stripe.model'
import ClubApi from '@/api/club.api'
import {generateUrl} from '@/helpers/app'
import cfg from '@/services/cfg'

/**
 * Club model
 */
export default class Club extends BaseModel {

  /**
   * Setup
   */
  setup() {

    //Parent method
    super.setup()

    //Sub models
    this.subModels = {
      accountOwner: Member,
      address: Address,
      plan: Plan,
      billing: ClubBilling,
      stripe: ClubStripe,
      comments: [Comment],
    }
  }

  /**************************************************************************
   * Getters
   ***/

  /**
   * Check if we have a subdomain
   */
  get hasSubdomain() {
    return !!this.subdomain
  }

  /**
   * Check if we have a custom domain
   */
  get hasCustomDomain() {
    return !!this.customDomain
  }

  /**
   * Domain
   */
  get domain() {
    const {genericDomain, customDomain} = this
    if (customDomain) {
      return customDomain
    }
    return genericDomain
  }

  /**
   * Generic domain (e.g. xxx.helloclub.com)
   */
  get genericDomain() {
    const {subdomain} = this
    if (subdomain) {
      return `${subdomain}.${cfg.domains.base}`
    }
    return cfg.domains.generic
  }

  /**
   * Generic login URL
   */
  get genericLoginUrl() {
    const {genericDomain, hasSubdomain, identifier} = this
    if (!hasSubdomain) {
      return generateUrl(genericDomain, null, `?club=${identifier}`)
    }
    return generateUrl(genericDomain)
  }

  /**
   * Custom login URL
   */
  get customLoginUrl() {
    const {customDomain} = this
    return generateUrl(customDomain)
  }

  /**
   * Check if club can be removed
   */
  get canRemove() {
    const {isCancelled, isTrial, cancelDate} = this
    if (isCancelled && isTrial) {
      return true
    }
    const threshold = moment().subtract(30, 'days')
    return (isCancelled && cancelDate && cancelDate.isBefore(threshold))
  }

  /**
   * Default billing source
   */
  get defaultSource() {
    const {stripe} = this
    if (!stripe || !stripe.sources) {
      return null
    }
    return stripe.sources.find(s => s.isDefault)
  }

  /**
   * Default billing source brand
   */
  get defaultSourceBrand() {
    const {defaultSource} = this
    if (defaultSource && defaultSource.card && defaultSource.card.brand) {
      return defaultSource.card.brand
    }
    return 'unknown'
  }

  /**************************************************************************
   * API methods
   ***/

  /**
   * Save
   */
  save(data) {
    if (this.id) {
      return this.patch(data)
    }
    else {
      return this.create(data)
    }
  }

  /**
   * Patch
   */
  patch(data, params) {

    //Get ID
    const {id} = this

    //Update
    return ClubApi
      .update(id, data, params)
      .then(data => this.fromJSON(data))
  }

  /**
   * Patch module
   */
  patchModule(moduleId, data) {

    //Get ID
    const {id} = this

    //Update
    return ClubApi
      .patchModule(id, moduleId, data)
      .then(data => this.fromJSON(data))
  }

  /**
   * Change plan
   */
  changePlan(data, options) {

    //Get ID
    const {id} = this

    //Update
    return ClubApi
      .changePlan(id, data, options)
      .then(data => this.fromJSON(data))
  }

  /**
   * Remove
   */
  remove() {

    //Get ID
    const {id} = this

    //Remove
    return ClubApi
      .remove(id)
  }

  /**
   * Create comment
   */
  createComment(text) {

    //Get ID
    const {id} = this

    //Update
    return ClubApi
      .createComment(id, {text})
      .then(data => this.fromJSON(data))
  }

  /**
   * Update comment
   */
  updateComment(commentId, text) {

    //Get ID
    const {id} = this

    //Update
    return ClubApi
      .updateComment(id, commentId, {text})
      .then(data => this.fromJSON(data))
  }

  /**
   * Remove comment
   */
  removeComment(commentId) {

    //Get ID
    const {id} = this

    //Update
    return ClubApi
      .removeComment(id, commentId)
      .then(data => this.fromJSON(data))
  }
}
