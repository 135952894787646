<template>
  <li class="SideBarMenuItem">
    <router-link class="SideBarLink" :to="route">
      <i class="Icon">{{icon}}</i>
      <div class="SideBarLink-label">
        <span>{{label}}</span>
      </div>
    </router-link>
  </li>
</template>

<script>

export default {
  props: {
    route: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="scss">
.SideBarLink {
  position: relative;
  display: block;
  border-radius: $spacing-xs;
  margin-left: $spacing-xs;
  margin-right: $spacing-xs;
  margin-bottom: $spacing-xs;
  color: $white;
  width: 2.5rem;
  height: 2.5rem;
  transform: translate3d(0px, 0px, 0px);
  &.router-link-active {
    .SideBarLink-label {
      //background-color: #08c79e;
      background-color: #4eb9a2;
    }
    .Icon {
      color: $white;
    }
  }
  .Icon {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: fade-out($white, .2);
  }
  &:hover {
    .SideBarLink-label {
      width: auto;
      white-space: nowrap;
      max-width: 20rem;
      background-color: #08c79e;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }
    .Icon {
      color: $white;
    }
  }
}
.SideBarLink-label {
  max-width: 0;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 3px;
  background-color: transparent;
  transition: max-width 0.2s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0s, background-color 0.2s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0s;
  span {
    padding-right: 0.5rem;
  }
}
</style>
