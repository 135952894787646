<template>
  <div v-if="filter">

    <!-- Payment method -->
    <filter-item
      :filter="filter"
      filter-key="method"
      label="Method"
      icon="credit_card"
    >
      <radio-buttons
        :model="filter.method"
        :options="methodOptions"
        @change="filter.update('method', $event.value)"
      />
    </filter-item>

    <!-- Currency -->
    <filter-item
      :filter="filter"
      filter-key="currencyCode"
      label="Currency"
      icon="attach_money"
    >
      <radio-buttons
        :model="filter.currencyCode"
        :options="currencyOptions"
        @change="filter.update('currencyCode', $event.value)"
      />
    </filter-item>
  </div>
</template>

<script>
import OnlinePaymentMethods from '@/constants/online-payment-methods'
import FilterService from '@/services/filter'

export default {
  filter: FilterService.get('onlinePayments'),
  data: () => ({
    methodOptions: [
      {
        label: 'All',
        value: null,
      },
    ].concat(OnlinePaymentMethods),
    currencyOptions: [
      {
        label: 'All (converted to NZD)',
        value: null,
      },
      {
        label: 'NZD',
        value: 'NZD',
      },
      {
        label: 'AUD',
        value: 'AUD',
      },
      {
        label: 'CAD',
        value: 'CAD',
      },
      {
        label: 'USD',
        value: 'USD',
      },
      {
        label: 'EUR',
        value: 'EUR',
      },
      {
        label: 'GBP',
        value: 'GBP',
      },
      {
        label: 'CHF',
        value: 'CHF',
      },
    ],
  }),
}
</script>
