
/**
 * Module defaults
 */
export default {
  kiosk: {
    title: 'Kiosk',
    icon: 'home',
  },
  home: {
    title: 'Home',
    icon: 'home',
  },
  bookings: {
    title: 'Bookings',
    icon: 'date_range',
  },
  calendar: {
    title: 'Calendar',
    icon: 'today',
  },
  profile: {
    title: 'Profile',
    icon: 'person',
  },
  subscription: {
    title: 'Subscriptions',
    icon: 'subscriptions',
  },
  coupons: {
    title: 'Coupons',
    icon: 'loyalty',
    singular: 'coupon',
    plural: 'coupons',
  },
  account: {
    title: 'Account',
    icon: 'payment',
  },
  directory: {
    title: 'Directory',
    icon: 'people',
  },
  club: {
    title: 'Club',
    icon: 'account_balance',
    singular: 'club',
  },
  people: {
    title: 'People',
    icon: 'group',
  },
  members: {
    title: 'Members',
    icon: 'group',
    singular: 'member',
    plural: 'members',
  },
  staff: {
    title: 'Staff',
    icon: 'group',
  },
  contacts: {
    title: 'Contacts',
    icon: 'group',
  },
  events: {
    title: 'Events',
    icon: 'event',
  },
  memberships: {
    title: 'Membership types',
    icon: 'bookmarks',
    singular: 'membership type',
    plural: 'membership types',
  },
  couponTypes: {
    title: 'Coupon types',
    icon: 'local_offer',
    singular: 'coupon type',
    plural: 'coupon types',
  },
  resources: {
    title: 'Resources',
    icon: 'inventory',
    singular: 'resource',
    plural: 'resources',
  },
  activities: {
    title: 'Activities',
    icon: 'fiber_smart_record',
  },
  grades: {
    title: 'Grades',
    icon: 'grade',
    singular: 'grade',
    plural: 'grades',
  },
  areas: {
    title: 'Areas',
    icon: 'border_all',
  },
  finance: {
    title: 'Finance',
    icon: 'attach_money',
  },
  transactions: {
    title: 'Transactions',
    icon: 'attach_money',
  },
  payments: {
    title: 'Payments',
    icon: 'attach_money',
  },
  payouts: {
    title: 'Payouts',
    icon: 'attach_money',
  },
  accountCredit: {
    title: 'Account credit',
    icon: 'attach_money',
  },
  fees: {
    title: 'Fees',
    icon: 'attach_money',
  },
  reports: {
    title: 'Reports',
    icon: 'equalizer',
  },
  logs: {
    title: 'Logs',
    icon: 'playlist_add_check',
  },
  auditLogs: {
    title: 'Audit log',
    icon: 'playlist_add_check',
  },
  accessLogs: {
    title: 'Access log',
    icon: 'playlist_add_check',
  },
  activityLogs: {
    title: 'Activity log',
    icon: 'playlist_add_check',
  },
  checkInLogs: {
    title: 'Check in log',
    icon: 'playlist_add_check',
  },
  visitorLogs: {
    title: 'Visitor log',
    icon: 'playlist_add_check',
  },
  emailLogs: {
    title: 'Email log',
    icon: 'playlist_add_check',
  },
  system: {
    title: 'System',
    icon: 'dvr',
  },
  integrations: {
    title: 'Integrations',
    icon: 'apps',
  },
  settings: {
    title: 'Settings',
    icon: 'settings',
  },
}
