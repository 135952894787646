import BaseApi from './base.api'
import Plan from '@/models/plan.model'

/**
 * Plan API service
 */
class PlanApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/plan',
      dataKey: 'plans',
      Model: Plan,
    })
  }
}

/**
 * Export singleton instance
 */
export default new PlanApi()
