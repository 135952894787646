<template>
  <span>
    <a
    :href="club.genericLoginUrl"
    target="_blank"
    v-tooltip.bottom="club.genericDomain"
  >
    <i class="Icon Icon--xs Icon--inline" :class="{
      'Icon--success': club.hasSubdomain,
      'Icon--faint': !club.hasSubdomain,
    }">open_in_new</i>
  </a>
  <a
    v-if="club.hasCustomDomain"
    :href="club.customLoginUrl"
    target="_blank"
    v-tooltip.bottom="club.customDomain"
  >
    <i class="Icon Icon--xs Icon--inline Icon--info">open_in_new</i>
  </a>
  </span>
</template>

<script>
export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
}
</script>
