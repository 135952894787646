import BaseApi from './base.api'
import NotAuthenticatedError from '@/errors/not-authenticated'

/**
 * User API service
 */
class UserApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/user',
      dataKey: null,
      Model: null,
    })
  }

  /**
	 * Get data for logged in user
	 */
  me() {
    return this
      .get(`me`, null, {
        ignoreCache: true,
      })

      //TEMP: Guard against non-super users being able to login, since we don't
      //have scopes yet. To be removed later when we have refactored the server.
      //Even if they would login, they won't have access to super routes, but
      //it's just tidier to disallow it just in case.
      .then(user => {
        if (!user.roles.includes('super')) {
          throw new NotAuthenticatedError(`Not a super user`)
        }
        return user
      })
  }
}

/**
 * Export singleton instance
 */
export default new UserApi()
