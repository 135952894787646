<template>
  <div class="Modal">
    <form @submit="$event.preventDefault();">
    <div class="ModalHeader">
      <h1>{{title}}</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot />
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isSaving"
        :is-disabled-confirm="isDisabled"
        label-confirm="Save"
        @cancel="$emit('close')"
        @confirm="save()"
      ></button-bar>
    </div>
    </form>
  </div>
</template>

<script>

export default {
  emits: [
    'close',
  ],
  data() {
    return {
      isError: false,
      isSaving: false,
    }
  },
  props: {
    model: {
      type: Object,
    },
    params: {
      type: Object,
    },
    validation: {
      type: Object,
    },
    title: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
    onSave: {
      type: Function,
    },
  },

  /**
   * Methods
   */
  methods: {

    /**
     * Save
     */
    async save() {

      //Get data
      const {model, params, validation} = this

      //Validate form
      if (validation) {
        validation.$touch()
        if (validation.$invalid) {
          return
        }
      }

      //Toggle flags
      this.isSaving = true
      this.isError = false

      //Call handler
      try {
        await this.onSave(model, params)
        this.$emit('close')
      }
      catch (error) {
        //TODO: Need to process JS errors
        this.isError = true
      }
      finally {
        this.isSaving = false
      }
    },
  },
}
</script>
