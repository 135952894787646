<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="false"
    >
      <template v-slot:actions>
        <i @click="mrrReport()" class="Icon Icon--faint PageAction-icon clickable MrrReportIcon" v-tooltip.bottom="'Download MRR report'">download</i>
      </template>
      <template v-slot:filters>
        <plan-history-filters
          :plans="plans"
          :countries="countries"
        />
      </template>
    </page-header>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--period">
          Period
        </div>
        <div class="Table-col Table-col--liveAccounts">
          Live
        </div>
        <div class="Table-col Table-col--paidAccounts">
          Paying
        </div>
        <div class="Table-col Table-col--freeAccounts">
          Free
        </div>
        <div class="Table-col Table-col--trialAccounts">
          Trial
        </div>
        <div class="Table-col Table-col--members">
          Members
        </div>
        <div class="Table-col Table-col--revenue">
          Software MRR
        </div>
        <div class="Table-col Table-col--growth">
          Growth
        </div>
        <div class="Table-col Table-col--avg">
          Average
        </div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--period"><span/></div>
          <div class="Table-col Table-col--liveAccounts"><span/></div>
          <div class="Table-col Table-col--paidAccounts"><span/></div>
          <div class="Table-col Table-col--freeAccounts"><span/></div>
          <div class="Table-col Table-col--trialAccounts"><span/></div>
          <div class="Table-col Table-col--members"><span/><small/></div>
          <div class="Table-col Table-col--revenue"><span/><small/></div>
          <div class="Table-col Table-col--growth"><span/><small/></div>
          <div class="Table-col Table-col--avg"><span/><small/></div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="item in items"
          :key="item.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--flex Table-col--period">
            <div>
              <strong @click="expand(item)" class="Table-link">
                {{getPeriod(item)}}
              </strong>
              <small class="Table-sub" v-if="item.isCurrent">
                Month to date
              </small>
              <small class="Table-sub" v-else>
                End of month
              </small>
            </div>
            <spinner class="Spinner--inline" v-if="item.isExpanding"/>
          </div>
          <div class="Table-col Table-col--liveAccounts">
            {{item.numLive}}
          </div>
          <div class="Table-col Table-col--paidAccounts">
            {{item.numPaid}}
          </div>
          <div class="Table-col Table-col--freeAccounts">
            {{item.numFree}}
          </div>
          <div class="Table-col Table-col--trialAccounts">
            {{item.numTrial}}
          </div>
          <div class="Table-col Table-col--members">
            {{item.numActiveMembers | number}}
            <small class="Table-sub">
              {{item.numArchivedMembers | number}}
            </small>
          </div>
          <div class="Table-col Table-col--revenue">
            {{item.mrr | currency}}
            <small class="Table-sub">
              {{item.arr | currency}}
            </small>
          </div>
          <div class="Table-col Table-col--growth">
            <icon-trend
              :value="item.mrrFactor"
              size="xs"
            />
            <small class="Table-sub">
              {{item.mrrDifference | currency}}
            </small>
          </div>
          <div class="Table-col Table-col--avg">
            {{item.mrrAverage | currency}}
            <small class="Table-sub">
              {{item.arrAverage | currency}}
            </small>
          </div>

          <div class="Table-subRows" v-if="item.isExpanded">
            <div
              v-for="sub in item.sub"
              :key="sub.id"
              class="Table-subRow"
            >
              <div class="Table-col Table-col--plan">
                {{sub.plan.name}}
              </div>
              <div class="Table-col Table-col--liveAccounts">
                {{sub.numLive}}
              </div>
              <div class="Table-col Table-col--paidAccounts">
                {{sub.numPaid}}
              </div>
              <div class="Table-col Table-col--freeAccounts">
                {{sub.numFree}}
              </div>
              <div class="Table-col Table-col--trialAccounts">
                {{sub.numTrial}}
              </div>
              <div class="Table-col Table-col--members">
                {{sub.numActiveMembers | number}}
                <small class="Table-sub">
                  {{sub.numArchivedMembers | number}}
                </small>
              </div>
              <div class="Table-col Table-col--revenue">
                {{sub.mrr | currency}}
                <small class="Table-sub">
                  {{sub.arr | currency}}
                </small>
              </div>
              <div class="Table-col Table-col--growth">
                &nbsp;
              </div>
              <div class="Table-col Table-col--avg">
                {{sub.mrrAverage | currency}}
                <small class="Table-sub">
                  {{sub.arrAverage | currency}}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from '@/services/moment'
import countries from '@/services/countries'
import auth from '@/services/auth'
import cfg from '@/services/cfg'
import PlanApi from '@/api/plan.api'
import PlanHistoryApi from '@/api/plan-history.api'
import PlanHistoryFilters from './plan-history-filters'
import IconTrend from '@/components/shared/icons/icon-trend'

export default {
  components: {
    PlanHistoryFilters,
    IconTrend,
  },
  data() {
    return {

      //Page data
      title: 'Plan history',
      type: 'record',

      //Totals
      totalItems: 0,

      //Flags
      isLoading: true,

      //Data
      items: [],
      plans: [],
      countries: countries.all(),
    }
  },
  computed: {
    hasItems() {
      return this.items.length > 0
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
    currencyKey() {
      const currencyCode = this.filter.currencyCode
      if (!currencyCode) {
        return 'nzd'
      }
      return 'org'
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await Promise.all([
      this.loadPlanHistory(),
      this.loadPlans(),
    ])

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('planHistory')

      //Set defaults
      this.filter.setDefaults({
        group: 'month,year',
        fromDate: moment('01-12-2016', 'DD-MM-YYYY').startOf('day'),
        toDate: moment().endOf('day'),
        day: 1,
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadPlanHistory()
    },

    /**
     * Load plans
     */
    async loadPlans() {
      this.plans = await PlanApi.list()
    },

    /**
     * Load items
     */
    async loadPlanHistory(extra) {

      //Clear current list and flag as loading
      this.items = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter(extra)

      //Query items
      await PlanHistoryApi
        .aggregate(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Download MRR report
     */
    mrrReport() {

      //Serialize and create url
      const query = auth.getQueryString()
      const url = `${cfg.api.baseUrl}/club/mrrReport${query}`

      //Go to URL (as this is a download)
      window.location.href = url
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {items} = data

      //Set properties and flags
      this.items = items
      this.totalItems = items.length
    },

    /**
     * Expand an item
     */
    async expand(item) {

      //Already expanded? Contract again
      if (item.isExpanded) {
        return this.$set(item, 'isExpanded', false)
      }

      //Already has items loaded? Merely show
      if (item.sub) {
        return this.$set(item, 'isExpanded', true)
      }

      //Get filter
      const filter = {
        group: 'plan',
        year: item.year,
        month: item.month,
      }

      //Flag as expanding
      this.$set(item, 'isExpanding', true)

      //Query items
      const items = await PlanHistoryApi
        .aggregate(filter)
        .then(({items}) => items)

      //Set and flag no longer expanding
      this.$set(item, 'sub', items)
      this.$set(item, 'isExpanded', true)
      this.$set(item, 'isExpanding', false)
    },

    /**
     * Get period for item
     *
     * NOTE: The period displayed is the month before, as each item's data is
     * for the first of the month.
     */
    getPeriod(item) {
      const {month, year} = item
      const date = moment().year(year).month(month - 2)
      return date.format('MMMM YYYY')
    },
  },
}
</script>

<style scoped lang="scss">
.Table-col--period, .Table-col--plan {
  flex: 2;
}
.Table-col--liveAccounts,
.Table-col--paidAccounts,
.Table-col--freeAccounts,
.Table-col--trialAccounts,
.Table-col--members,
.Table-col--revenue,
.Table-col--growth
{
  flex: 1;
}
.MrrReportIcon {
  margin-right: 0.5rem;
}
</style>
