import countries from '@/services/countries'

/**
 * Country filter
 */
export default function(code, prop) {
  
  //Find country
  const country = countries.findByCode(code)
  if (!country) {
    return ''
  }

  //Return prop
  return country[prop] || ''
}
