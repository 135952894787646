<template>
  <card
    title="Modules"
    :has-title-spinner="isSaving"
  >
    <div class="ModulesGrid">
      <grid :rows="Math.ceil(numModules / 3)" row-spacing="xs">
        <icon-label
          class="Module clickable"
          v-for="(module, id) in ModuleDefaults"
          :key="id"
          :icon="module.icon"
          :icon-class="{
            'Icon--success': !model[id] || !model[id].isDisabled,
            'Icon--warning': hasModuleChanges(module),
            'Icon--danger': model[id] && model[id].isDisabled,
          }"
          @clickOnLabel="editModule(id)"
          @clickOnIcon="toggleModule(id)"
        >
          <span v-if="model[id] && model[id].title">{{model[id].title}}</span>
          <span v-else>{{module.title}}</span>
          <spinner class="Spinner--inline Spinner--s" v-if="isBusy[id]" />
        </icon-label>
      </grid>
    </div>
  </card>
</template>

<script>
import ModalEditModule from '../modals/edit-module'
import ModuleDefaults from  '@/constants/module-defaults'

export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {

      //Flags
      isSaving: false,
      isBusy: Object
        .keys(ModuleDefaults)
        .reduce((map, key) => {
          map[key] = false
          return map
        }, {}),

      //Model
      model: [],

      //Module defaults
      ModuleDefaults,
    }
  },
  computed: {
    numModules() {
      return Object.keys(ModuleDefaults).length
    },
  },
  async created() {

    //Extract modules model
    this.model = this.club.extract('modules')
  },
  methods: {

    /**
     * Is busy with check
     */
    isBusyWith(id) {
      console.log(id, this.isBusy[id])
      return !!this.isBusy[id]
    },

    /**
     * Edit a module
     */
    editModule(id) {

      //Create model with defaults merged in
      const defaults = ModuleDefaults[id]
      const module = Object.assign({}, defaults, this.model[id] || {})

      //Create save handler
      const onSave = async (model) => {

        //Remove values if same as defaults
        if (model.title === defaults.title) {
          delete model.title
        }
        if (model.singular === defaults.singular) {
          delete model.singular
        }
        if (model.plural === defaults.plural) {
          delete model.plural
        }

        //Defaults
        if (Object.keys(model).length === 0) {
          delete this.model[id]
        }

        //Store what's changed
        else {
          this.model[id] = model
        }

        //Save
        await this.save(id, model, false)
      }

      //Open editing modal
      this.$modal.show(ModalEditModule, {
        module, defaults, onSave,
      })
    },

    /**
     * Toggle a module
     */
    toggleModule(id) {

      //Not when saving
      if (this.isSaving) {
        return
      }

      //Enable module if disabled
      if (this.model[id] && this.model[id].isDisabled) {
        delete this.model[id]
        this.save(id, {isDisabled: false}, false)
      }
      else {
        this.model[id] = {isDisabled: true}
        this.save(id, {isDisabled: true}, false)
      }
    },

    /**
     * Check if a specific module has changes compared to the default module
     */
    hasModuleChanges(module) {

      //Get the module id
      const {id} = module

      //If the module is set in the model, it means we have some customisations
      if (this.model[id] && !this.model[id].isDisabled) {
        return true
      }

      //No changes
      return false
    },

    /**
     * Save modules
     */
    async save(id, data, toggleFlag = true) {

      //Toggle flag
      if (toggleFlag) {
        this.isSaving = true
      }

      //Mark module as busy
      this.isBusy[id] = true

      //Patch
      await this.club
        .patchModule(id, data)
        .finally(() => {
          this.isSaving = false
          this.isBusy[id] = false
        })

      //Extract new model from club
      this.model = this.club.extract('modules')
    },
  },
}
</script>

<style scoped lang="scss">
.ModulesGrid {
  margin-top: $spacing-m;
}
</style>
