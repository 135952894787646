<template>
  <div v-if="filter">

    <!-- Method -->
    <filter-item
      :filter="filter"
      filter-key="method"
      label="Method"
      icon="domain"
    >
      <radio-buttons
        :model="filter.method"
        :options="methodOptions"
        @change="filter.update('method', $event.value)"
      />
    </filter-item>

    <!-- Status -->
    <filter-item
      :filter="filter"
      filter-key="status"
      label="Status codes"
      icon="link"
    >
      <radio-buttons
        :model="filter.status"
        :options="statusOptions"
        @change="filter.update('status', $event.value)"
      />
    </filter-item>

    <!-- From Date -->
    <filter-item
      :filter="filter"
      filter-key="fromDate"
      label="From date"
      icon="today"
    >
      <date-picker
        :model="filter.fromDate"
        :max-date="filter.toDate"
        :can-clear="true"
        @change="filter.update('fromDate', $event.date)"
      />
    </filter-item>

     <!-- To Date -->
    <filter-item
      :filter="filter"
      filter-key="toDate"
      label="To date"
      icon="event"
    >
      <date-picker
        :model="filter.toDate"
        :min-date="filter.fromDate"
        :can-clear="true"
        @change="filter.update('toDate', $event.date)"
      />
    </filter-item>

  </div>
</template>

<script>
import FilterService from '@/services/filter'
import moment from '@/services/moment'

export default {
  data: () => ({
    filter: FilterService.get('requestLog'),
    methodOptions: [
      {
        label: 'All',
        value: null,
      },
      {
        value: 'POST',
        label: 'POST',
      },
      {
        value: 'GET',
        label: 'GET',
      },
      {
        value: 'PUT',
        label: 'PUT',
      },
      {
        value: 'PATCH',
        label: 'PATCH',
      },
      {
        value: 'DELETE',
        label: 'DELETE',
      },
    ],
    statusOptions: [
      {
        label: 'All',
        value: null,
      },
      {
        value: 200,
        label: '2xx',
      },
      {
        value: 300,
        label: '3xx',
      },
      {
        value: 400,
        label: '4xx',
      },
      {
        value: 500,
        label: '5xx',
      },
    ],

    //Today
    today: moment().startOf('day'),
  }),
}
</script>
