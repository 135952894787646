
/**
 * Number filter
 */
export default function(number, options) {

  //Default config
  const defaults = {
    useGrouping: true,
    maximumFractionDigits: 0,
  }

  //Merge options and create formatter
  options = Object.assign({}, defaults, options)
  const formatter = new Intl.NumberFormat('en-NZ', options)

  //Format number
  return formatter.format(number)
}
