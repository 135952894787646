import cfg from '@/services/cfg'

/**
 * Mutations for the Page state
 */
export default {

  /**
   * Set page title
   */
  setPageTitle(state, title) {
    state.title = title
    if (title) {
      document.title = `${title} | ${cfg.app.title}`
    }
    else {
      document.title = cfg.app.title
    }
  },

  /**
   * Clear page title
   */
  clearPageTitle(state) {
    state.title = ''
  },

  /**
   * Set loadig state
   */
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
}
