import moment from 'moment'
import AuthApi from '@/api/auth.api'
import cfg from '@/services/cfg'
import {fromBase64} from '@/helpers/string'

//Storage path for token
export const storagePath = `${cfg.storage.prefix}.auth.accessToken`

/**
 * Token model
 */
export default class Token {

  /**
   * Constructor
   */
  constructor(accessToken) {

    //Get payload
    const payload = Token.decode(accessToken)
    if (!payload) {
      throw new Error(`Invalid access token`)
    }

    //Remember payload and raw access token string
    this.payload = payload
    this.accessToken = accessToken

    //Parse exp/iat values
    this.expires = moment.unix(payload.exp)
    this.issued = moment.unix(payload.iat)
    this.scope = (payload.scope || '').split(' ')
  }

  /**
   * Check if the token has expired
   */
  get hasExpired() {
    const now = moment()
    return (this.expires && this.expires.isBefore(now))
  }

  /**
   * Check if the token is expiring within the given offset
   */
  isExpiring(offset) {
    const reference = moment().add(offset, 'seconds')
    return (this.expires && this.expires.isBefore(reference))
  }

  /**
   * Store underlying access token
   */
  store() {
    Token.store(this.accessToken)
    return this
  }

  /**
   * Clear underlying access token
   */
  clear() {
    Token.clear()
    return null
  }

  /**************************************************************************
   * Storage helpers
   ***/

  /**
   * Read access token from local storage
   */
  static read() {
    return localStorage.getItem(storagePath) || ''
  }

  /**
   * Store access token in local storage
   */
  static store(accessToken) {
    localStorage.setItem(storagePath, accessToken)
  }

  /**
   * Clear access token from local storage
   */
  static clear() {
    localStorage.removeItem(storagePath)
  }

  /**************************************************************************
   * Decoding helpers
   ***/

  /**
   * Decode access token data
   */
  static decode(accessToken) {

    //Nothing?
    if (!accessToken) {
      return null
    }

    //Split in parts
    const parts = accessToken.split('.')
    if (parts.length !== 3) {
      return null
    }

    //Get decoded payload
    try {
      const decoded = fromBase64(parts[1])
      return JSON.parse(decoded)
    }
    catch (e) {
      return null
    }
  }

  /**************************************************************************
   * Helpers to obtain tokens
   ***/

  /**
   * Get existing access token
   */
  static existing() {

    //Read access token
    const accessToken = Token.read()
    if (!accessToken) {
      return null
    }

    //Instantiate
    return new Token(accessToken)
  }

  /**
   * Obtain access token from server
   */
  static async obtain(grantType, data) {

    //Prepare request
    const {clientId} = cfg.auth
    const request = Object.assign({clientId, grantType}, data || {})

    //Get authentication data
    const {accessToken} = await AuthApi.token(request)
    return new Token(accessToken)
  }

  /**
   * Use a provided access token
   */
  static from(accessToken) {
    return new Token(accessToken)
  }
}
