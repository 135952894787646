import store from '@/store'
import auth from '@/services/auth'
import countries from '@/services/countries'

/**
 * Clear page
 */
export function clearPage(to, from, next) {
  store.dispatch('page/clear')
  next()
}

/**
 * Mark page as loading
 */
export function markPageAsLoading(to, from, next) {
  store.dispatch('page/loading', true)
  next()
}

/**
 * Mark session as loaded
 */
export function markSessionAsLoaded(to, from, next) {
  store.dispatch('session/loaded', true)
  next()
}

/**
 * load countries
 */
export async function loadCountries(to, from, next) {
  if (auth.isAuthenticated()) {
    await countries.load()
  }
  next()
}
