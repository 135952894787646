<template>
  <div class="PageCrumbs">
    <div class="PageCrumbWrapper" v-for="(crumb, i) in crumbs" :key="crumb.title">
      <div class="PageCrumb PageCrumb--chevron" v-if="i !== 0">
        <i class="Icon PageCrumb-icon">chevron_right</i>
      </div>
      <div class="PageCrumb">
        <router-link class="PageCrumb-link" :to="crumb.route">
          {{crumb.title}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    crumbs: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.PageCrumbs {
  display: flex;
  align-items: flex-start;
  font-size: $font-xl;
}
.PageCrumbWrapper {
  display: flex;
  align-items: flex-start;
}
.PageCrumb {
  margin-right: $spacing-xxs;
  &:last-child:not(:only-child) {
    .PageCrumb-link {
      color: $green;
    }
  }
}
.PageCrumb--chevron {
  color: $oslo;
}
.PageCrumb-icon {
  vertical-align: -4px;
}
.PageCrumb-link {
  color: $slate;
}
</style>
