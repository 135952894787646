<template>
  <card
    title="Connected integrations"
  >
    <div :class="$style.gridWrapper">
      <grid :rows="integrations.length" row-spacing="s">
        <integration
          v-for="integration in integrations"
          :key="integration.id"
          :integration="integration"
        />
      </grid>
    </div>
  </card>
</template>

<script>
import IntegrationApi from '@/api/integration.api'
import Integration from '../integration'

export default {
  components: {
    Integration,
  },
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isSaving: false,
      integrations: [],
    }
  },
  async created() {

    //Load integrations
    await this.loadIntegrations()
  },
  methods: {

    /**
     * Load integrations
     */
    async loadIntegrations() {

      //Get data
      const {club} = this

      //Load integrations
      await IntegrationApi
        .query({club: club.id})
        .then(data => {
          this.integrations = data.integrations
            .filter(integration => integration.isConnected === true)
        })
    },
  },
}
</script>

<style module lang="scss">
.gridWrapper {
  margin-top: $spacing-m;
}
</style>
