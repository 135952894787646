<template>
  <modal-edit
    title="Edit account owner"
    :model="model"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <grid cols="2" row-spacing="m">
        <group
          label="Account owner"
          :validation="$v.model.accountOwner"
        >
          <select-box
            :options="admins"
            :model="model.accountOwner"
            track-by="id"
            label-by="name"
            @change="$set(model, 'accountOwner', $event.value)"
          />
        </group>
      </grid>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import {required} from 'vuelidate/lib/validators'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {

      //Model
      model: {
        accountOwner: null,
      },
    }
  },
  props: {
    club: {
      type: Object,
    },
    admins: {
      type: Array,
      default: () => [],
    },
    onSave: {
      type: Function,
    },
  },
  validations: {
    model: {
      accountOwner: {required},
    },
  },
  created() {

    //Get data
    const {club} = this

    //Create model subset
    this.model = {
      accountOwner: club.extract('accountOwner'),
    }
  },
}
</script>
