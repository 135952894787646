<template>
  <modal-edit
    title="Edit module"
    :model="model"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <group
        label="Title"
        :validation="$v.model.title"
      >
        <input
          class="Input"
          type="text"
          v-model.trim="model.title"
        >
      </group>
      <grid cols="2" row-spacing="m" v-if="hasSingularPlural">
        <group
          label="Singular"
          :validation="$v.model.singular"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.singular"
          >
        </group>
        <group
          label="Plural"
          :validation="$v.model.plural"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.plural"
          >
        </group>
      </grid>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import {extract} from '@/models/base.model'
import {required} from 'vuelidate/lib/validators'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {
      model: {
        title: null,
        singular: null,
        plural: null,
      },
    }
  },
  props: {
    module: {
      type: Object,
    },
    onSave: {
      type: Function,
    },
  },
  computed: {
    hasSingularPlural() {
      return (this.module.singular && this.module.plural)
    },
  },
  validations: {
    model: {
      title: {
        required,
      },
    },
  },
  created() {

    //Create model subset
    this.model = extract(this.module, Object.keys(this.model))

    //Add additional validations if needed
    if (this.hasSingularPlural) {
      this.$v.model.singular = {required}
      this.$v.model.plural = {required}
    }
  },
}
</script>
