import BaseApi from './base.api'
import TagOrder from '@/models/tag-order.model'

/**
 * Tag order API service
 */
class TagOrderApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/tagOrder',
      dataKey: 'tagOrders',
      Model: TagOrder,
    })
  }
}

/**
 * Export singleton instance
 */
export default new TagOrderApi()
