<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm">

      <!-- Generic error -->
      <div v-if="isGenericError">
        <h2>Error</h2>
        <p>Something went wrong on our end. Please try again or come back later.</p>
      </div>

      <!-- Network error -->
      <div v-if="isNetworkError">
        <h2>Network error</h2>
        <p>There seem to be some network issues. Please check your internet connection and try again.</p>
      </div>

      <!-- Not found error -->
      <div v-if="isNotFoundError">
        <h2>Not found</h2>
        <p>Resource not found.</p>
      </div>

      <!-- Not authorized error -->
      <div v-if="isNotAuthorizedError">
        <h2>Not authorized</h2>
        <p>You don’t have acces to this resource</p>
      </div>

      <!-- Back button -->
      <div class="Block">
        <button
          class="Button Button--s"
          @click="back()"
        >Go back</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NetworkError,
  NotFoundError,
  NotAuthorizedError
} from '@/errors/index'

export default {
  props: {
    error: Error,
  },
  computed: {
    isNetworkError() {
      return (this.error instanceof NetworkError)
    },
    isNotFoundError() {
      return (this.error instanceof NotFoundError)
    },
    isNotAuthorizedError() {
      return (this.error instanceof NotAuthorizedError)
    },
    isGenericError() {
      return (
        !this.isNetworkError &&
        !this.isNotFoundError &&
        !this.isNotAuthorizedError
      )
    },
  },

  /**
   * Before mount
   */
  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Error',
    })
  },

  /**
   * Methods
   */
  methods: {

    /**
     * Go back
     */
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
