<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="true"
    >
      <template v-slot:filters>
        <club-filters
          :plans="plans"
          :countries="countries"
          @change="filter.update($event.key, $event.value)"
        />
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--flag"></div>
        <div class="Table-col Table-col--name">
          <sort
            :sort="filter.sort"
            field="name"
            @toggle="filter.update('sort', $event.sort)"
          >Name</sort>
        </div>
        <div class="Table-col Table-col--numActiveMembers">
          <sort
            :sort="filter.sort"
            field="numActiveMembers"
            @toggle="filter.update('sort', $event.sort)"
          >Members</sort>
        </div>
        <div class="Table-col Table-col--monthlyRevenue">
          <sort
            :sort="filter.sort"
            field="monthlyRevenue"
            @toggle="filter.update('sort', $event.sort)"
          >MRR</sort>
        </div>
        <div class="Table-col Table-col--plan">
          <sort
            :sort="filter.sort"
            field="plan"
            @toggle="filter.update('sort', $event.sort)"
          >Plan</sort>
        </div>
        <div class="Table-col Table-col--signUpDate">
          <sort
            :sort="filter.sort"
            field="signUpDate"
            @toggle="filter.update('sort', $event.sort)"
          >Signed up</sort>
        </div>
        <div class="Table-col Table-col--lastActive">
          <sort
            :sort="filter.sort"
            field="lastActive"
            @toggle="filter.update('sort', $event.sort)"
          >Last active</sort>
        </div>
        <div class="Table-col Table-col--setupProgress">
          <sort
            :sort="filter.sort"
            field="setup.progress"
            @toggle="filter.update('sort', $event.sort)"
          >Setup</sort>
        </div>
        <div class="Table-col Table-col--engagementScore">
          <sort
            :sort="filter.sort"
            field="engagement.score"
            @toggle="filter.update('sort', $event.sort)"
          >Eng</sort>
        </div>
        <div class="Table-col Table-col--status">
          Status
        </div>
        <div class="Table-col Table-col--options">&nbsp;</div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--flag"><span/></div>
          <div class="Table-col Table-col--name"><span/><small/></div>
          <div class="Table-col Table-col--numActiveMembers"><span/></div>
          <div class="Table-col Table-col--monthlyRevenue"><span/></div>
          <div class="Table-col Table-col--plan"><span/></div>
          <div class="Table-col Table-col--signUpDate"><span/><small/></div>
          <div class="Table-col Table-col--lastActive"><span/><small/></div>
          <div class="Table-col Table-col--setupProgress"><span/><small/></div>
          <div class="Table-col Table-col--engagementScore"><span/><small/></div>
          <div class="Table-col Table-col--status"><span/><small/></div>
          <div class="Table-col Table-col--options">
            <i class="Icon">more_vert</i>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="club in clubs"
          :key="club.id"
          class="Table-row" :class="{'Table-row--faded': club.isCancelled}"
        >
          <div class="Table-col Table-col--flag">
            <span class="Flag clickable" v-tooltip.bottom="getCountryName(club.countryCode)">{{club.countryCode | country('flag')}}</span>
          </div>
          <div class="Table-col Table-col--name">
            <router-link class="Table-link" :to="`/clubs/edit/${club.id}`">
              {{club.name}}
            </router-link>
            <small class="Table-sub">
              {{club.identifier}}
              <club-links :club="club" />
            </small>
          </div>
          <div class="Table-col Table-col--numActiveMembers">
            <span
              :class="{
                'text-warning': (
                  club.numActiveMembers >= club.permissions.memberSoftLimit &&
                  club.numActiveMembers < club.permissions.memberHardLimit
                ),
                'text-danger': club.numActiveMembers >= club.permissions.memberHardLimit,
              }"
            >{{club.numActiveMembers}}</span>
            <small class="Table-sub">
              {{club.permissions.memberSoftLimit}}
            </small>
          </div>
          <div class="Table-col Table-col--monthlyRevenue">
            <span v-if="club.monthlyRevenue > 0">
              {{club.monthlyRevenue | currency}}
            </span>
          </div>
          <div class="Table-col Table-col--plan">
            <pill class="PlanPill PlanPill--trial" v-if="club.isTrial || club.hasTrialEnded">
              Trial
            </pill>
            <pill class="PlanPill PlanPill--partner" v-else-if="club.isPartnerAccount">
              Partner
            </pill>
            <pill class="PlanPill" :class="`PlanPill--${club.plan.identifier}`" v-else>
              {{club.plan.name}}
            </pill>
          </div>
          <div class="Table-col Table-col--signUpDate">
            {{club.signUpDate | moment}}
            <small class="Table-sub">{{club.signUpDate | moment('fromNow')}}</small>
          </div>
          <div class="Table-col Table-col--lastActive">
            {{club.lastActive | moment}}
            <small class="Table-sub">{{club.lastActive | moment('fromNow')}}</small>
          </div>
          <div class="Table-col Table-col--setupProgress">
            <span
              :class="{
                'text-danger': club.setup.progress < 50,
                'text-warning': club.setup.progress >= 50 && club.setup.progress < 75,
                'text-success': club.setup.progress >= 75,
              }"
            >{{club.setup.progress}}%</span>
            <small class="Table-sub">{{club.setup.numStepsCompleted}} of {{club.setup.numSteps}}</small>
          </div>
          <div class="Table-col Table-col--engagementScore">
            <span
              :class="{
                'text-danger': club.engagement.score < 0.4,
                'text-warning': club.engagement.score >= 0.4 && club.engagement.score < 0.6,
                'text-success': club.engagement.score >= 0.6,
              }"
            >{{club.engagement.score | percentage({maximumFractionDigits: 2})}}</span>
            <small class="Table-sub" v-if="club.engagement.score >= 0.6">Good</small>
            <small class="Table-sub" v-else-if="club.engagement.score >= 0.4">Avg</small>
            <small class="Table-sub" v-else>Poor</small>
          </div>
          <div class="Table-col Table-col--status">
            <span v-if="club.isCancelled" class="text-danger">Cancelled</span>
            <span v-else-if="club.isTrial || club.hasTrialEnded" class="text-warning">Trial</span>
            <span v-else class="text-success">Live</span>
            <small v-if="club.isCancelled" class="Table-sub">
              {{club.cancelDate | moment('fromNow')}}
            </small>
            <small v-else-if="club.isTrial" class="Table-sub">
              Ends {{club.trialEndDate | moment('fromNow')}}
            </small>
            <small v-else-if="club.hasTrialEnded" class="Table-sub">
              Ended {{club.trialEndDate | moment('fromNow')}}
            </small>
            <small v-else-if="club.planExpirationDate" class="Table-sub">
              Expires {{club.planExpirationDate | moment('fromNow')}}
            </small>
            <small v-else class="Table-sub">
              &nbsp;
            </small>
          </div>
          <div class="Table-col Table-col--options">
            <club-options
              :club="club"
              @checkIn="checkIn(club)"
              @remove="remove(club)"
              @cancel="cancel(club)"
              @reactivate="reactivate(club)"
            ></club-options>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>
  </div>
</template>

<script>
import moment from '@/services/moment'
import countries from '@/services/countries'
import ClubApi from '@/api/club.api'
import PlanApi from '@/api/plan.api'
import ClubOptions from './club-options'
import ClubFilters from './club-filters'
import ClubLinks from './club-links'
import ClubRemove from './modals/remove'
import ClubCancel from './modals/cancel'
import ClubReactivate from './modals/reactivate'

export default {
  components: {
    ClubOptions,
    ClubFilters,
    ClubLinks,
  },
  data() {
    return {

      //Page data
      title: 'Clubs',
      type: 'club',

      //Pagination
      page: 1,
      itemsPerPage: 50,
      totalItems: 0,

      //Fields to query
      fields: [
        'cancelDate',
        'countryCode',
        'customDomain',
        'customPermissions',
        'engagement',
        'hasTrialEnded',
        'identifier',
        'isCancelled',
        'isPartnerAccount',
        'isLive',
        'isTrial',
        'lastActive',
        'modules',
        'monthlyRevenue',
        'name',
        'numActiveMembers',
        'permissions',
        'planExpirationDate',
        'plan',
        'setup',
        'signUpDate',
        'stripe',
        'subdomain',
        'timezone',
        'trialEndDate',
        'type',
      ],

      //Flags
      isLoading: true,

      //Data
      clubs: [],
      plans: [],
      countries: countries.all(),
    }
  },
  computed: {
    hasItems() {
      return this.clubs.length > 0
    },
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await Promise.all([
      this.loadClubs(),
      this.loadPlans(),
    ])

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('clubs')

      //Set defaults
      this.filter.setDefaults({
        search: '',
        sort: '-signUpDate',
        status: 'all',
        active: 'all',
        progress: 'all',
        term: 'all',
        lastCheckIn: 'all',
      })

      //Map status values to underlying filter values
      this.filter.map('status', {
        all: {
          isLive: null,
          isTrial: null,
          isCancelled: null,
        },
        cancelled: {
          isCancelled: true,
        },
        live: {
          isLive: true,
        },
        trial: {
          isCancelled: false,
          isTrial: true,
        },
        partner: {
          isPartnerAccount: true,
        },
      })

      //Map term values to underlying filter values
      this.filter.map('term', {
        all: {
          term: null,
        },
        yearly: {
          term: 'year',
        },
        monthly: {
          term: 'month',
        },
      })

      //Map active values to underlying filter values
      this.filter.map('active', {
        all: {
          lastActiveAfter: null,
        },
        active: {
          lastActiveAfter: moment().subtract(1, 'month'),
        },
        inactive: {
          lastActiveBefore: moment().subtract(1, 'month'),
        },
      })

      //Map check in dates values to underlying filter values
      this.filter.map('lastCheckIn', {
        never: {
          hasNeverCheckedIn: true,
          lastCheckInBefore: null,
          lastCheckInAfter: null,
        },
        all: {
          lastCheckInBefore: null,
          lastCheckInAfter: null,
        },
        lessOneMonth: {
          lastCheckInBefore: moment().subtract(1, 'month'),
        },
        lessThreeMonths: {
          lastCheckInBefore: moment().subtract(3, 'months'),
        },
        lessSixMonths: {
          lastCheckInBefore: moment().subtract(6, 'months'),
        },
        lessTwelveMonths: {
          lastCheckInBefore: moment().subtract(12, 'months'),
        },
        moreOneMonth: {
          lastCheckInAfter: moment().subtract(1, 'month'),
        },
        moreThreeMonths: {
          lastCheckInAfter: moment().subtract(3, 'months'),
        },
        moreSixMonths: {
          lastCheckInAfter: moment().subtract(6, 'months'),
        },
        moreTwelveMonths: {
          lastCheckInAfter: moment().subtract(12, 'months'),
        },
      })

      //Map status values to underlying filter values
      this.filter.map('progress', value => {
        if (value === 'all') {
          return {
            minProgress: null,
            maxProgress: null,
          }
        }
        const dir = value.charAt(0)
        const amount = Number(value.substring(1))
        if (dir === '<') {
          return {
            minProgress: 0,
            maxProgress: amount,
          }
        }
        else {
          return {
            minProgress: amount,
            maxProgress: 100,
          }
        }
      })

      //Map status values to underlying filter values
      this.filter.map('engagement', value => {
        if (value === 'all') {
          return {
            minEngagement: null,
            maxEngagement: null,
          }
        }
        const dir = value.charAt(0)
        const amount = Number(value.substring(1))
        if (dir === '<') {
          return {
            minEngagement: 0,
            maxEngagement: amount,
          }
        }
        else {
          return {
            minEngagement: amount,
            maxEngagement: 100,
          }
        }
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadClubs()
    },

    /**
     * Load plans
     */
    async loadPlans() {
      this.plans = await PlanApi.list()
    },

    /**
     * Load clubs
     */
    async loadClubs(extra) {

      //Clear current list and flag as loading
      this.clubs = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter(extra)

      //Query clubs
      await ClubApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()
      const fields = this.fields.join(',')
      const {page, itemsPerPage} = this

      //Append fields
      if (fields) {
        filter.fields = fields
      }

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage
        filter.offset = (page - 1) * itemsPerPage
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {meta, clubs} = data

      //Set properties and flags
      this.clubs = clubs
      this.totalItems = meta.total
    },

    /**
     * Get country name
     */
    getCountryName(code) {
      return this.$options.filters.country(code, 'name')
    },

    /**
     * Check-in
     */
    async checkIn(club) {

      //Create new date
      const checkInDate = moment()

      //Update check-in date
      await club.patch({checkInDate})
      this.$notice.show(`Checked in!`)
    },

    /**
     * Cancel account
     */
    cancel(club) {

      //Create handler
      const onConfirm = () => club
        .patch({isCancelled: true})
        .then(() => this.onCancelled(club))

      //Open confirmation modal
      this.$modal.show(ClubCancel, {
        club, onConfirm,
      })
    },

    /**
     * Reactivate account
     */
    reactivate(club) {

      //Create handler
      const onConfirm = () => club
        .patch({isCancelled: false})
        .then(() => this.onReactivated(club))

      //Open confirmation modal
      this.$modal.show(ClubReactivate, {
        club, onConfirm,
      })
    },

    /**
     * Remove
     */
    remove(club) {

      //Create handler
      const onConfirm = () => club
        .remove()
        .then(() => this.onRemoved(club))

      //Open confirmation modal
      this.$modal.show(ClubRemove, {
        club, onConfirm,
      })
    },

    /**
     * On removed handler
     */
    onRemoved(club) {

      //Show notice
      this.$notice.show(`Club is being removed`)

      //Remove club from list
      const i = this.clubs.findIndex(c => c.isSame(club))
      if (i !== -1) {
        this.clubs.splice(i, 1)
      }
    },

    /**
     * On cancelled handler
     */
    onCancelled() {
      this.$notice.show(`Club account cancelled`)
    },

    /**
     * On reactivated handler
     */
    onReactivated() {
      this.$notice.show(`Club account reactivated`)
    },
  },
}
</script>

<style scoped lang="scss">
.Table-col--flag {
  flex: 0 0 2rem;
}
.Table-col--name {
  flex: 1;
}
.Table-col--monthlyRevenue {
  flex: 0 0 6rem;
  @include maxWidth-m {
    display: none;
  }
}
.Table-col--numActiveMembers {
  flex: 0 0 7rem;
  @include maxWidth-s {
    display: none;
  }
}
.Table-col--signUpDate {
  flex: 0 0 8rem;
  @include maxWidth-l {
    display: none;
  }
}
.Table-col--lastActive {
  flex: 0 0 8rem;
  @include maxWidth-l {
    display: none;
  }
}
.Table-col--plan {
  flex: 0 0 8rem;
}
.Table-col--setupProgress,
.Table-col--engagementScore {
  flex: 0 0 5rem;
  @include maxWidth-l {
    display: none;
  }
}
.Table-col--status {
  flex: 0 0 9rem;
}
</style>
