import merge from 'deepmerge'
import production from './production'

/**
 * Staging environment configuration
 */
export default merge.all([production, {

  //Admin portal
  portal: {
    baseUrl: 'https://staging-admin.helloclub.com',
  },

  //App
  app: {
    baseUrl: 'https://staging.helloclub.com',
  },

  //API
  api: {
    baseUrl: 'https://staging-api.helloclub.com',
  },

  //Error handling
  errors: {
    log: true,
  },
}])
