import BaseApi from './base.api'
import Feedback from '@/models/feedback.model'

/**
 * Feedback API service
 */
class FeedbackApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/feedback',
      dataKey: 'feedback',
      Model: Feedback,
    })
  }
}

/**
 * Export singleton instance
 */
export default new FeedbackApi()
