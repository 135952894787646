import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

/**
 * Session state object
 */
const state = {
  user: null,
  isLoaded: false,
}

/**
 * Export as Vuex module
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
