import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import cfg from '@/services/cfg'
import AuthApi from '@/api/auth.api'
import Token from '@/models/token.model'
import EventEmitter from '@/classes/event-emitter'

/**
 * Create mixin to load the authentication service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.auth) {
      this.$auth = options.auth
    }
    else if (options.parent && options.parent.$auth) {
      this.$auth = options.parent.$auth
    }
  },
})

/**
 * Auth service
 */
class AuthService extends EventEmitter {

  /**
   * Check if we have a valid token which hasn't expired yet
   */
  isAuthenticated() {
    return (this.token && !this.token.hasExpired)
  }

  /**
   * Check if we have a token which has expired
   */
  hasAuthenticationExpired() {
    return (this.token && this.token.hasExpired)
  }

  /**
   * Check if authentication is about to expire
   */
  isAuthenticationExpiring(offset = 60) {
    return (this.token && this.token.isExpiring(offset))
  }

  /**************************************************************************
   * Token handling
   ***/

  /**
   * Load token
   */
  loadToken() {
    this.token = Token.existing()
  }

  /**
   * Store token
   */
  storeToken(token) {
    this.token = token
    this.token.store()
  }

  /**
   * Clear token
   */
  clearToken() {
    if (this.token) {
      this.token.clear()
      this.token = null
    }
  }

  /**
   * Check if we have a token
   */
  hasToken() {
    return !!this.token
  }

  /**
   * Get token
   */
  getToken() {
    return this.token
  }

  /**
   * Get query string
   */
  getQueryString() {

    //No access token
    if (!this.token) {
      return ''
    }

    //Return query string
    return `?access_token=${this.token.accessToken}`
  }

  /**************************************************************************
   * Authentication flow control
   ***/

  /**
	 * Login with credentials
	 */
  async loginWithCredentials(username, password, redirect) {

    //Obtain token from server
    const token = await Token.obtain('password', {username, password})

    //Authenticate
    await this.authenticate(token, redirect)
  }

  /**
   * Login with existing access token (from oAuth flow)
   */
  async loginWithToken(accessToken, redirect) {

    //Create token from access token directly
    const token = Token.from(accessToken)

    //Authenticate
    await this.authenticate(token, redirect)
  }

  /**
   * Login with oAuth
   */
  async loginWithOAuth(provider, action = 'login', state = {}) {

    //If object, get ID
    if (typeof provider === 'object') {
      provider = provider.id
    }

    //Always append action to state
    state.action = action

    //Create serialized params from state object
    const params = Object
      .entries(state)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    //Determine path
    const base = cfg.api.baseUrl
    const path = this.getOAuthPath(action)
    const qs = params ? `?${params}` : ''

    //Wrap in promise
    return Promise
      .resolve()
      .then(() => {
        document.location.href = `${base}/${path}/${provider}${qs}`
      })
  }

  /**
   * Manual logout
   */
  async logout() {

    //Unauthenticate
    this.unAuthenticate()

    //Revoke refresh token on server
    await AuthApi.revoke()
  }

  /**
   * Refresh access token
   */
  async refresh() {

    //Return existing refresh promise if already refreshing
    if (this.refreshPromise) {
      return this.refreshPromise
    }

    //Refresh
    return this.refreshPromise = Token
      .obtain('refreshToken')
      .then(token => this.storeToken(token))
      .catch(() => this.clearToken())
      .finally(() => this.refreshPromise = null)
  }

  /**
   * Get oAuth path for given action
   */
  getOAuthPath(action) {
    switch (action) {
      case 'register':
        return 'user/register'
      case 'login':
      case 'connect':
      default:
        return 'auth'
    }
  }

  /**************************************************************************
   * Authentication state handlers
   ***/

  /**
   * Authenticate
   */
  async authenticate(token, redirect = cfg.auth.homeRoute) {

    //Store token
    this.storeToken(token)

    //Load user
    await Promise.all([
      store.dispatch('session/loadUser'),
    ])

    //Emit authenticated event
    this.emit('authenticated')

    //Redirect if needed
    if (redirect) {
      router.push(redirect)
    }
  }

  /**
   * Unauthenticate
   */
  async unAuthenticate() {

    //Clear token
    this.clearToken()

    //Redirect back to login unless allowed to be here
    if (router.currentRoute.matched.some(route => route.meta.auth)) {
      await router.push(cfg.auth.loginRoute)
    }

    //Emit unauthenticated event
    this.emit('unauthenticated')

    //Unload user after we've nagivated away
    store.dispatch('session/unloadUser')
  }
}

/**
 * Export singleton instance
 */
export default new AuthService()
