<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="true"
    >
      <template v-slot:filters>
        <request-log-filters />
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--club">
          Club
        </div>
        <div class="Table-col Table-col--status">
          Status
        </div>
        <div class="Table-col Table-col--method">
          Method
        </div>
        <div class="Table-col Table-col--path">
          Path
        </div>
        <div class="Table-col Table-col--date">
          <sort
            :sort="filter.sort"
            field="date"
            @toggle="filter.update('sort', $event.sort)"
          >Date</sort>
        </div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--club"><span/></div>
          <div class="Table-col Table-col--staus"><span/></div>
          <div class="Table-col Table-col--method"><span/></div>
          <div class="Table-col Table-col--path"><span/><small/></div>
          <div class="Table-col Table-col--date"><span/><small/></div>
          <div class="Table-col Table-col--options">
            <i class="Icon">more_vert</i>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="item in requestLogs"
          :key="item.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--club">
            <router-link
              v-if="item.club"
              class="Table-link"
              :to="`/clubs/edit/${item.club.id}`"
              @click.stop
            >
              {{item.club.name}}
            </router-link>
            <span v-else>Unknown</span>
            <small class="Table-sub">
              {{item.ip}}
            </small>
          </div>
          <div
            class="Table-col Table-col--status"
            :class="{
              'text-success': item.isSuccess,
              'text-warning': item.isClientError,
              'text-danger': item.isServerError,
            }"
          >
            {{item.status}}
            <small class="Table-sub">
              {{item.duration}}ms
            </small>
          </div>
          <div class="Table-col Table-col--method">
            {{item.method}}
          </div>
          <div class="Table-col Table-col--path">
            <a class="Table-link" @click="view(item)">{{item.path}}</a>
          </div>
          <div class="Table-col Table-col--date">
            {{item.date | moment('D MMM YYYY, HH:mm:ss')}}
            <small class="Table-sub">
              {{item.date | moment('fromNow')}}
            </small>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>
  </div>
</template>

<script>
import RequestLogApi from '@/api/request-log.api'
import RequestLogFilters from './request-log-filters'
import RequestLogView from './modals/view'
import moment from '@/services/moment'

export default {
  components: {
    RequestLogFilters,
  },
  data() {
    return {

      //Page data
      title: 'Request Logs',
      type: 'request log',

      //Pagination
      page: 1,
      itemsPerPage: 50,
      totalItems: 0,

      //Flags
      isLoading: true,

      //Default dates
      fromDate: moment().subtract(7, 'days').endOf('day'),
      toDate: moment().endOf('day'),

      //Data
      requestLogs: [],
    }
  },
  computed: {
    hasItems() {
      return this.requestLogs.length > 0
    },
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await this.loadRequestLogs()

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('requestLog')

      //Set defaults
      this.filter.setDefaults({
        search: '',
        sort: '-date',
        method: null,
        status: null,
        fromDate: this.fromDate,
        toDate: this.toDate,
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadRequestLogs()
    },

    /**
     * Load request logs
     */
    async loadRequestLogs(extra) {

      //Clear current list and flag as loading
      this.requestLogs = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter(extra)

      //Query request logs
      await RequestLogApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()
      const {page, itemsPerPage} = this

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage
        filter.offset = (page - 1) * itemsPerPage
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {meta, requestLogs} = data

      //Set properties and flags
      this.requestLogs = requestLogs
      this.totalItems = meta.total
    },

    /**
     * View
     */
    view(requestLog) {

      //Open viewing modal
      this.$modal.show(RequestLogView, {
        requestLog,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.Table-col--club {
  flex: 2;
}
.Table-col--method {
  flex: 0 0 6rem;
}
.Table-col--path {
  flex: 3;
}
.Table-col--status {
  flex: 0 0 5rem;
}
.Table-col--date {
  flex: 0 0 11.5rem;
}
</style>
