<template>
  <card
    title="Comments"
  >
    <template v-slot:title></template>
    <comment
      :comment="comment"
      v-for="comment in club.comments"
      :key="comment.id"
      @edit="edit(comment)"
      @remove="remove(comment)"
    />
    <div class="Group CommentInputWrapper">
    <textarea
      class="Input CommentInput"
      placeholder="Add new comment"
      v-model="text"
      rows="3"
    />
    <div>
      <button
        class="Button Button--s CommentButton"
        @click="create()"
        :disabled="isSaving"
      >
        Add
        <spinner/>
      </button>
    </div>
  </div>
  </card>
</template>

<script>
import Comment from '../comment'
import ModalEditComment from '../modals/edit-comment'
import ModalRemoveComment from '../modals/remove-comment'

export default {
  components: {
    Comment,
  },
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isSaving: false,
      text: '',
    }
  },
  methods: {
    async create() {

      //Must have text
      if (!this.text) {
        return
      }

      //Set flag
      this.isSaving = true

      //Create the comment
      this.club
        .createComment(this.text)
        .then(this.text = '')
        .finally(this.isSaving = false)
    },
    edit(comment) {

      //Create save handler
      const onSave = model => this.club
        .updateComment(comment.id, model.text)

      //Open editing modal
      this.$modal.show(ModalEditComment, {
        comment, onSave,
      })
    },
    remove(comment) {

      //Create remove handler
      const onConfirm = () => this.club
        .removeComment(comment.id)

      //Open editing modal
      this.$modal.show(ModalRemoveComment, {
        comment, onConfirm,
      })
    },
  },
}
</script>

<style lang="scss">
.CommentInputWrapper {
  display: flex;
  align-items: flex-end;
}
.CommentInput {
  flex: 1;
}
.CommentButton {
  margin-left: $spacing-m;
}
</style>
