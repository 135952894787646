<template>
  <span class="Sort" @click="toggle()">
    <slot />
    <i class="Icon" v-if="isCurrentSortField && !isDescending">arrow_drop_up</i>
    <i class="Icon" v-if="isCurrentSortField && isDescending">arrow_drop_down</i>
  </span>
</template>

<script>
export default {
  emits: [
    'toggle',
  ],
  props: {
    sort: {
      type: String,
    },
    field: {
      type: String,
    },
    defaultDirection: {
      type: String,
      default: '',
    },
  },
  computed: {
    isCurrentSortField() {
      if (!this.sort) {
        return false
      }
      return this.sort.replace(/^-/, '') === this.field
    },
    isDescending() {
      return this.isCurrentSortField && this.sort.match(/^-/)
    },
    direction() {

      //This field is not currently being sorted on, use the default direction
      if (!this.isCurrentSortField) {
        return this.defaultDirection
      }

      //Check if currently descending
      return this.isDescending ? '' : '-'
    },
  },
  methods: {
    toggle() {

      //Prepare sort string
      const {field, direction} = this
      const sort = field
        .split(',')
        .map(field => `${direction}${field}`)
        .join(',')

      //Emit event
      this.$emit('toggle', {sort})
    },
  },
}
</script>

<style lang="scss">
.Sort {
  cursor: pointer;
  display: flex;
  align-items: center;
  .Icon {
    margin-top: 2px;
  }
}
</style>
