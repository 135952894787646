<template>
  <icon-text
    :size="size"
    :icon="icon"
    :iconClass="{
      [`${classUp}`]: isUp,
      [`${classFlat}`]: isFlat,
      [`${classDown}`]: isDown,
    }"
  >
    {{value | percentage({maximumFractionDigits: 1})}}
  </icon-text>
</template>

<script>
import IconText from '@/components/shared/icons/icon-text'

export default {
  components: {
    IconText,
  },
  props: {
    value: {
      type: Number,
      default: () => 0,
    },
    size: {
      type: String,
      default: () => 'm',
    },
    iconUp: {
      type: String,
      default: () => 'trending_up',
    },
    iconFlat: {
      type: String,
      default: () => 'trending_flat',
    },
    iconDown: {
      type: String,
      default: () => 'trending_down',
    },
    classUp: {
      type: String,
      default: () => 'Icon--success',
    },
    classFlat: {
      type: String,
      default: () => 'Icon--muted',
    },
    classDown: {
      type: String,
      default: () => 'Icon--danger',
    },
  },
  computed: {
    isUp() {
      return (this.value > 0)
    },
    isDown() {
      return (this.value < 0)
    },
    isFlat() {
      return (this.value === 0)
    },
    icon() {
      if (this.isUp) {
        return this.iconUp
      }
      if (this.isDown) {
        return this.iconDown
      }
      return this.iconFlat
    },
  },
}
</script>
