<template>
  <div class="Grid" :class="{
    [`Grid--cols-${cols}`]: cols > 0,
    [`Grid--rows-${rows}`]: rows > 0,
    [`Grid--rowSpacing-${rowSpacing}`]: rowSpacing !== '',
    [`Grid--colSpacing-${colSpacing}`]: colSpacing !== '',
  }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    cols: {
      type: [Number, String],
      default: () => 0,
    },
    rows: {
      type: [Number, String],
      default: () => 0,
    },
    colSpacing: {
      type: String,
      default: () => '',
    },
    rowSpacing: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="scss">
.Grid {
  display: grid;
  grid-column-gap: $spacing-m;
  grid-row-gap: 0;
  align-items: start;
  @include verticallySpaced;
  > .IconLabel {
    overflow: hidden;
    min-width: 0;
  }
}
.Grid--rowSpacing-xxs {
  grid-row-gap: $spacing-xxs;
}
.Grid--rowSpacing-xs {
  grid-row-gap: $spacing-xs;
}
.Grid--rowSpacing-s {
  grid-row-gap: $spacing-s;
}
.Grid--rowSpacing-m {
  grid-row-gap: $spacing-m;
}
.Grid--rowSpacing-l {
  grid-row-gap: $spacing-l;
}
@for $i from 1 through 20 {
  .Grid--cols-#{$i} {
    grid-auto-flow: row;
    grid-template-columns: repeat($i, 1fr);
  }
  .Grid--rows-#{$i} {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-rows: repeat($i, min-content);
  }
}
</style>
