<template>
  <div class="SelectBox" :class="{'SelectBox--nullValue': isNullValue}">
    <div
      class="InputWrapper clickable"
      @click.stop="toggleOptions()"
    >
      <div class="Caret"
        @click.stop="toggleOptions()"
      ></div>
      <input
        readonly
        class="Input"
        :class="inputClass"
        type="text"
        v-model="selectedLabel"
      >
    </div>
    <ul class="Dropdown SelectBox-options" v-show="isShowingOptions">
      <li
        v-if="isNullable || !hasOptions"
        class="Dropdown-item"
        :class="{selected: isSelection(-1)}"
        @mouseover="setSelection(-1)"
        @click.prevent="confirmSelection(-1)"
      >{{nullLabel}}</li>
      <li
        v-for="(option, index) in options"
        :key="option[bindKey]"
        class="Dropdown-item"
        :class="{selected: isSelection(index)}"
        @mouseover="setSelection(index)"
        @click.prevent="confirmSelection(index)"
      >{{getLabelValue(option)}}</li>
    </ul>
  </div>
</template>

<script>
import {clickHandler} from '@/helpers/ux'

export default {
  emits: [
    'change',
  ],
  data() {
    return {
      isShowingOptions: false,
      selectionIndex: -1,
    }
  },
  props: {
    model: {
      type: [String, Number, Object, Boolean],
      default: () => null,
      validator: prop => (
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        typeof prop === 'object' ||
        typeof prop === 'boolean' ||
        prop === null
      ),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: () => undefined,
    },
    trackBy: {
      type: String,
      default: () => undefined,
    },
    asObject: {
      type: Boolean,
      default: () => false,
    },
    isNullable: {
      type: Boolean,
      default: () => false,
    },
    isNullUndefined: {
      type: Boolean,
      default: () => false,
    },
    nullValue: {
      type: [null, undefined],
      default: () => null,
    },
    nullLabel: {
      type: String,
      default: () => '...',
    },
    nullLabelSelected: {
      type: String,
      default: () => '',
    },
    bindKey: {
      type: String,
      default: () => 'value',
    },
    inputClass: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    isNullValue() {
      return (this.model === this.nullValue)
    },
    labelKey() {
      if (typeof this.labelBy !== 'undefined') {
        return this.labelBy
      }
      return this.determineKey('label', ['label', 'name'])
    },
    trackKey() {
      if (typeof this.trackBy !== 'undefined') {
        return this.trackBy
      }
      return this.determineKey('track', ['value', 'id', 'code'])
    },
    hasOptions() {
      return (this.options && this.options.length > 0)
    },
    selectedOption() {
      return this.findOption()
    },
    selectedLabel() {
      const {selectedOption, nullLabelSelected} = this
      return this.getLabelValue(selectedOption, nullLabelSelected)
    },
  },
  created() {

    //Create bound handler
    const handler = this.hideOptions.bind(this)

    //Create click handler
    this.removeHandler = clickHandler(handler, this.$el)
  },
  unmounted() {
    this.removeHandler()
  },
  methods: {

    /**
     * Toggle options
     */
    toggleOptions() {
      this.isShowingOptions = !this.isShowingOptions
    },

    /**
     * Hide options
     */
    hideOptions() {
      this.isShowingOptions = false
    },

    /**
     * Select an option
     */
    selectOption(option, index) {

      //Hide options
      this.hideOptions()

      //Get the new model value and call on change handler
      const value = this.getModelValue(option, index)
      this.$emit('change', {value, option, index})
    },

    /**
     * Check if given index is the selection index
     */
    isSelection(index) {
      return (this.selectionIndex === index)
    },

    /**
     * Set the selection index
     */
    setSelection(index) {
      this.selectionIndex = index
    },

    /**
     * Confirm selection
     */
    confirmSelection(index) {

      //Get data
      const {isNullable, hasOptions, options, selectionIndex} = this

      //If index not given, use current selection index
      if (typeof index === 'undefined') {
        index = selectionIndex
      }

      //Initialize option
      let option

      //Nullable and -1 index given?
      if (isNullable && index === -1) {
        option = null
      }

      //Otherwise, take from given options
      else {

        //Validate index
        if (
          !hasOptions ||
          typeof index === 'undefined' ||
          typeof options[index] === 'undefined'
        ) {
          return
        }

        //Get option
        option = options[index]
      }

      //Select option now
      this.selectOption(option, index)
    },

    /**
     * Find the selected option based on the model value
     */
    findOption() {

      //Get data
      const {model, options, trackKey, nullValue} = this

      //Empty or null value selected?
      if (typeof model === 'undefined' || model === nullValue) {
        return null
      }

      //Tracking by index?
      if (trackKey === '$index') {
        if (typeof options[model] !== 'undefined') {
          return options[model]
        }
        return null
      }

      //Get the model value
      const modelValue = this.getTrackingValue(model, model)

      //Find matching option
      return options
        .find((option, index) => {
          const optionValue = this.getTrackingValue(option, index)
          return (modelValue === optionValue)
        })
    },

    /**
     * Determine key based on options
     */
    determineKey(type, candidates) {

      //Must have options
      if (!this.options || this.options.length === 0) {
        return null
      }

      //Get first option and check if it's an object
      const first = this.options[0]
      if (typeof first !== 'object') {
        return null
      }

      //Find key from candidates
      const key = candidates.find(key => typeof first[key] !== 'undefined')
      if (key) {
        return key
      }

      //Unable to determine
      throw new Error(`Cannot determine ${type} key`)
    },

    /**
     * Get the model value
     */
    getModelValue(option, index) {

      //Get data
      const {asObject} = this

      //If returning as object, return the selected option
      if (asObject) {
        return option
      }

      //Otherwise, return the tracking value of the given option
      return this.getTrackingValue(option, index)
    },

    /**
     * Helper to get the tracking value of an option
     */
    getTrackingValue(option, index) {

      //Get data
      const {trackKey, nullValue, isNullable, isNullUndefined} = this

      //Null value?
      if (isNullable && option === null) {
        if (isNullUndefined) {
          return undefined
        }
        return nullValue
      }

      //Tracking by index?
      if (trackKey === '$index') {
        return index
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option
      }

      //Validate property
      if (typeof option[trackKey] === 'undefined') {
        throw new Error(`Unknown tracking key '${trackKey}' for select box`)
      }

      //Return the property
      return option[trackKey]
    },

    /**
     * Get label value of an option
     */
    getLabelValue(option) {

      //Get data
      const {labelKey, nullLabel} = this

      //Null value?
      if (option === null || typeof option === 'undefined') {
        return nullLabel
      }

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option
      }

      //Validate property
      if (typeof option[labelKey] === 'undefined') {
        throw new Error(`Unknown property '${labelKey}' for select box label`)
      }

      //Return the property
      return option[labelKey]
    },
  },
}
</script>

<style lang="scss">

//Selectbox
.SelectBox {
  width: 100%;
  display: inline-block;
  position: relative;
  .Input {
    color: $slate;
    outline: none;
    cursor: pointer;
  }
}
.SelectBox-options {
  width: 100%; //Match input width
  min-width: 12rem;
  max-height: 14rem; //7 items
  margin-top: $spacing-xs;
  overflow-x: hidden;
  overflow-y: scroll;
}

//Sizing
.SelectBox--m {
  width: 8rem;
}
.SelectBox--s {
  width: 6rem;
}
.SelectBox--xs {
  width: 4rem;
}

//Null value selectbox
.SelectBox--nullValue {
  .Input {
    color: $oslo;
  }
}

//Caret
.Caret {
  display: block;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 50%;
  margin-top: -5px;
  width: 0;
  height: 0;
  border-top: 5px solid $oslo;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
</style>
