
/**
 * Base error class
 */
export default class BaseError extends Error {

  /**
   * Constructor
   */
  constructor(message, data, status) {

    //Parent constructor
    super(message)

    //If used another error as constructor, copy those properties
    //NOTE: must manually copy stack
    if (message && message instanceof Error) {
      Object.assign(this, message)
      this.stack = message.stack
    }

    //Otherwise, check if data given as first parameter
    else if (message && typeof message === 'object') {
      data = message
      message = ''
    }

    //Set name, message, data and status
    this.name = this.constructor.name
    this.message = this.message || message || 'Error'
    this.data = data || null
    this.status = status || null

    //Capture stack trace
    if (!this.stack) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  /**
   * To string
   */
  toString() {
    return this.name + ': ' + this.message
  }
}
