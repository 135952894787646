import BaseModel from './base.model'

/**
 * Request log model
 */
export default class RequestLog extends BaseModel {

  /**
   * Virtuals
   */
  get isSuccess() {
    return (this.status >= 200 && this.status <= 299)
  }
  get isClientError() {
    return (this.status >= 400 && this.status <= 499)
  }
  get isServerError() {
    return (this.status >= 500 && this.status <= 599)
  }
}
