import Vue from 'vue'
import Router from 'vue-router'

//Guards
import {checkAuth, loadUser} from './guards/authentication'
import {clearPage, markPageAsLoading, markSessionAsLoaded, loadCountries} from './guards/common'

//Main modules
import Dashboard from '@/components/dashboard/dashboard'
import Login from '@/components/login/login'
import OAuthCallback from '@/components/oauth/callback'
import ErrorRoute from '@/components/error/error'

//Club
import Club from '@/components/club/club'
import ClubOverview from '@/components/club/overview'
import ClubEdit from '@/components/club/edit'

//Feedback
import Feedback from '@/components/feedback/feedback'
import FeedbackOverview from '@/components/feedback/overview'

//Plan history
import PlanHistory from '@/components/plan-history/plan-history'
import PlanHistoryOverview from '@/components/plan-history/overview'

//Online payments history
import OnlinePaymentsHistory from '@/components/online-payments/online-payments'
import OnlinePaymentsHistoryOverview from '@/components/online-payments/overview'

//Request logs
import RequestLogs from '@/components/request-logs/request-logs'
import RequestLogsOverview from '@/components/request-logs/overview'

//Systems
import Systems from '@/components/systems/systems'
import SystemsOverview from '@/components/systems/overview'

//Orders
import Orders from '@/components/orders/orders'
import OrdersOverview from '@/components/orders/overview'

//Load plugin
Vue.use(Router)

//NOTE: When props is set to true, the route.params will be set as the component props

/**
 * Create router instance
 */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        auth: true,
      },
    },
    {
      path: '/clubs',
      component: Club,
      children: [
        {
          path: '',
          name: 'clubs',
          component: ClubOverview,
        },
        {
          path: 'edit/:id',
          name: 'club.edit',
          component: ClubEdit,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/feedback',
      component: Feedback,
      children: [
        {
          path: '',
          name: 'feedback',
          component: FeedbackOverview,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/plan-history',
      component: PlanHistory,
      children: [
        {
          path: '',
          name: 'planHistory',
          component: PlanHistoryOverview,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/online-payments-history',
      component: OnlinePaymentsHistory,
      children: [
        {
          path: '',
          name: 'onlinePayments',
          component: OnlinePaymentsHistoryOverview,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/request-logs',
      component: RequestLogs,
      children: [
        {
          path: '',
          name: 'requestLogs',
          component: RequestLogsOverview,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/systems',
      component: Systems,
      children: [
        {
          path: '',
          name: 'systems',
          component: SystemsOverview,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/orders',
      component: Orders,
      children: [
        {
          path: '',
          name: 'orders',
          component: OrdersOverview,
        },
      ],
      meta: {
        auth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/oauth/callback',
      name: 'oAuthCallback',
      component: OAuthCallback,
      props: true,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorRoute,
      props: true,
    },
  ],
})

/**
 * Guards
 */
router.beforeEach(clearPage)
router.beforeEach(markPageAsLoading)
router.beforeEach(checkAuth)
router.beforeEach(loadUser)
router.beforeEach(markSessionAsLoaded)
router.beforeEach(loadCountries)

/**
 * Export router instance
 */
export default router
