import cfg from '@/services/cfg'

/**
 * Helper to generate app URL for given hostname
 */
export function generateUrl(hostname, path, qs) {

  //Create base URL
  let url = cfg.app.baseUrl.replace(/%hostname%/, hostname)

  //Append path if given
  if (path) {
    if (path.charAt(0) !== '/') {
      path = `/${path}`
    }
    url += path
  }

  //Append query string if given
  if (qs) {
    if (qs.charAt(0) !== '?') {
      qs = `?${qs}`
    }
    url += qs
  }

  //Return URL
  return url
}