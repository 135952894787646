import BaseApi from './base.api'
import Permission from '@/models/permission.model'

/**
 * Permission API service
 */
class PermissionApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/permission',
      dataKey: 'permissions',
      Model: Permission,
    })
  }
}

/**
 * Export singleton instance
 */
export default new PermissionApi()
