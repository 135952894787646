<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :suffix="suffix"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="false"
    >``
      <template v-slot:filters>
        <online-payments-history-filters />
      </template>
    </page-header>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--period">
          Period
        </div>
        <div class="Table-col Table-col--numPayments">
          Payments
        </div>
        <div class="Table-col Table-col--processed">
          Processed
        </div>
        <div class="Table-col Table-col--total">
          Total
        </div>
        <div class="Table-col Table-col--memberFees">
          Member fees
        </div>
        <div class="Table-col Table-col--clubFees">
          Club fees
        </div>
        <div class="Table-col Table-col--providerFees">
          Provider fees
        </div>
        <div class="Table-col Table-col--platformFees">
          Platform fees
        </div>
        <div class="Table-col Table-col--rebateFees">
          Rebate fees
        </div>
        <div class="Table-col Table-col--mrr">
          Payments MRR
        </div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--period"><span/></div>
          <div class="Table-col Table-col--numPayments"><span/></div>
          <div class="Table-col Table-col--processed"><span/></div>
          <div class="Table-col Table-col--total"><span/></div>
          <div class="Table-col Table-col--memberFees"><span/></div>
          <div class="Table-col Table-col--clubFees"><span/></div>
          <div class="Table-col Table-col--providerFees"><span/></div>
          <div class="Table-col Table-col--platformFees"><span/></div>
          <div class="Table-col Table-col--rebateFees"><span/></div>
          <div class="Table-col Table-col--mrr"><span/></div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="item in items"
          :key="item.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--flex Table-col--period">
            <strong @click="expand(item)" class="Table-link">
              {{getPeriod(item)}}
            </strong>
            <spinner class="Spinner--inline" v-if="item.isExpanding"/>
          </div>
          <div class="Table-col Table-col--numPayments">
            {{item.numPayments}}
          </div>
          <div class="Table-col Table-col--processed">
            {{item[currencyKey].processed | currency}}
            <small class="Table-sub">
              {{item[currencyKey].avgPaymentAmount | currency}} avg
            </small>
          </div>
          <div class="Table-col Table-col--total">
            {{item[currencyKey].totalProcessed | currency}}
            <small class="Table-sub">
              +{{item[currencyKey].totalProcessedFactor | percentage({maximumFractionDigits: 1})}}
            </small>
          </div>
          <div class="Table-col Table-col--memberFees">
            {{item[currencyKey].memberFees | currency}}
            <small class="Table-sub">
              {{item[currencyKey].partMemberFees | percentage({maximumFractionDigits: 1})}}
            </small>
          </div>
          <div class="Table-col Table-col--clubFees">
            {{item[currencyKey].clubFees | currency}}
            <small class="Table-sub">
              {{item[currencyKey].partClubFees | percentage({maximumFractionDigits: 1})}}
            </small>
          </div>
          <div class="Table-col Table-col--providerFees">
            {{item[currencyKey].providerFees | currency}}
            <small class="Table-sub">
              {{item[currencyKey].avgProviderFee | currency}} avg /
              {{item[currencyKey].providerFeesPart | percentage({maximumFractionDigits: 2, minimumFractionDigits: 2})}}
            </small>
          </div>
          <div class="Table-col Table-col--platformFees">
            {{item[currencyKey].platformFees | currency}}
            <small class="Table-sub">
              {{item[currencyKey].avgPlatformFee | currency}} avg /
              {{item[currencyKey].platformFeesPart | percentage({maximumFractionDigits: 2, minimumFractionDigits: 2})}}
            </small>
          </div>
          <div class="Table-col Table-col--rebateFees">
            {{item[currencyKey].rebateFees | currency}}
            <small class="Table-sub">
              {{item[currencyKey].avgRebateFee | currency}} avg /
              {{item[currencyKey].rebateFeesPart | percentage({maximumFractionDigits: 2, minimumFractionDigits: 2})}}
            </small>
          </div>
          <div class="Table-col Table-col--mrr">
            {{item.mrr | currency}}
            <small class="Table-sub">
              {{item.arr | currency}}
            </small>
          </div>

          <div class="Table-subRows" v-if="item.isExpanded">
            <div
              v-for="sub in item.sub"
              :key="sub.id"
              class="Table-subRow"
            >
              <div class="Table-col Table-col--method">
                {{sub.method | label(OnlinePaymentMethods)}}
              </div>
              <div class="Table-col Table-col--numPayments">
                {{sub.numPayments}}
              </div>
              <div class="Table-col Table-col--processed">
                {{sub[currencyKey].processed | currency}}
              </div>
              <div class="Table-col Table-col--total">
                &nbsp;
              </div>
              <div class="Table-col Table-col--memberFees">
                {{sub[currencyKey].memberFees | currency}}
              </div>
              <div class="Table-col Table-col--clubFees">
                {{sub[currencyKey].clubFees | currency}}
              </div>
              <div class="Table-col Table-col--providerFees">
                {{sub[currencyKey].providerFees | currency}}
              </div>
              <div class="Table-col Table-col--platformFees">
                {{sub[currencyKey].platformFees | currency}}
              </div>
              <div class="Table-col Table-col--rebateFees">
                {{sub[currencyKey].rebateFees | currency}}
              </div>
              <div class="Table-col Table-col--mrr">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from '@/services/moment'
import OnlinePaymentsHistoryApi from '@/api/online-payments-history.api'
import OnlinePaymentMethods from '@/constants/online-payment-methods'
import OnlinePaymentsHistoryFilters from './online-payments-filters'

export default {
  components: {
    OnlinePaymentsHistoryFilters,
  },
  data() {
    return {

      //Page data
      title: 'Online payments history',
      type: 'record',

      //Totals
      totalItems: 0,
      totalPaymentsProcessed: 0,

      //Flags
      isLoading: true,

      //Data
      items: [],

      //Constants
      OnlinePaymentMethods,
    }
  },
  computed: {
    hasItems() {
      return this.items.length > 0
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
    currencyKey() {
      const currencyCode = this.filter.currencyCode
      if (!currencyCode) {
        return 'nzd'
      }
      return 'org'
    },
    suffix() {
      const val = this.$options.filters
        .currency(this.totalPaymentsProcessed, {fractionCount: 0})
      return `${val} processed`
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await this.loadOnlinePayments()

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('onlinePayments')

      //Set defaults
      this.filter.setDefaults({
        group: 'month,year',
        fromDate: moment('01-12-2016', 'DD-MM-YYYY').startOf('day'),
        toDate: moment().endOf('day'),
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadOnlinePayments()
    },

    /**
     * Load items
     */
    async loadOnlinePayments(extra) {

      //Clear current list and flag as loading
      this.items = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter(extra)

      //Query items
      await OnlinePaymentsHistoryApi
        .aggregate(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {
        items,
        sums: {totalPaymentsProcessed},
      } = data

      //Set properties and flags
      this.items = items
      this.totalItems = items.length
      this.totalPaymentsProcessed = totalPaymentsProcessed
    },

    /**
     * Expand an item
     */
    async expand(item) {

      //Already expanded? Contract again
      if (item.isExpanded) {
        return this.$set(item, 'isExpanded', false)
      }

      //Already has items loaded? Merely show
      if (item.sub) {
        return this.$set(item, 'isExpanded', true)
      }

      //Create period
      const period = moment().year(item.year).month(item.month - 1)

      //Get base filter
      const filter = this.makeFilter({
        group: 'method',
        fromDate: period.clone().startOf('month'),
        toDate: period.clone().endOf('month'),
      })

      //Flag as expanding
      this.$set(item, 'isExpanding', true)

      //Query items
      const items = await OnlinePaymentsHistoryApi
        .aggregate(filter)
        .then(({items}) => items)

      //Set and flag no longer expanding
      this.$set(item, 'sub', items)
      this.$set(item, 'isExpanded', true)
      this.$set(item, 'isExpanding', false)
    },

    /**
    * Get period for item
    */
    getPeriod(item) {
      const {month, year} = item
      const date = moment().year(year).month(month - 1)
      return date.format('MMMM YYYY')
    },
  },
}
</script>

<style scoped lang="scss">
.Table-col--period, .Table-col--method {
  flex: 1;
}
.Table-col--numPayments {
  flex: 0 0 6rem;
}
.Table-col--mrr {
  flex: 0 0 7.5rem;
}
.Table-col--processed,
.Table-col--total,
.Table-col--memberFees,
.Table-col--clubFees,
.Table-col--providerFees,
.Table-col--platformFees,
.Table-col--rebateFees {
  flex: 0 0 8rem;
}
</style>
