<template>
  <div class="IconText">
    <i class="Icon" :class="iconClassWithSize">{{icon}}</i>
    <span><slot/></span>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: () => 'm',
    },
    icon: {
      type: String,
      default: () => 'emoji_emotions',
    },
    iconClass: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    iconClassWithSize() {
      const {size, iconClass} = this
      return Object.assign(iconClass, {
        [`Icon--${size}`]: true,
      })
    },
  },
}
</script>

<style lang="scss">
.IconText {
  display: inline-flex;
  align-items: center;
  .Icon {
    margin-right: $spacing-xxs;
  }
}
</style>
