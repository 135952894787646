<template>
  <card
    title="Migration"
  >
    <template v-slot:title>
      <i
        class="Icon Icon--faint Icon--s Icon--inline clickable"
        @click="edit()"
        v-tooltip.bottom="'Edit migration details'"
      >edit</i>
    </template>
    <grid rows="1" row-spacing="xs">
      <icon-label icon="flag" label="Flagged for migration">
        <span v-if="club.willBeMigratedToFrello">Yes</span>
        <span v-else>No</span>
      </icon-label>

      <icon-label icon="event_available" label="Migration date">
        <span v-if="club.frelloMigrationDate">{{club.frelloMigrationDate | moment}}</span>
        <span v-else>Not set</span>
      </icon-label>

      <icon-label icon="verified" label="Has been migrated">
        <span v-if="club.hasMigratedToFrello">Yes</span>
        <span v-else>No</span>
      </icon-label>
    </grid>
  </card>
</template>

<script>
import ModalEditMigration from '../modals/edit-migration'

export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {

      //Flags
      isSaving: false,
    }
  },
  methods: {



    /**
     * Edit details
     */
    edit() {

      //Get club
      const {club} = this

      //Create save handler
      const onSave = async (model) => {
        await club.patch(model)
      }

      //Open editing modal
      this.$modal.show(ModalEditMigration, {
        club, onSave,
      })
    },
  },
}
</script>

<style lang="scss">
</style>
