<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="true"
    ></page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--club">
          Ordered by
        </div>
        <div class="Table-col Table-col--order">
          Order
        </div>
        <div class="Table-col Table-col--address">
          Deliver to
        </div>
        <div class="Table-col Table-col--date">
          Ordered on
        </div>
        <div class="Table-col Table-col--date">
          Processed on
        </div>
        <div class="Table-col Table-col--status">
          Status
        </div>
        <div class="Table-col Table-col--options">&nbsp;</div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--club"><span/></div>
          <div class="Table-col Table-col--order"><span/></div>
          <div class="Table-col Table-col--address"><span/></div>
          <div class="Table-col Table-col--date"><span/></div>
          <div class="Table-col Table-col--date"><span/></div>
          <div class="Table-col Table-col--status"><span/></div>
          <div class="Table-col Table-col--options">
            <i class="Icon">more_vert</i>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="order in tagOrders"
          :key="order.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--club">
            {{order.club.name}}
            <br>
            <small class="Table-sub">
              {{order.orderedBy.firstName}} {{order.orderedBy.lastName}}
            </small>
          </div>
          <div class="Table-col Table-col--order">
            {{order.amount}} x {{order.type}}
            <br>
            <small class="Table-sub">
              {{order.totalPrice | currency}}
            </small>
          </div>
          <div class="Table-col Table-col--address" v-tooltip.bottom="order.notes">
            {{order.deliveryName}}
            <br>
            <small class="Table-sub">
              {{order.deliveryAddress.formatted}}
            </small>
          </div>
          <div class="Table-col Table-col--date">
            {{order.orderPlacedDate | moment}}
            <br>
            <small class="Table-sub">
              {{order.orderPlacedDate | moment('HH:mm')}}
            </small>
          </div>
          <div class="Table-col Table-col--date">
            {{order.processedDate | moment}}
            <br>
            <small class="Table-sub">
              {{order.processedDate | moment('HH:mm')}}
            </small>
          </div>
          <div class="Table-col Table-col--status">
            <i
              class="Icon Icon--success"
              v-if="order.isConfirmed && order.processedDate"
              v-tooltip.bottom="'Processed'"
            >check_circle</i>
            <i
              class="Icon Icon--warning"
              v-else-if="order.isConfirmed"
              @click="toggleConfirmation(order)"
              v-tooltip.bottom="'Confirmed'"
            >schedule</i>
            <i
              class="Icon Icon--danger"
              v-else
              @click="toggleConfirmation(order)"
              v-tooltip.bottom="'New order, click to confirm'"
            >add_circle_outline</i>
          </div>
          <div class="Table-col Table-col--options">
            <order-options
              :order="order"
              @remove="remove(order)"
            ></order-options>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>
  </div>
</template>

<script>
import TagOrderApi from '@/api/tag-order.api'
import ModuleDefaults from  '@/constants/module-defaults'
import OrderOptions from './order-options'
import OrderRemove from './modals/remove'

export default {
  components: {
    OrderOptions,
  },
  data() {
    return {

      //Page data
      title: 'Orders',
      type: 'order',

      //Pagination
      page: 1,
      itemsPerPage: 50,
      totalItems: 0,

      //Flags
      isSaving: false,
      isLoading: true,

      //Data
      tagOrders: [],

      //Module defaults
      ModuleDefaults,
    }
  },
  computed: {
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await this.loadTagOrders()

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('orders')

      //Set defaults
      this.filter.setDefaults({
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadSystems()
    },


    /**
     * Load systems
     */
    async loadTagOrders() {

      //Clear current list and flag as loading
      this.tagOrders = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter()

      //Query request logs
      await TagOrderApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()
      const {page, itemsPerPage} = this

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage
        filter.offset = (page - 1) * itemsPerPage
        filter.sort = '-orderPlacedDate'
        filter.club = false
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {meta, tagOrders} = data

      //Set properties and flags
      this.tagOrders = tagOrders
      this.totalItems = meta.total
    },

    /**
     * Toggle isConfirmed
     */
    toggleConfirmation(order) {

      //Not when saving
      if (this.isSaving) {
        return
      }

      //Toggle flag
      const data = {isConfirmed: !order.isConfirmed}

      //Save change
      this.save(order, data)
    },

    /**
     * Save tag order
     */
    async save(order, data) {

      //Toggle flag
      this.isSaving = true

      //Patch
      await order
        .patch(data)
        .finally(() => {
          this.isSaving = false
        })

      //Load tag orders again
      this.loadTagOrders()
    },

    /**
     * Remove
     */
    remove(order) {

      //Create handler
      const onConfirm = () => order
        .remove()
        .then(() => this.onRemoved(order))

      //Open confirmation modal
      this.$modal.show(OrderRemove, {
        order, onConfirm,
      })
    },

    /**
     * On removed handler
     */
    onRemoved(tagOrder) {

      //Show notice
      this.$notice.show(`Order is being removed`)

      //Remove club from list
      const i = this.tagOrders.findIndex(o => o.isSame(tagOrder))
      if (i !== -1) {
        this.tagOrders.splice(i, 1)
      }

      //Recalculate total
      this.totalItems = this.tagOrders.length
    },

  },
}
</script>

<style scoped lang="scss">
.Table-col--club {
  max-width: 12rem;
}
.Table-col--date {
  max-width: 8rem;
}
.Table-col--order {
  max-width: 12.5rem;
}
.Table-col--address {
  min-width: 20rem;
}
.Table-col--status {
  max-width: 5rem;
}
</style>
