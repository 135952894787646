import BaseModel from '../base.model'
import Address from '../shared/address.model'

/**
 * Club billing model
 */
export default class ClubBilling extends BaseModel {

  /**
   * Setup
   */
  setup() {

    //Parent method
    super.setup()

    //Sub models
    this.subModels = {
      address: Address,
    }
  }
}
