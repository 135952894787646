<template>
  <card
    title="Billing details"
  >
    <template v-slot:title>
      <i
        class="Icon Icon--faint Icon--s Icon--inline clickable"
        @click="edit()"
        v-tooltip.bottom="'Edit billing details'"
      >edit</i>
    </template>
    <grid rows="4" row-spacing="xs">
      <icon-label icon="person" label="Billing contact">
        {{club.billing.contact}}
      </icon-label>
      <icon-label icon="alternate_email" label="Email">
        <a v-if="club.billing.email" :href="`mailto:${club.billing.email}`">{{club.billing.email}}</a>
        <span v-else>–</span>
      </icon-label>
      <icon-label icon="business" label="Entity">
        {{club.billing.entity}}
      </icon-label>
      <icon-label icon="place" label="Address" v-if="club.billing.address">
        {{club.billing.address.oneLiner}}
      </icon-label>
    </grid>
  </card>
</template>

<script>
import ModalEditBillingDetails from '../modals/edit-billing-details'
import {flatten} from '@/helpers/object'

export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {

      //Flags
      isSaving: false,
    }
  },
  methods: {

    /**
     * Edit billing details
     */
    edit() {

      //Get data
      const {club} = this

      //Create save handler
      const onSave = async (model, params) => {
        const patch = flatten(model, 'stripe')
        await club.patch(patch, params)
      }

      //Open editing modal
      this.$modal.show(ModalEditBillingDetails, {
        club, onSave,
      })
    },
  },
}
</script>

<style lang="scss">
</style>
