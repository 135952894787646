<template>
  <div class="AppWrapper" :class="{'AppWrapper--green': isOnLoginPage}">
    <app-side-bar v-if="isLoaded && user && !isOnLoginPage" />
    <div class="AppLoader" v-if="!isLoaded">
      <spinner class="Spinner--primary" />
    </div>
    <div class="Container AppContent">
      <router-view />
    </div>
    <modals-container :key="$route.fullPath" />
    <notices-container />
    <app-footer />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import AppSideBar from './side-bar/side-bar'
import AppFooter from './footer/footer'

export default {
  components: {
    AppSideBar,
    AppFooter,
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
      isLoaded: state => state.session.isLoaded,
    }),
    isOnLoginPage() {
      const {$route} = this
      return ($route && $route.name && $route.name.match(/^login/))
    },
  },
}
</script>

<style lang="scss">
.AppWrapper {
  display: flex;
  height: 100vh;
  background: $mystic;
}
.AppWrapper--green {
  background: $green;
}
.AppContent {
  display: block;
  max-height: 100%;
  overflow: auto;
}
.AppLoader {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
