<template>
  <div class="NoticesContainer">
    <notice
      v-for="notice in notices"
      :key="notice.id"
      @hide="hide(notice)"
      :message="notice.message"
      :icon="notice.icon"
      :type="notice.type"
      :has-spinner="notice.hasSpinner"
      :hide-on-click="notice.hideOnClick"
      :on-click="notice.onClick"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      notices: [],
    }
  },
  created() {
    this.$notice.setContainer(this)
  },
  methods: {

    /**
     * Show a notice
     */
    show(notice) {
      this.notices.push(notice)
    },

    /**
     * Hide a notice
     */
    hide(notice) {
      const i = this.notices.indexOf(notice)
      if (i !== -1) {
        this.notices.splice(i, 1)
      }
    },
  },
}
</script>

<style lang="scss">
.NoticesContainer {
  position: fixed;
  z-index: 20000;
  bottom: 2rem;
  left: 2rem;
}
</style>
