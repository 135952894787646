import BaseModel from './base.model'

/**
 * System model
 */
export default class SystemApi extends BaseModel {

  /**
   * Update many systems
   */
  updateMany(types) {
    return SystemApi
      .updateMany({types})
      .then(data => this.fromJSON(data))
  }
}
