<template>
  <div class="Group" :class="{
    'Group--danger': hasError,
  }">
    <label class="Label" v-if="label">
      {{label}}
    </label>
    <slot />
    <div class="InputHint" v-if="hasError">
      <p v-for="error in errors" :key="error">
        <span v-if="messages && messages[error]">{{messages[error]}}</span>
        <span v-else-if="error === 'required'">{{label}} is required</span>
        <span v-else-if="error === 'invalid'">{{label}} is invalid</span>
        <span v-else>Unknown error</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    validation: {
      type: Object,
      default: () => null,
    },
    messages: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    hasError() {
      return (this.validation && this.validation.$error)
    },
    errors() {
      const {validation} = this
      const errors = []
      for (const key in validation) {
        if (!key.match(/^\$/) && validation[key] === false) {
          errors.push(key)
        }
      }
      return errors
    },
  },
}
</script>

<style lang="scss">
.Group {
  @include verticallySpaced;
}
.Grid .Group {
  margin-top: 0;
}
.Group--danger {
  .Label {
    color: $red;
  }
  .Input {
    @include inputColor($red);
  }
}
.Group--warning {
  .Label {
    color: $yellow;
  }
  .Input {
    @include inputColor($yellow);
  }
}
.Group--success {
  .Label {
    color: $green;
  }
  .Input {
    @include inputColor($green);
  }
}
</style>
