import * as sentry from '@sentry/browser'
import cfg from '@/services/cfg'

/**
 * Sentry service
 */
class Sentry {

  /**
	 * Constructor
	 */
  constructor() {

    //Set config
    this.config = cfg.sentry
    this.isEnabled = !!this.config.dsn

    //Init
    this.init()
  }

  /**
   * Initialize Sentry
   */
  init() {

    //Not enabled
    if (!this.isEnabled) {
      return
    }

    //Get data
    const {env: environment} = cfg
    const config = Object.assign({environment}, this.config)

    //Initialize
    sentry.init(config)
  }

  /**
   * Capture an exception
   */
  captureException(exception) {

    //Not enabled
    if (!this.isEnabled) {
      return
    }

    //No exception, or ignoring
    if (!exception || exception.ignoreInSentry) {
      return
    }

    //Get config
    const {tags} = this.config

    //Wrap in scope
    sentry.withScope(scope => {

      //Set tags and client URL
      scope.setTags(tags)
      scope.setExtra('clientUrl', window.location.href)

      //Capture exception
      sentry.captureException(exception)
    })
  }
}

/**
 * Export singleton instance
 */
export default new Sentry()
