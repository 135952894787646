
/**
 * Notice service
 */
class NoticeService {

  /**
   * Constructor
   */
  constructor() {

    //Set default options for notices
    this.setDefaults({
      hideAutomatically: true,
      hideOnClick: true,
      hideAfter: 4000,
      icon: '',
      hasSpinner: false,
      isSuccess: false,
      isError: false,
      onClick: function() {},
    })
  }

  /**
   * Set container
   */
  setContainer(container) {
    this.container = container
  }

  /**
   * Set defaults
   */
  setDefaults(defaults) {
    this.defaults = defaults
  }

  /**
   * Show a notice
   */
  show(message, icon, options) {

    //Options given as second parameter?
    if (icon && typeof icon === 'object') {
      options = icon
      icon = ''
    }

    //Create notice object
    const notice = Object.assign({message, icon}, this.defaults, options || {})

    //Auto hide?
    if (notice.hideAutomatically && notice.hideAfter) {
      setTimeout(() => {
        this.container.hide(notice)
      }, notice.hideAfter)
    }

    //Add to container
    this.container.show(notice)

    //Return API to allow closing the notice
    return {
      hide: () => {
        this.container.hide(notice)
      },
    }
  }
}

/**
 * Export singleton instance
 */
export default new NoticeService()
