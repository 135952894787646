
/**
 * Engagement factors
 */
export const LAST_MEMBER_LOGIN = 'lastMemberLogin'
export const LAST_ADMIN_LOGIN = 'lastAdminLogin'
export const SETUP_GUIDE_COMPLETION = 'setupGuideCompletion'
export const NUM_LOGGED_IN_ALL_TIME = 'numLoggedInAllTime'
export const NUM_LOGGED_IN_RECENTLY = 'numLoggedInRecently'
export const NUM_INTEGRATIONS = 'numIntegrations'
export const NUM_ADMINS = 'numAdmins'
export const NUM_ONLINE_PAYMENTS = 'numOnlinePayments'
export const NUM_BOOKINGS = 'numBookings'
export const NUM_ATTENDEES = 'numAttendees'
export const NUM_ACCESS_LOGS = 'numAccessLogs'

/**
 * Default object
 */
export default [
  {
    value: LAST_MEMBER_LOGIN,
    label: 'Last member login',
  },
  {
    value: LAST_ADMIN_LOGIN,
    label: 'Last admin login',
  },
  {
    value: SETUP_GUIDE_COMPLETION,
    label: 'Setup guide completion',
  },
  {
    value: NUM_LOGGED_IN_ALL_TIME,
    label: 'Members logged in all time',
  },
  {
    value: NUM_LOGGED_IN_RECENTLY,
    label: 'Members logged in recently',
  },
  {
    value: NUM_INTEGRATIONS,
    label: 'Number of integrations',
  },
  {
    value: NUM_ADMINS,
    label: 'Number of admins',
  },
  {
    value: NUM_ONLINE_PAYMENTS,
    label: 'Number of online payments',
  },
  {
    value: NUM_BOOKINGS,
    label: 'Number of bookings',
  },
  {
    value: NUM_ATTENDEES,
    label: 'Number of event attendees',
  },
  {
    value: NUM_ACCESS_LOGS,
    label: 'Number of access logs',
  },
]
