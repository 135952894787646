<template>
  <div class="Toggle" @click="toggle()">
    <i class="Icon Icon--l" :class="{
      [`${iconClassOn}`]: isOn,
      [`${iconClassOff}`]: !isOn,
      'Icon--faint': isDisabled,
    }">{{icon}}</i>
    <label class="Toggle-label" v-if="label">{{label}}</label>
  </div>
</template>

<script>
export default {
  emits: [
    'change',
  ],
  props: {
    label: {
      type: String,
      default: () => '',
    },
    model: {
      type: Boolean,
      default: () => false,
    },
    onValue: {
      type: [Boolean, Number, String],
      default: () => true,
    },
    offValue: {
      type: [Boolean, Number, String],
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    iconOn: {
      type: String,
      default: () => 'toggle_on',
    },
    iconOff: {
      type: String,
      default: () => 'toggle_off',
    },
    iconClassOn: {
      type: String,
      default: () => 'Icon--success',
    },
    iconClassOff: {
      type: String,
      default: () => 'Icon--muted',
    },
  },
  computed: {
    isOn() {
      return (this.model === this.onValue)
    },
    icon() {
      if (this.isOn) {
        return this.iconOn
      }
      return this.iconOff
    },
  },
  methods: {

    /**
     * Toggle
     */
    toggle() {

      //Get data
      const {isDisabled, model, onValue, offValue} = this

      //Don't toggle when disabled
      if (isDisabled) {
        return
      }

      //Determine new value
      const value = (model === onValue) ? offValue : onValue

      //Get boolean value and emit change
      this.$emit('change', {value})
    },
  },
}
</script>

<style lang="scss">
.Toggle {
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.Toggle-label {
  margin-left: $spacing-xs;
  cursor: pointer;
}
</style>
