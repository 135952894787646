<template>
  <div class="ButtonBar">
    <div class="ButtonBar-primary">
      <button
        class="Button Button--confirm"
        v-if="showConfirm"
        :class="classesConfirm"
        :disabled="isDisabledConfirm || isBusy"
        @click="$emit('confirm')"
      >
        {{labelConfirm}}
        <spinner v-if="hasSpinnerConfirm || isBusy" />
      </button>
      <button
        class="Button Button--muted Button--cancel"
        v-if="showCancel"
        :disabled="isDisabledCancel || isBusy"
        @click="$emit('cancel')"
      >
        {{labelCancel}}
      </button>
    </div>
    <div class="ButtonBar-secondary">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  emits: [
    'confirm',
    'cancel',
  ],
  props: {

    //Buttons visibility
    showCancel: {
      type: Boolean,
      default: () => true,
    },
    showConfirm: {
      type: Boolean,
      default: () => true,
    },

    //Buttons disabled state
    isDisabledCancel: {
      type: Boolean,
      default: () => false,
    },
    isDisabledConfirm: {
      type: Boolean,
      default: () => false,
    },

    //Buttons labels
    labelCancel: {
      type: String,
      default: () => 'Cancel',
    },
    labelConfirm: {
      type: String,
      default: () => 'Confirm',
    },

    //Button type
    typeConfirm: {
      type: String,
      default: () => 'primary',
    },

    //Spinner state
    hasSpinnerConfirm: {
      type: Boolean,
      default: () => false,
    },

    //Convenience property to indicate standard business
    isBusy: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    classesConfirm() {
      return {
        'Button--primary': this.typeConfirm === 'primary',
        'Button--success': this.typeConfirm === 'success',
        'Button--warning': this.typeConfirm === 'warning',
        'Button--danger': this.typeConfirm === 'danger',
      }
    },
  },
}
</script>

<style lang="scss">
.ButtonBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include verticallySpaced;
  .Button + .Button {
    margin-left: $spacing-xs;
  }
}
.ButtonBar-primary {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ButtonBar--rightAligned {
  justify-content: flex-end;
  .ButtonBar-primary {
    order: 2;
  }
  .ButtonBar-secondary {
    order: 1;
  }
  .Button--confirm {
    order: 2;
  }
  .Button--cancel {
    order: 1;
  }
  .Button + .Button {
    margin-right: 0;
    margin-left: $spacing-xs;
  }
}
</style>
