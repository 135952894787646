<template>
  <context-menu>
    <a
      class="Dropdown-item is-danger"
      @click="$emit('remove')"
    >
      <i class="Icon">delete_forever</i> Remove
    </a>
  </context-menu>
</template>

<script>
import ContextMenu from '@/components/shared/context-menu'

export default {
  emits: [
    'remove',
  ],
  components: {
    ContextMenu,
  },
  props: {
    order: {
      type: Object,
    },
  },
}
</script>
