
/**
 * Timezone filter
 */
export default function(timezone) {
  if (!timezone || typeof timezone !== 'string') {
    return timezone
  }
  return timezone
    .replace(/\//g, ' / ')
    .replace(/_/g, ' ')
}
