<template>
  <modal-edit
    title="Update systems"
    label-confirm="Update"
    type="success"
    :model="model"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <group
      label="Type"
    >
      <check-box
        v-for="type in typeOptions"
        :key="type.value"
        :model="model.types"
        :checked-value="type.value"
        :is-array="true"
        @change="$set(model, 'types', $event.value)"
      >
        {{type.label}}
      </check-box>
    </group>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {
      model: {
        types: [],
      },
      typeOptions: [
        {
          value: 'ict',
          label: 'ICT',
        },
        {
          value: 'dynalite',
          label: 'Dynalite',
        },
        {
          value: 'he900',
          label: 'HE900',
        },
        {
          value: 'he600',
          label: 'HE600',
        },
      ],
    }
  },
  props: {
    onSave: {
      type: Function,
    },
  },
}
</script>

<style module lang="scss">

</style>
