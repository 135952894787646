import BaseApi from './base.api'
import Club from '@/models/club.model'

/**
 * Club API service
 */
class ClubApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/club',
      dataKey: 'clubs',
      Model: Club,
    })
  }

  /**
   * Aggregate
   */
  aggregate(property, accumulator = 'sum', filter) {
    return this
      .get(`aggregate/${accumulator}/${property}`, filter)
      .then(data => data.value)
  }

  /**
   * Patch module
   */
  patchModule(id, module, data) {
    return this
      .patch(`${id}/module/${module}`, data)
  }

  /**
	 * Change plan
	 */
  changePlan(id, data, params) {
    return this
      .post(`${id}/changePlan`, data, {params})
  }

  /**
	 * Create comment
	 */
  createComment(id, data) {
    return this
      .post(`${id}/comment`, data)
  }

  /**
	 * Update comment
	 */
  updateComment(id, commentId, data) {
    return this
      .patch(`${id}/comment/${commentId}`, data)
  }

  /**
	 * Remove comment
	 */
  removeComment(id, commentId) {
    return this
      .delete(`${id}/comment/${commentId}`)
  }
}

/**
 * Export singleton instance
 */
export default new ClubApi()
