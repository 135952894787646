
//Common label/value property combinations
const combos = [
  ['label', 'value'],
  ['name', 'id'],
]

/**
 * Helper to make replacement
 */
function replace(value, labelProp, valueProp, items) {

  //Get value
  if (value && typeof value === 'object' && value.id) {
    value = value.id
  }

  //Find item and return label prop or value as is
  const item = items.find(item => item[valueProp] === value)
  return item ? item[labelProp] : value
}

/**
 * Common label generator filter function
 */
export default function(value, constant, labelProp, valueProp, glue = ', ') {

  //If no value, done
  if (!value) {
    return ''
  }

  //Must have both label and value prop, or neither
  if (labelProp && !valueProp || valueProp && !labelProp) {
    throw new Error(
      'Must have both label/value prop, or neither for auto detection'
    )
  }

  //No constant or no items within?
  if (!constant || !Array.isArray(constant) || constant.length === 0) {
    return ''
  }

  //Determine label and value props if not given
  if (!labelProp || !valueProp) {
    for (const props of combos) {
      [labelProp, valueProp] = props
      if (
        typeof constant[0][labelProp] !== 'undefined' &&
        typeof constant[0][valueProp] !== 'undefined'
      ) {
        break
      }
    }
  }

  //Array given?
  if (Array.isArray(value)) {
    return value
      .map(v => replace(v, labelProp, valueProp, constant))
      .join(glue)
  }

  //Simple value
  return replace(value, labelProp, valueProp, constant)
}
