// import {DateTime} from 'luxon'

/**
 * Lookup dotted path in an object
 */
export function get(obj, path, defaultValue = undefined) {

  //Empty path, return object
  if (!path) {
    return obj
  }

  //Split path in object keys to traverse
  const keys = path.split('.')
  for (let i = 0; i < keys.length && obj !== undefined; i++) {
    const key = keys[i]
    obj = (obj !== null) ? obj[key] : undefined
  }

  //Return default value if undefined
  if (obj === undefined) {
    return defaultValue
  }

  //Return reference
  return obj
}

/**
 * Set dotted path value in an object
 */
export function set(obj, path, value) {

  //Empty path
  if (!path) {
    return
  }

  //Split path in object keys to traverse
  const keys = path.split('.')
  for (let i = 0; i < (keys.length - 1); i++) {
    const key = keys[i]
    if (!obj[key]) {
      obj[key] = {}
    }
    obj = obj[key]
  }

  //Get last key
  const prop = keys[keys.length - 1]
  obj[prop] = value
}

/**
 * Only ID
 */
export function onlyId(obj) {
  if (Array.isArray(obj)) {
    return obj.map(onlyId)
  }
  if (!obj || typeof obj !== 'object' || !obj.id) {
    return obj
  }
  return obj.id
}

/**
 * Helper to copy a property
 */
export function copyProperty(obj, key) {
  if (Array.isArray(obj[key])) {
    return obj[key].map((value, key) => copyProperty(obj[key], key))
  }
  if (obj[key] === undefined) {
    return undefined
  }
  if (obj[key] === null) {
    return null
  }
  // if (obj[key] instanceof DateTime) {
  //   return DateTime.fromObject(obj[key].toObject())
  // }
  if (typeof obj[key] === 'object') {
    if (obj[key].isModel) {
      return obj[key].id
    }
    return Object.assign({}, obj[key])
  }
  return obj[key]
}

/**
 * Extract subset of data from an object
 */
export function extract(obj, keys) {

  //If string given, just return copy of one property
  if (typeof keys === 'string') {
    return copyProperty(obj, keys)
  }

  //No keys given? Iterate all object keys
  if (!Array.isArray(keys) || keys.length === 0) {
    keys = Object.keys(obj)
  }

  //Reduce to extract
  return keys.reduce((extract, key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      extract[key] = copyProperty(obj, key)
    }
    return extract
  }, {})
}

/**
 * Flatten a path in an object, returning a new object
 */
export function flatten(obj, key) {
  if (!obj[key] || typeof obj[key] !== 'object') {
    return obj
  }
  const newObj = Object.assign({}, obj)
  Object
    .keys(obj[key])
    .forEach(childKey => {
      newObj[`${key}.${childKey}`] = obj[key][childKey]
    })
  delete newObj[key]
  return newObj
}
