<template>
  <modal-confirm
    type="danger"
    title="Remove feedback"
    label-confirm="Remove"
    :on-confirm="onConfirm"
    @close="$emit('close')"
  >
    <p>Are you sure you want to remove the feedback of <strong>{{feedback.member ? feedback.member.name : 'unknown member'}}</strong>?</p>
  </modal-confirm>
</template>

<script>
import ModalConfirm from '@/components/shared/modals/modal-confirm'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalConfirm,
  },
  props: {
    feedback: {
      type: Object,
    },
    onConfirm: {
      type: Function,
    },
  },
}
</script>
