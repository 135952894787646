
/**
 * Dependencies
 */
import i from 'i'
const inflect = i()

//Custom rules
inflect.inflections.plural('feedback', 'feedback')

/**
 * Inflection filter
 */
export default function(str, method) {
  if (typeof str !== 'string') {
    return ''
  }
  return inflect[method](str)
}
