import BaseModel from './base.model'
import Club from './club.model'
import Member from './member.model'
import TagOrderApi from '@/api/tag-order.api'

/**
 * Tag Order model
 */
export default class TagOrder extends BaseModel {

  /**
   * Setup
   */
  setup() {

    //Parent method
    super.setup()

    //Sub models
    this.subModels = {
      club: Club,
      member: Member,
    }
  }

  /**
   * Patch
   */
  patch(data) {

    //Get ID
    const {id} = this

    //Update
    return TagOrderApi
      .update(id, data)
      .then(data => this.fromJSON(data))
  }

  /**
   * Remove
   */
  remove() {

    //Get ID
    const {id} = this

    //Update
    return TagOrderApi
      .remove(id)
  }
}
