import ClientError from './client'

/**
 * Validation error
 */
export default class ValidationError extends ClientError {

  /**
   * Constructor
   */
  constructor(message, data) {

    //Parent constructor
    super(message, data, 422)
  }
}
