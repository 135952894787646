import Vue from 'vue'
import router from '@/router'
import cfg from '@/services/cfg'
import auth from '@/services/auth'
import modal from '@/services/modal'
import notice from '@/services/notice'
import sentry from '@/services/sentry'
import ErrorModal from '@/components/shared/modals/modal-error'

/**
 * Import errors
 */
import {
  ResponseError,
  NetworkError,
  ClientError,
  ServerError,
  ExistsError,
  TimeoutError,
  ValidationError,
  NotFoundError,
  NotAuthorizedError,
  NotAuthenticatedError,
  ServiceUnavailableError
} from '@/errors/index'

/**
 * Create mixin to load the errors service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.errors) {
      this.$err = options.errors
    }
    else if (options.parent && options.parent.$err) {
      this.$err = options.parent.$err
    }
  },
})

/**
 * Errors service class
 */
class ErrorsService {

  /**
   * Capture global non-Vue errors
   */
  constructor() {

    //Add event listener for general errors outside of Vue's scope
    window.addEventListener('error', event => {
      if (event.reason) {
        this.process(event.reason)
      }
      event.preventDefault()
    })

    //Add event listener for unhandled rejections
    window.addEventListener('unhandledrejection', event => {
      if (event.reason && !event.reason.ignoreUnhandledRejection) {
        this.process(event.reason)
      }
      event.preventDefault()
    })
  }

  /**
   * Process error
   */
  process(error, redirect = false, replace = false) {

    //Log to console
    if (cfg.errors.log) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    //Capture in Sentry
    sentry.captureException(error)

    //Not authenticated errors, unauthenticate user (this will redirect to login)
    if (error instanceof NotAuthenticatedError) {
      return auth.unAuthenticate()
    }

    //Redirect
    if (redirect) {

      //Error route
      const route = {name: 'error', params: {error}}
      const method = replace ? 'replace' : 'push'

      //Redirect to error page
      return router[method](route)
    }

    //Show modal if this was a response error
    if (error instanceof ResponseError) {
      return modal.show(ErrorModal, {error})
    }

    //Show notice for client errors
    notice.show(`Oops, something went wrong`, {
      type: 'danger',
      icon: 'warning',
      hideAutomatically: false,
    })
  }

  /**
   * Get error class for response errors
   */
  getClass(response) {

    //Network error
    if (typeof response === 'undefined') {
      return NetworkError
    }

    //Get data
    const {status} = response

    //Unauthenticated errors
    if (status === 401) {
      return NotAuthenticatedError
    }

    //Unauthorized errors
    else if (status === 403) {
      return NotAuthorizedError
    }

    //Not found errors
    else if (status === 404) {
      return NotFoundError
    }

    //Exists errors
    else if (status === 409) {
      return ExistsError
    }

    //Validation errors
    else if (status === 422) {
      return ValidationError
    }

    //Service unavailable errors
    else if (status === 503) {
      return ServiceUnavailableError
    }

    //Request timeout
    else if (status === 408 || status === 504) {
      return TimeoutError
    }

    //Generic client errors
    else if (status >= 400 && status <= 499) {
      return ClientError
    }

    //Server errors
    else if (status >= 500 && status <= 599) {
      return ServerError
    }

    //Generic response errors
    return ResponseError
  }
}

/**
 * Export singleton instance
 */
export default new ErrorsService()
