<template>
  <div class="Container Container--centered Container--centeredVertically">
    <div class="PortalForm">
      <img src="@/assets/logo.svg" class="PortalLogo">
      <h2 class="PortalHeader">Sign in to Hello Club</h2>
      <form @submit.prevent="login()" novalidate>
        <div class="Block">
          <input
            v-model="credentials.email"
            type="email"
            class="Input Input--block Input--white"
            placeholder="Email"
          >
        </div>
        <div class="Block">
          <input
            v-model="credentials.password"
            type="password"
            class="Input Input--block Input--white"
            placeholder="Password"
          >
        </div>
        <p class="text-danger" v-if="isErrorInvalidLogin">Invalid sign in details</p>
        <div class="Block">
          <button
            class="Button Button--primary"
            :disabled="isLoggingIn"
          >
            Sign in
            <spinner />
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth'
import cfg from '@/services/cfg'
import NotAuthenticatedError from '@/errors/not-authenticated'

export default {
  data() {
    return {
      isErrorInvalidLogin: false,
      isLoggingIn: false,
      credentials: {
        email: '',
        password: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {

    //If we are already authenticated, go to dashboard
    if (auth.isAuthenticated()) {
      return next(cfg.auth.homeRoute)
    }

    //Otherwise, all good
    next()
  },
  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Login',
      crumbs: [
        {
          title: 'Login',
          route: cfg.auth.loginRoute,
        },
      ],
    })
  },
  methods: {

    /**
     * Login
     */
    async login() {

      //Get data
      const {email, password} = this.credentials
      const {redirect} = this.$route.query

      //Reset flags
      this.isErrorInvalidLogin = false
      this.isLoggingIn = true

      //Login
      try {
        await auth
          .loginWithCredentials(email, password, redirect)
          .finally(() => this.isLoggingIn = false)
      }
      catch (error) {

        //Not authenticated
        if (error instanceof NotAuthenticatedError) {
          return this.isErrorInvalidLogin = true
        }

        //Process other errors
        this.$err.process(error)
      }
    },
  },
}
</script>

<style lang="scss">
  .PortalForm {
    width: 24rem;
    padding: $spacing-xl;
    background: $porcelain;
    box-shadow: 2px 2px 7px rgba(0,0,0,0.03);
  }
  .PortalLogo {
    margin-bottom: $spacing-xl;
    width: 7rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .PortalHeader {
    text-align: center;
    margin-bottom: $spacing-xl;
  }
</style>
