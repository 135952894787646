<template>
  <card
    title="Permissions"
  >
    <template v-slot:title>
      <i
        class="Icon Icon--faint Icon--s Icon--inline clickable"
        @click="toggleEditing(true)"
        v-tooltip.bottom="'Edit permissions'"
        v-if="!isEditing"
      >edit</i>
    </template>
    <grid rows="8" row-spacing="xs">
      <simple-label
        :label="permission.name"
        v-for="permission in permissions"
        :key="permission.id"
      >
        <permission-value
          :club="club"
          :permission="permission"
          v-if="!isEditing"
        />
        <div v-if="isEditing">
          <input
            v-if="permission.isNumber"
            class="Input Input--onGray Input--xs"
            type="text"
            v-model="model[permission.identifier]"
          >
          <select-box
            v-if="permission.isFlag"
            class="SelectBox--xs"
            input-class="Input--onGray"
            :options="YesNo"
            :is-nullable="true"
            :is-null-undefined="true"
            :model="model[permission.identifier]"
            @change="$set(model, permission.identifier, $event.value)"
          />
          <div class="InputSuffix">{{permission.unit}}</div>
        </div>
      </simple-label>
    </grid>
    <button-bar
      v-if="isEditing"
      label-confirm="Save"
      :has-spinner-confirm="true"
      :is-disabled-confirm="isSaving"
      @cancel="toggleEditing(false)"
      @confirm="save()"
    />
  </card>
</template>

<script>
import PermissionValue from '../permission-value'
import YesNo from '@/constants/yes-no'

export default {
  components: {
    PermissionValue,
  },
  props: {
    club: {
      type: Object,
      default: () => null,
    },
    permissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

      //Flags
      isEditing: false,
      isSaving: false,

      //Model
      model: {},

      //Constants
      YesNo,
    }
  },
  async created() {

    //Extract model
    this.model = this.club.extract('customPermissions')
  },
  methods: {

    /**
     * Edit permissions
     */
    toggleEditing(isEditing) {
      this.isEditing = isEditing
    },

    /**
     * Save permissions
     */
    async save() {

      //Get data
      const customPermissions = this.model

      //Ensure that values which have been set as empty strings are set to undefined
      for (const key in customPermissions) {
        if (customPermissions[key] === '') {
          customPermissions[key] = undefined
        }
      }

      //Toggle flag
      this.isSaving = true

      //Patch
      await this.club
        .patch({customPermissions})
        .finally(() => this.isSaving = false)

      //Toggle editing flag and load new model
      this.model = this.club.extract('customPermissions')
      this.isEditing = false
    },
  },
}
</script>

<style scoped lang="scss">
</style>
