<template>
  <div class="IconLabel">
    <i
      class="Icon IconLabel-icon"
      :class="iconClass"
      v-if="icon"
      @click="$emit('clickOnIcon')"
    >{{icon}}</i>
    <div @click="$emit('clickOnLabel')">
      <label class="IconLabel-label">{{label}}</label>
      <div class="IconLabel-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
    'clickOnIcon',
    'clickOnLabel',
  ],
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    iconClass: {
      type: [String, Object],
      default: () => '',
    },
  },
}
</script>

<style lang="scss">
.IconLabel {
  display: flex;
  align-items: center;
}
.IconLabel-icon {
  margin-right: $spacing-m;
  color: $silver;
  &.Icon--success {
  color: $green;
  }
  &.Icon--danger {
    color: $red;
  }
  &.Icon--warning {
    color: $yellow;
  }
  &.Icon--info {
    color: $blue;
  }
}
.IconLabel-label {
  display: block;
  color: $oslo;
  text-transform: lowercase;
  font-size: $font-s;
  line-height: 1.25rem;
}
.IconLabel-content {
  line-height: $line-height;
}
</style>
