import UserApi from '@/api/user.api'
import auth from '@/services/auth'

/**
 * Load session user
 */
export const loadUser = async ({commit}) => {

  //Get user
  try {
    const user = await UserApi.me()
    commit('setUser', user)
  }
  catch (error) {

    //Failed loading the user, unauthenticate and rethrow error
    auth.unAuthenticate()
    throw error
  }
}

/**
 * Unload session user
 */
export const unloadUser = async ({commit}) => {

  //Clear from store
  commit('setUser', null)
}

/**
 * Flag as loaded
 */
export const loaded = async ({commit}, isLoaded) => {

  //Set loaded
  commit('setLoaded', isLoaded)
}
