import Vue from 'vue'
import Vuex from 'vuex'

//Load plugin
Vue.use(Vuex)

/**
 * Custom store modules
 */
import page from './modules/page'
import data from './modules/data'
import session from './modules/session'

/**
 * Export Vuex store instance
 */
export default new Vuex.Store({
  modules: {
    page,
    data,
    session,
  },
})
