<template>
  <modal-edit
    title="Edit billing details"
    :model="model"
    :params="params"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <grid cols="2" row-spacing="m">
        <group
          label="Contact"
          :validation="$v.model.billing.contact"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.billing.contact"
          >
        </group>
        <group
          label="Email"
          :validation="$v.model.billing.email"
        >
          <input
            class="Input"
            type="email"
            v-model.trim="model.billing.email"
          >
        </group>
      </grid>
      <group
        label="Entity"
        :validation="$v.model.billing.entity"
      >
        <input
          class="Input"
          type="text"
          v-model.trim="model.billing.entity"
        >
      </group>
      <grid cols="2" row-spacing="m">
        <group
          label="Stripe customer ID"
          :validation="$v.model.stripe.customerId"
        >
          <input
            class="Input"
            type="text"
            :disabled="params.pushToStripe"
            placeholder="cus_xxx"
            v-model.trim="model.stripe.customerId"
          >
        </group>
        <group
          label="Stripe subscription ID"
          :validation="$v.model.stripe.subscriptionId"
        >
          <input
            class="Input"
            type="text"
            :disabled="params.pushToStripe"
            placeholder="sub_xxx"
            v-model.trim="model.stripe.subscriptionId"
          >
        </group>
      </grid>
      <group
        label="Options"
      >
        <toggle
          :model="params.pushToStripe"
          :label="pushToStripeLabel"
          @change="togglePushToStripe($event.value)"
        />
      </group>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import {helpers, required, email} from 'vuelidate/lib/validators'

//Validators for Stripe ID's
const customerId = (value) => !helpers.req(value) || Boolean(value.match(/^cus_/))
const subscriptionId = (value) => !helpers.req(value) || Boolean(value.match(/^sub_/))

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {
      model: {
        billing: {
          contact: null,
          email: null,
          entity: null,
        },
        stripe: {
          customerId: null,
          subscriptionId: null,
        },
      },
      params: {
        pushToStripe: null,
      },
    }
  },
  props: {
    club: {
      type: Object,
    },
    onSave: {
      type: Function,
    },
  },
  computed: {
    pushToStripeLabel() {
      return this.club.stripe.customerId ?
        'Update customer in Stripe' :
        'Create customer in Stripe'
    },
  },
  validations: {
    model: {
      billing: {
        contact: {
          required,
        },
        email: {
          required,
          email,
        },
        entity: {
          required,
        },
      },
      stripe: {
        customerId: {
          invalid: customerId,
        },
        subscriptionId: {
          invalid: subscriptionId,
        },
      },
    },
  },
  created() {

    //Get data
    const {club, model, params} = this

    //Set model data
    model.billing = club.billing.extract(Object.keys(model.billing))
    model.stripe = club.stripe.extract(Object.keys(model.stripe))

    //Set params
    params.pushToStripe = true
  },
  methods: {

    /**
     * Toggle push to Stripe setting
     */
    togglePushToStripe(value) {

      //Set value
      const {club, model, params} = this
      this.$set(params, 'pushToStripe', value)

      //If true, and we didn't have existing Stripe ID's, clear anything we
      //may have entered
      if (!club.stripe.customerId) {
        this.$set(model.stripe, 'customerId', '')
      }
      if (!club.stripe.subscriptionId) {
        this.$set(model.stripe, 'subscriptionId', '')
      }
    },
  },
}
</script>
