<template>
  <div class="ClubComment">
    <label class="CommentDate">{{comment.name}} – {{comment.date | moment('D MMM YYYY, HH:mm')}}
      <i
        class="Icon Icon--faint Icon--xs Icon--inline clickable"
        @click="$emit('edit')"
        v-tooltip.bottom="'Edit comment'"
      >edit</i>
      <i
        class="Icon Icon--faint Icon--xs Icon--inline clickable"
        @click="$emit('remove')"
        v-tooltip.bottom="'Remove comment'"
      >delete</i>
    </label>
    <div class="CommentText Text--nl2br">{{comment.text}}</div>
  </div>
</template>

<script>
export default {
  emits: [
    'edit',
    'remove',
  ],
  props: {
    comment: {
      type: Object,
    },
  },
}
</script>

<style lang="scss">
.ClubComment {
  align-items: center;
  margin-top: $spacing-m;
}
.CommentDate {
  color: $oslo;
  font-size: $font-s;
  white-space: nowrap;
  overflow: hidden;
}
.CommentText {
  margin-top: $spacing-xxs;
  padding: $spacing-xs 0;
}
</style>
