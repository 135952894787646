
/**
 * Make a global click handler
 */
export function clickHandler(handler, refs) {

  //Reference given, wrap in custom handler with a check
  //to see if the element was clicked. Only call the handler
  //function when it wasn't (useful to hide dropdowns)
  if (refs) {

    //Remember handler
    const func = handler

    //Create wrapper handler
    handler = function(event) {
      if (!isTarget(event.target, refs)) {
        func()
      }
    }
  }

  //Add event listener
  document.addEventListener('click', handler)

  //Return helper function to remove listener
  return () => document.removeEventListener('click', handler)
}

/**
 * Helper to check if user interacted with specific elements
 */
export function isTarget(target, refs) {

  //Ensure array
  if (!Array.isArray(refs)) {
    refs = [refs]
  }

  //Check if contain target
  return refs.some(ref => ref.contains(target))
}
