<template>
  <div v-if="filter">

    <!-- Status -->
    <filter-item
      :filter="filter"
      filter-key="status"
      label="Status"
      icon="lens"
    >
      <radio-buttons
        :model="filter.status"
        :options="statuses"
        @change="filter.update('status', $event.value)"
      />
    </filter-item>

    <!-- Active state -->
    <filter-item
      :filter="filter"
      filter-key="active"
      label="Active"
      icon="speed"
    >
      <radio-buttons
        :model="filter.active"
        :options="activeStates"
        @change="filter.update('active', $event.value)"
      />
    </filter-item>

    <!-- Plan -->
    <filter-item
      :filter="filter"
      filter-key="plan"
      label="Plan"
      icon="bookmark"
    >
      <div class="CheckBoxGroup">
        <check-box
          v-for="plan in plans"
          :key="plan.id"
          :model="filter.plans"
          :checked-value="plan.id"
          :is-array="true"
          @change="filter.update('plans', $event.value)"
        >
          <i
            class="Icon PlanIcon"
            :class="`PlanIcon--${plan.identifier}`"
          >bookmark</i>
          {{plan.name}}
        </check-box>
      </div>
    </filter-item>

    <!-- Term -->
    <filter-item
      :filter="filter"
      filter-key="term"
      label="Term"
      icon="schedule"
    >
      <radio-buttons
        :model="filter.term"
        :options="termValues"
        @change="filter.update('term', $event.value)"
      />
    </filter-item>

    <!-- Country -->
    <filter-item
      :filter="filter"
      filter-key="countryCode"
      label="Country"
      icon="language"
    >
      <select-box
        :model="filter.countryCode"
        :options="countries"
        @change="filter.update('countryCode', $event.value)"
      />
    </filter-item>

    <!-- Setup progress -->
    <filter-item
      :filter="filter"
      filter-key="progress"
      label="Setup"
      icon="build"
    >
      <radio-buttons
        :model="filter.progress"
        :options="progressValues"
        @change="filter.update('progress', $event.value)"
      />
    </filter-item>

    <!-- Engagement score -->
    <filter-item
      :filter="filter"
      filter-key="engagement"
      label="Engagement"
      icon="star"
    >
      <radio-buttons
        :model="filter.engagement"
        :options="engagementValues"
        @change="filter.update('engagement', $event.value)"
      />
    </filter-item>

    <!-- Check in -->
    <filter-item
      :filter="filter"
      filter-key="lastCheckIn"
      label="Last check-in"
      icon="emoji_people"
    >
      <radio-buttons
        :model="filter.lastCheckIn"
        :options="lastCheckinPeriods"
        @change="filter.update('lastCheckIn', $event.value)"
      />
    </filter-item>
  </div>
</template>

<script>
import FilterService from '@/services/filter'

export default {
  props: {
    plans: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filter: FilterService.get('clubs'),
    statuses: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Live',
        value: 'live',
      },
      {
        label: 'Trial',
        value: 'trial',
      },
      {
        label: 'Cancelled',
        value: 'cancelled',
      },
      {
        label: 'Partner',
        value: 'partner',
      },
    ],
    activeStates: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ],
    termValues: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yearly',
        value: 'yearly',
      },
      {
        label: 'Monthly',
        value: 'monthly',
      },
    ],
    progressValues: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Up to 25%',
        value: '<25',
      },
      {
        label: 'Up to 50%',
        value: '<50',
      },
      {
        label: 'Up to 75%',
        value: '<75',
      },
      {
        label: 'Up to 90%',
        value: '<90',
      },
      {
        label: 'Over 25%',
        value: '>25',
      },
      {
        label: 'Over 50%',
        value: '>50',
      },
      {
        label: 'Over 75%',
        value: '>75',
      },
      {
        label: 'Over 90%',
        value: '>90',
      },
    ],
    engagementValues: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Up to 25%',
        value: '<0.25',
      },
      {
        label: 'Up to 50%',
        value: '<0.50',
      },
      {
        label: 'Up to 75%',
        value: '<0.75',
      },
      {
        label: 'Up to 90%',
        value: '<0.90',
      },
      {
        label: 'Over 25%',
        value: '>0.25',
      },
      {
        label: 'Over 50%',
        value: '>0.50',
      },
      {
        label: 'Over 75%',
        value: '>0.75',
      },
      {
        label: 'Over 90%',
        value: '>0.90',
      },
    ],
    lastCheckinPeriods: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Less than one month ago',
        value: 'lessOneMonth',
      },
      {
        label: 'Less than 3 months ago',
        value: 'lessThreeMonths',
      },
      {
        label: 'Less than 6 months ago',
        value: 'lessSixMonths',
      },
      {
        label: 'Less than a year ago',
        value: 'lessTwelveMonths',
      },
      {
        label: 'More than a month ago',
        value: 'moreOneMonth',
      },
      {
        label: 'More than 3 months ago',
        value: 'moreThreeMonths',
      },
      {
        label: 'More than 6 months ago',
        value: 'moreSixMonths',
      },
      {
        label: 'More than a year ago',
        value: 'moreTwelveMonths',
      },
      {
        label: 'Never',
        value: 'never',
      },
    ],
  }),
}
</script>
