<template>
  <div class="PageHeader">
    <div class="PageInfo">
      <spinner class="PageInfo-spinner" v-if="isLoading" />
      <page-crumbs
        v-else-if="crumbs.length > 0"
        :crumbs="crumbs"
      />
      <h1 class="PageInfo-title" v-else>{{title}}</h1>
      <div class="PageInfo-suffix" v-if="suffix && !isLoading">
        {{suffix}}
      </div>
      <div class="PageInfo-suffix" v-else-if="type && !isLoading">
        {{totalItems}} {{plural}}
      </div>
    </div>
    <div class="PageActions">

      <!-- Other actions -->
      <slot name="actions" />

      <!-- Filters -->
      <div class="PageAction PageAction--filters" v-if="filter">
        <page-filter
          :filter="filter"
        >
          <slot name="filters" />
        </page-filter>
        <!-- <span v-for="item in nonDefaults" :key="item.key">{{item.key}} {{item.value}}</span> -->
      </div>

      <!-- Search -->
      <div class="PageAction PageAction--search" v-if="showSearch && filter">
        <page-search
          :filter="filter"
          :plural="plural"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageCrumbs from './page-crumbs'
import PageFilter from './page-filter'
import PageSearch from './page-search'

export default {
  components: {
    PageCrumbs,
    PageFilter,
    PageSearch,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    suffix: {
      type: String,
      default: () => '',
    },
    crumbs: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => null,
    },
    totalItems: {
      type: Number,
      default: () => 0,
    },
    type: {
      type: String,
      default: () => '',
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      query: '',
      isFocusOnSearch: false,
      isShowingFilters: false,
    }
  },
  computed: {
    plural() {
      return this.$options.filters.inflect(this.type, 'pluralize')
    },
    nonDefaults() {
      return this.filter.getNonDefaults()
    },
  },
}
</script>

<style lang="scss">
.PageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: $spacing-m;
}
.PageInfo {
  padding-right: $spacing-xl;
  min-width: 20rem;
  height: 2.75rem;
}
.PageInfo-title {
  margin-bottom: $spacing-xxs;
}
.PageInfo-suffix {
  color: $oslo;
  padding-top: $spacing-xxs;
}
.PageInfo-spinner {
  margin-top: $spacing-xs;
}

//Page actions
.PageActions {
  flex: 1;
  display: flex;
  align-items: center;
  height: 2.75rem;
  padding-left: $spacing-m;
  &:not(:empty) {
    border-left: 1px solid $sparta;
  }
}
.PageAction {
  position: relative;
  display: flex;
  align-items: center;
}
.PageAction-icon {
  position: relative;
  &:hover {
    color: $oslo;
  }
}
.PageAction-icon--active {
  color: $oslo;
}
.PageAction-icon--inUse {
  &:after {
    content: '';
    right: -2px;
    top: 0;
    position: absolute;
    display: block;
    background-color: $green;
    height: 12px;
    width: 12px;
    border: 2px solid $white;
    border-radius: 6px;
  }
}
.PageAction--search {
  flex: 1;
}
</style>
