
/**
 * Feedback for types
 */
export const APP = 'app'
export const CLUB = 'club'

/**
 * Default object
 */
export default [
  {
    value: APP,
    label: 'App',
  },
  {
    value: CLUB,
    label: 'Clubs',
  },
]
