<template>
  <modal-edit
    title="Edit comment"
    :model="model"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <group
        label="Comment"
      >
        <textarea
          class="Input"
          rows="10"
          ref="comments"
          v-model.trim="model.text"
        />
      </group>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {
      model: {
        text: '',
      },
    }
  },
  props: {
    comment: {
      type: Object,
    },
    onSave: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.comments.focus()
  },
  created() {

    //Create model subset
    this.model = this.comment.extract(Object.keys(this.model))
  },
}
</script>
