<template>
  <div class="Panel">
    <label class="Panel-label" v-if="label">{{label}}</label>
    <spinner v-if="isAsync && !hasValue" />
    <div class="Panel-value" v-if="hasValue" :class="{
      'Panel-value--prominent': isProminent
    }">
      <span v-if="isCurrency">
        {{value | currency({fractionCount: 0})}}
      </span>
      <span v-else-if="isPercentage">
        {{value | percentage({maximumFractionDigits: 2})}}
      </span>
      <span v-else-if="isNumber">
        {{value | number}}
        {{suffix}}
      </span>
      <span v-else>
        {{value}}
        {{suffix}}
      </span>
    </div>
    <slot v-if="!isAsync || hasValue" />
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Number],
      default: () => undefined,
    },
    label: {
      type: String,
      default: () => '',
    },
    suffix: {
      type: String,
      default: () => '',
    },
    isAsync: {
      type: Boolean,
      default: () => false,
    },
    isCurrency: {
      type: Boolean,
      default: () => false,
    },
    isNumber: {
      type: Boolean,
      default: () => false,
    },
    isPercentage: {
      type: Boolean,
      default: () => false,
    },
    isProminent: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    hasValue() {
      return (this.value !== undefined)
    },
  },
}
</script>

<style lang="scss">
.Panel {
  background-color: $athens;
  border-radius: 0;
  padding: $spacing-m;
  box-shadow: 2px 2px 7px rgba(0,0,0,0.03);
  transition: background-color 0.2s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0s;
  &:hover {
    background-color: $white;
  }
}
.Panel-label {
  display: block;
  color: $oslo;
  padding-bottom: $spacing-xs;
}
.Panel-value {
  font-size: $font-xxl;
  font-weight: $extra-bold;
  margin-bottom: $spacing-xs;
  color: $black;
}
.Panel-value--prominent {
  font-size: 2.5rem;
  color: $green;
}
</style>
