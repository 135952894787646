import Vue from 'vue'
import config from '@/config/index'

/**
 * Create mixin to load the config service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.cfg) {
      this.$cfg = options.cfg
    }
    else if (options.parent && options.parent.$cfg) {
      this.$cfg = options.parent.$cfg
    }
  },
})

/**
 * Config service class
 */
class ConfigService {

  /**
   * Constructor
   */
  constructor() {

    //Get application environment/mode
    const env = process.env.VUE_APP_ENV || process.env.NODE_ENV

    //Check if configuration exists for this environment
    if (typeof config[env] === 'undefined') {
      throw new Error(`Missing configuration file for app environment: ${env}`)
    }

    //Get the specific configuration and set config params
    const specific = config[env] || {}
    Object.assign(this, {env}, specific)
  }
}

/**
 * Export singleton instance
 */
export default new ConfigService()
