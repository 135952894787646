import Vue from 'vue'
import moment from '@/services/moment'

/**
 * Create mixin to load the authentication service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.filter) {
      this.$filter = options.filter
    }
    else if (options.parent && options.parent.$filter) {
      this.$filter = options.parent.$filter
    }
  },
})

/**
 * Filter class
 */
class Filter {

  /**
   * Constructor
   */
  constructor(type) {

    //Define non enumerable private properties
    Object.defineProperty(this, '_defaults', {
      writable: true,
    })
    Object.defineProperty(this, '_type', {
      writable: true,
    })
    Object.defineProperty(this, '_changeHandlers', {
      writable: true,
    })
    Object.defineProperty(this, '_mappedValues', {
      writable: true,
    })

    //Save type and initialize on change handlers
    this._type = type
    this._changeHandlers = []
    this._mappedValues = {}
  }

  /**
   * Has defaults check
   */
  get hasDefaults() {
    for (const key in this._defaults) {
      if (Object.prototype.hasOwnProperty.call(this._defaults, key)) {
        if (!this.isDefault(key)) {
          return false
        }
      }
    }
    return true
  }

  /**************************************************************************
   * Defaults handling
   ***/

  /**
   * Get defaults
   */
  getDefaults() {
    return this._defaults
  }

  /**
   * Set multiple default values
   */
  setDefaults(values, overwrite = false) {

    //Don't overwrite if already set
    if (this._defaults && !overwrite) {
      return
    }

    //Initialize and set default values
    this._defaults = this._defaults || {}
    Object.assign(this._defaults, values)

    //Reset now
    this.resetToDefaults()
  }

  /**
   * Change or set a default value
   */
  setDefault(key, value) {
    this._defaults[key] = value
  }

  /**
   * Check if a key contains the default value
   */
  isDefault(key) {
    if (moment.isMoment(this[key])) {
      return this[key].isSame(this._defaults[key])
    }
    if (Array.isArray(this[key])) {
      if (this[key].length === 0 && this._defaults[key] === null) {
        return true
      }
      return false
    }
    return (this._defaults[key] === this[key])
  }

  /**
   * Ensure a key is present in default values, if not, add it with null value
   */
  ensureInDefaults(key) {
    if (typeof this._defaults[key] === 'undefined') {
      this.setDefault(key, null)
    }
  }

  /**
   * Reset back to defaults
   */
  resetToDefaults(silent) {
    for (const key in this._defaults) {
      if (Object.prototype.hasOwnProperty.call(this._defaults, key)) {
        const value = this._defaults[key]
        if (moment.isMoment(value)) {
          Vue.set(this, key, value.clone())
        }
        else {
          Vue.set(this, key, value)
        }
      }
    }
    if (!silent) {
      this.triggerChange()
    }
  }

  /**
   * Load initial values based on existing keys
   */
  loadValues(data) {
    const keys = Object.keys(this)
    for (const key of keys) {
      if (typeof data[key] !== 'undefined') {
        let value = data[key]
        if (value === 'true') {
          value = true
        }
        else if (value === 'false') {
          value = false
        }
        this.update(key, value)
      }
    }
  }

  /**
   * Get non-default values
   */
  getNonDefaults(ignore = ['search']) {
    const nonDefaults = []
    const keys = Object.keys(this)
    for (const key of keys) {
      if (!ignore.includes(key) && !this.isDefault(key)) {
        const value = this[key]
        nonDefaults.push({key, value})
      }
    }
    return nonDefaults
  }

  /**************************************************************************
   * Mapping
   ***/

  /**
   * Map values for a specific key into underlying values
   */
  map(key, values) {
    this._mappedValues[key] = values
  }

  /**************************************************************************
   * Change handling
   ***/

  /**
   * Update
   */
  update(key, value, silent, forceChange = false) {

    //Object given
    if (key && typeof key === 'object') {
      forceChange = silent
      silent = value
      return this.updateMany(key, silent, forceChange)
    }

    //Ensure this key is present in defaults
    this.ensureInDefaults(key)

    //Check if value has changed
    const isChanged = (this[key] !== value)

    //Set the new value
    Vue.set(this, key, value)

    //Trigger a change if needed
    if ((isChanged || forceChange) && !silent) {
      this.triggerChange()
    }
  }

  /**
   * Update many
   */
  updateMany(values, silent, forceChange = false) {

    //Initialize changes flag
    let hasChanges = false

    //Process each key
    for (const key in values) {
      const value = values[key]

      //Ensure the key is present in defaults
      this.ensureInDefaults(key)

      //Update changes flag
      if (this[key] !== value) {
        hasChanges = true
      }

      //Set the new value
      Vue.set(this, key, value)
    }

    //Trigger change if
    if ((hasChanges || forceChange) && !silent) {
      this.triggerChange()
    }
  }

  /**
   * Trigger change handlers
   */
  triggerChange() {
    for (const handler of this._changeHandlers) {
      handler()
    }
  }

  /**
   * Add change handler
   */
  onChange(handler) {
    if (this._changeHandlers.indexOf(handler) === -1) {
      this._changeHandlers.push(handler)
    }
  }

  /**
   * Remove change handler (last one if none given)
   */
  offChange(handler) {
    if (handler) {
      const i = this._changeHandlers.indexOf(handler)
      if (i > -1) {
        this._changeHandlers.splice(i, 1)
      }
    }
    else if (this._changeHandlers.length > 0) {
      this._changeHandlers.pop()
    }
  }

  /**
   * To JSON converter
   */
  toJSON(data) {

    //Initialize json
    const json = (data && typeof data === 'object') ? data : {}

    //Process properties
    for (const key in this) {

      //Check type
      const isUndefined = (typeof this[key] === 'undefined')
      const isFunction = (typeof this[key] === 'function')
      const isObject = (typeof this[key] === 'object')
      const isOwn = Object.prototype.hasOwnProperty.call(this, key)
      const isMapped = (typeof this._mappedValues[key] !== 'undefined')

      //Ignore if invalid
      if (!isOwn || isFunction || isUndefined) {
        continue
      }

      //Ignore null values
      if (this[key] === null) {
        continue
      }

      //Mapped values
      if (isMapped) {
        const value = this[key]
        const map = (typeof this._mappedValues[key] === 'function') ?
          this._mappedValues[key](value) :
          this._mappedValues[key][value]
        for (const sub in map) {
          if (moment.isMoment(map[sub])) {
            json[sub] = map[sub].toJSON()
          }
          else {
            json[sub] = map[sub]
          }
        }
        continue
      }

      //Process
      if (isObject && typeof this[key].toJSON === 'function') {
        json[key] = this[key].toJSON()
      }
      else {
        json[key] = this[key]
      }
    }
    return json
  }
}

/**
 * Filter service
 */
class FilterService {

  /**
   * Get filter of a certain type
   */
  get(type) {
    if (!this[type]) {
      this[type] = new Filter(type)
    }
    return this[type]
  }
}

/**
 * Export singleton instance
 */
export default new FilterService()
