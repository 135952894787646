
//Global styles to be loaded first
import './styles/reset.scss'
import './styles/utility.scss'
import './styles/elements.scss'

//Vue app component
import Vue from 'vue'
import App from './components/app/app'

//Services
import router from './router'
import store from './store'
import cfg from './services/cfg'
import auth from './services/auth'
import filter from './services/filter'
import errors from './services/errors'

//3rd party plugins and components
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
})

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueTooltip from 'v-tooltip'
Vue.use(VueTooltip, {
  defaultPlacement: 'bottom',
  defaultClass: '',
  defaultTemplate: `
    <div class="Tooltip" role="tooltip">
      <div class="Tooltip-arrow"></div>
      <div class="Tooltip-body"></div>
    </div>
  `,
  defaultArrowSelector: '.Tooltip-arrow',
  defaultInnerSelector: '.Tooltip-body',
  defaultTargetClass: 'has-tooltip',
  defaultLoadingClass: 'is-loading',
  defaultLoadingContent: '<spinner/>',
})

//Plugins
import Notice from './plugins/notice/notice.plugin'
Vue.use(Notice, {})

import Modal from './plugins/modal/modal.plugin'
Vue.use(Modal, {
  dynamic: true,
  defaults: {
    clickToClose: false,
    scrollable: true,
    adaptive: true,
    height: 'auto',
    width: Infinity,
    maxWidth: 600,
  },
})

//Shared components
import ButtonBar from './components/shared/button-bar'
Vue.component('buttonBar', ButtonBar)

import Pagination from './components/shared/pagination'
Vue.component('pagination', Pagination)

import PageHeader from './components/shared/page-header/page-header'
Vue.component('pageHeader', PageHeader)

import SelectBox from './components/shared/form/select-box'
Vue.component('selectBox', SelectBox)

import CheckBox from './components/shared/form/check-box'
Vue.component('checkBox', CheckBox)

import CheckBoxes from './components/shared/form/check-boxes'
Vue.component('checkBoxes', CheckBoxes)

import RadioButtons from './components/shared/form/radio-buttons'
Vue.component('radioButtons', RadioButtons)

import DatePicker from './components/shared/form/date-picker'
Vue.component('datePicker', DatePicker)

import Toggle from '@/components/shared/form/toggle'
Vue.component('toggle', Toggle)

import FilterItem from '@/components/shared/filters/filter-item'
Vue.component('filterItem', FilterItem)

import SimpleLabel from './components/shared/simple-label'
Vue.component('simpleLabel', SimpleLabel)

import IconLabel from './components/shared/icon-label'
Vue.component('iconLabel', IconLabel)

import Card from './components/shared/card'
Vue.component('card', Card)

import Grid from './components/shared/grid'
Vue.component('grid', Grid)

import Group from './components/shared/group'
Vue.component('group', Group)

import Spinner from './components/shared/spinner'
Vue.component('spinner', Spinner)

import Sort from '@/components/shared/sort'
Vue.component('sort', Sort)

import Pill from '@/components/shared/pill'
Vue.component('pill', Pill)

import Tick from '@/components/shared/tick'
Vue.component('tick', Tick)

//Filters
import momentFilter from './filters/moment'
Vue.filter('moment', momentFilter)

import numberFilter from './filters/number'
Vue.filter('number', numberFilter)

import percentageFilter from './filters/percentage'
Vue.filter('percentage', percentageFilter)

import inflectFilter from './filters/inflect'
Vue.filter('inflect', inflectFilter)

import labelFilter from './filters/label'
Vue.filter('label', labelFilter)

import countryFilter from './filters/country'
Vue.filter('country', countryFilter)

import timezoneFilter from './filters/timezone'
Vue.filter('timezone', timezoneFilter)

import currencySymbolFilter from './filters/currency-symbol'
Vue.filter('currencySymbol', currencySymbolFilter)

import booleanIconFilter from './filters/boolean-icon'
Vue.filter('booleanIcon', booleanIconFilter)

//Service worker
import './register-service-worker'

//Override styles
import './styles/overrides.scss'

//Global Vue configuration
Vue.config.productionTip = false
Vue.config.errorHandler = function(error) {
  errors.process(error, false)
}

//Expose global registered filters
Vue.prototype.$filters = Vue.options.filters

//Instantiate app
new Vue({
  router,
  store,
  cfg,
  auth,
  filter,
  errors,
  render: h => h(App),
}).$mount('#app')
