<template>
  <div class="Page">
    <page-header
      :title="title"
      :crumbs="crumbs"
      :isLoading="isLoading"
    >
      <template v-slot:pageActions>
        <!-- <button class="Button Button--s" @click="add()">
        </button> -->
      </template>
    </page-header>

    <div v-if="club && !isLoading">
      <card-details
        :club="club"
      />
      <card-migration
        :club="club"
      />
      <div class="Cards Cards--grid Cards--clubDetails">
        <card-plan
          class="Card--clubPlan"
          :club="club"
          :plans="plans"
        />
        <card-account-owner
          class="Card--clubAccountOwner"
          :club="club"
          :admins="admins"
        />
        <card-billing
          class="Card--clubBilling"
          :club="club"
        />
        <card-comments
          class="Card--clubComments"
          :club="club"
        />
      </div>

      <card-permissions
        :club="club"
        :permissions="permissions"
      />
      <card-modules
        :club="club"
      />

      <div class="Cards Cards--grid Cards--clubExtras">
        <card-integrations
          :club="club"
          class="Card--clubIntegrations"
        />
        <card-api-keys
          :club="club"
          class="Card--clubApiKeys"
        />
      </div>

      <card-tag-orders
        :club="club"
      />

      <card>
        <h2>
          Settings
        </h2>
      </card>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ClubApi from '@/api/club.api'
import PlanApi from '@/api/plan.api'
import MemberApi from '@/api/member.api'
import PermissionApi from '@/api/permission.api'
import CardDetails from './cards/details'
import CardMigration from './cards/migration'
import CardAccountOwner from './cards/account-owner'
import CardBilling from './cards/billing'
import CardPlan from './cards/plan'
import CardComments from './cards/comments'
import CardModules from './cards/modules'
import CardPermissions from './cards/permissions'
import CardIntegrations from './cards/integrations'
import CardApiKeys from './cards/api-keys'

export default {
  components: {
    CardDetails,
    CardMigration,
    CardBilling,
    CardAccountOwner,
    CardPlan,
    CardComments,
    CardPermissions,
    CardModules,
    CardIntegrations,
    CardApiKeys,
  },
  data() {
    return {

      //Data
      club: null,
      plans: [],
      admins: [],
      permissions: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.page.isLoading,
    }),
    title() {
      if (!this.club) {
        return ''
      }
      return this.club.name
    },
    crumbs() {
      if (!this.club) {
        return []
      }
      const {id, name} = this.club
      return [
        {
          title: 'Clubs',
          route: {path: '/clubs'},
        },
        {
          title: name,
          route: {name: 'club.edit', params: {id}},
        },
      ]
    },
  },
  async created() {

    //Load club and permissions
    await Promise.all([
      this.loadClub(),
      this.loadPlans(),
      this.loadAdmins(),
      this.loadPermissions(),
    ])

    //Signal page finished loading
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Load club
     */
    async loadClub() {

      //Flag page as loading
      this.$store.dispatch('page/loading', true)

      //Get route params and find club
      const {id} = this.$route.params

      //Load club
      await ClubApi
        .findById(id)
        .then(club => this.setClub(club))
    },

    /**
     * Set loaded club
     */
    setClub(club) {

      //Club not found?
      if (!club) {
        //TODO
      }

      //Set club and page title
      this.club = club
      this.setupPage()
    },

    /**
     * Load plans
     */
    async loadPlans() {
      this.plans = await PlanApi.list()
    },

    /**
     * Load admins
     */
    async loadAdmins() {

      //Get route params and find club
      const {id} = this.$route.params

      //Load admins
      this.admins = await MemberApi.list({
        club: id,
        role: 'admin',
      })
    },

    /**
     * Load perimissions
     */
    async loadPermissions() {
      this.permissions = await PermissionApi.list()
    },
  },
}
</script>

<style scoped lang="scss">
//https://cssgrid-generator.netlify.app/
.Cards--clubDetails {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 1fr) 0.5fr;
}
.Card--clubPlan {
  grid-area: 1 / 1 / 4 / 3;
}
.Card--clubAccountOwner {
  grid-area: 1 / 3 / 2 / 5;
}
.Card--clubBilling {
  grid-area: 2 / 3 / 4 / 5;
}
.Card--clubComments {
  grid-area: 1 / 5 / 4 / 8;
}
.Cards--clubExtras {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "integrations apikeys"
}
.Card--clubIntegrations {
  grid-area: integrations;
}
.Card--clubApiKeys {
  grid-area: apikeys;
}
</style>
