import auth from '@/services/auth'
import cfg from '@/services/cfg'
import store from '@/store'

/**
 * Authenticate
 */
export async function checkAuth(to, from, next) {

  //Load token if needed
  if (!auth.token) {
    auth.loadToken()
  }

  //Route doesn't require authentication
  if (!to.matched.some(route => route.meta.auth)) {
    console.log('Route does not require authentication')
    return next()
  }

  //We have a token but it has expired, try to refresh
  //NOTE: Super user can't have refresh tokens at the moment, and this was
  //actually generating tokens for non-super users if they were logged in
  //in another club, so commenting out for now to avoid errors.
  // if (auth.hasAuthenticationExpired()) {
  //   try {
  //     console.log('Refreshing token...');
  //     await auth.refresh();
  //   }
  //   catch (error) {
  //     console.log('Refresh failed');
  //     return next(error);
  //   }
  // }

  //Authenticated
  if (auth.isAuthenticated()) {
    console.log('Authenticated')
    return next()
  }

  //Not authenticated, unload user
  console.log('Not authenticated, unloading user and heading to login page')
  store.dispatch('session/unloadUser')

  //Build login route
  const loginRoute = Object.assign({}, cfg.auth.loginRoute, {
    query: {redirect: to.path},
  })

  //Go to login route
  next(loginRoute)
}

/**
 * Load user
 */
export async function loadUser(to, from, next) {
  if (!store.state.session.user && auth.isAuthenticated()) {
    try {
      console.log('Loading user...')
      await store.dispatch('session/loadUser')
    }
    catch (error) {
      console.log('Loading user failed', error)
      next(error)
    }
  }
  next()
}
