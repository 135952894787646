import BaseModel from '../base.model'

/**
 * Address model
 */
export default class Address extends BaseModel {

  /**
   * One liner
   */
  get oneLiner() {
    return this.makeOneLiner('line1', 'suburb', 'city', 'postalCode', 'state')
  }

  /**
   * Location
   */
  get location() {
    return this.makeOneLiner('suburb', 'city', 'state')
  }

  /**
   * Make address one liner
   */
  makeOneLiner(...mask) {
    return this.makeParts(...mask).join(', ')
  }

  /**
   * Helper to make address parts
   */
  makeParts(...mask) {

    //Initialize parts
    const parts = []
    const {line1, line2, suburb, city, postalCode, state, country} = this

    //Include all parts by default
    if (!Array.isArray(mask) || mask.length === 0) {
      mask = [
        'line1', 'line2', 'suburb', 'city', 'postalCode', 'state', 'country',
      ]
    }

    //Add values
    if (line1 && mask.includes('line1')) {
      parts.push(line1)
    }
    if (line2 && mask.includes('line2')) {
      parts.push(line2)
    }
    if (suburb && suburb !== city && mask.includes('suburb')) {
      parts.push(suburb)
    }
    if (city && mask.includes('city')) {
      if (postalCode && mask.includes('postalCode')) {
        parts.push(`${city} ${postalCode}`)
      }
      else {
        parts.push(city)
      }
    }
    else if (postalCode && mask.includes('postalCode')) {
      parts.push(postalCode)
    }
    if (state && state !== city && mask.includes('state')) {
      parts.push(state)
    }
    if (country && mask.includes('country')) {
      parts.push(country)
    }

    //Return collected parts
    return parts
  }
}
