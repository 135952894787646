<template>
  <div class="Spinner">
    <div class="Spinner-circle"></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">

//Spinner variables
$spinnerBorderSize: 3px;
$spinnerSize: 20px;
$spinnerAnimationDuration: .6s;

//Helper function
@function circlePosition($size, $borderSize, $coordinate, $alt: false) {
  $center: ($size * 0.5) - $borderSize - ($borderSize * 0.5);
  $radius: ($size * 0.5) - $borderSize + ($borderSize * 0.5);
  $root: 0.70710678118; //sqrt(2)/2
  @if $coordinate == x or $alt == true {
    @return round($center + ($radius * $root));
  }
  @else {
    @return round($center - ($radius * $root));
  }
}

//Spinner
.Spinner {
  @include spinnerColor($silver);
}
.Spinner-circle {
  width: $spinnerSize;
  height: $spinnerSize;
  border: $spinnerBorderSize solid transparent;
  border-radius: 50%;
  position: relative;
  animation: loaderRotate $spinnerAnimationDuration linear infinite;
  &:after {
    content: '';
    width: $spinnerBorderSize;
    height: $spinnerBorderSize;
    border-radius: 50%;
    position: absolute;
    top: circlePosition($spinnerSize, $spinnerBorderSize, y);
    left: circlePosition($spinnerSize, $spinnerBorderSize, x);
  }
  &:before {
    content: '';
    width: $spinnerBorderSize;
    height: $spinnerBorderSize;
    border-radius: 50%;
    position: absolute;
    top: circlePosition($spinnerSize, $spinnerBorderSize, y, true);
    left: circlePosition($spinnerSize, $spinnerBorderSize, x, true);
  }
  @keyframes loaderRotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.Spinner--inline {
  display: inline-block;
  vertical-align: -2px;
  @include inlineSpaced;
}
.Spinner--s {
  transform: scale(0.75);
  &.Spinner--inline {
    vertical-align: -4px;
    &:not(:first-child) {
      margin-left: $spacing-xxs;
    }
  }
}
.Spinner--primary {
  @include spinnerColor($green);
}
</style>
