<template>
  <div class="Modal">
    <div class="ModalHeader ModalHeader--danger">
      <h1>Oops</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">

      <!-- Network error -->
      <div v-if="isNetworkError">
        <p>There seem to be some network issues. Please check your internet connection.</p>
      </div>

      <!-- Not found error -->
      <div v-else-if="isNotFoundError">
        <p>The resource you requested was not found.</p>
      </div>

      <!-- Not authorized error -->
      <div v-else-if="isNotAuthorizedError">
        <p>You don’t have access to this resource.</p>
      </div>

      <!-- Response error -->
      <div v-else-if="isResponseError">
        <p>The server made a boo boo.</p>
      </div>

      <!-- Client error -->
      <div v-else>
        <p>Something went wrong. That’s all we know.</p>
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :show-cancel="false"
        label-confirm="Close"
        type-confirm="danger"
        @confirm="$emit('close')"
      ></button-bar>
    </div>
  </div>
</template>

<script>
import {
  ResponseError,
  NetworkError,
  NotFoundError,
  NotAuthorizedError
} from '@/errors/index'

export default {
  emits: [
    'close',
  ],
  props: {
    error: {
      type: Error,
    },
  },
  computed: {
    isNetworkError() {
      return (this.error instanceof NetworkError)
    },
    isNotFoundError() {
      return (this.error instanceof NotFoundError)
    },
    isNotAuthorizedError() {
      return (this.error instanceof NotAuthorizedError)
    },
    isResponseError() {
      return (this.error instanceof ResponseError)
    },
  },
}
</script>
