<template>
  <div v-if="filter">

    <!-- Plan -->
    <filter-item
      :filter="filter"
      filter-key="plan"
      label="Plan"
      icon="bookmark"
    >
      <div class="CheckBoxGroup">
        <check-box
          v-for="plan in plans"
          :key="plan.id"
          :model="filter.plan"
          :checked-value="plan.id"
          :is-array="true"
          @change="filter.update('plan', $event.value)"
        >
          <i
            class="Icon PlanIcon"
            :class="`PlanIcon--${plan.identifier}`"
          >bookmark</i>
          {{plan.name}}
        </check-box>
      </div>
    </filter-item>

    <!-- Country -->
    <filter-item
      :filter="filter"
      filter-key="countryCode"
      label="Country"
      icon="language"
    >
      <select-box
        :model="filter.countryCode"
        :options="countries"
        @change="filter.update('countryCode', $event.value)"
      />
    </filter-item>
  </div>
</template>

<script>
import FilterService from '@/services/filter'

export default {
  props: {
    plans: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filter: FilterService.get('planHistory'),
  }),
}
</script>
