<template>
  <modal-edit
    title="Edit feedback"
    :model="model"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <group
        label="Feedback"
        :validation="$v.model.message"
      >
        <textarea
          class="Input"
          rows="5"
          v-model.trim="model.message"
        />
      </group>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import {required} from 'vuelidate/lib/validators'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {

      //Model
      model: {
        message: null,
      },
    }
  },
  props: {
    feedback: {
      type: Object,
    },
    onSave: {
      type: Function,
    },
  },
  validations: {
    model: {
      message: {
        required,
      },
    },
  },
  created() {

    //Create model subset
    this.model = this.feedback.extract(Object.keys(this.model))
  },
}
</script>
