<template>
  <context-menu>
    <a
      class="Dropdown-item"
      @click="$emit('edit')"
    >
      <i class="Icon">edit</i> Edit
    </a>
    <a
      class="Dropdown-item is-danger"
      @click="$emit('remove')"
    >
      <i class="Icon">delete_forever</i> Remove
    </a>
  </context-menu>
</template>

<script>
import ContextMenu from '@/components/shared/context-menu'

export default {
  emits: [
    'edit',
    'remove',
  ],
  components: {
    ContextMenu,
  },
  props: {
    feedback: {
      type: Object,
    },
  },
}
</script>
