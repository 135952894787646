import BaseApi from './base.api'
import System from '@/models/system.model'

/**
 * Request log API service
 */
class SystemApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/system',
      dataKey: 'systems',
      Model: System,
    })
  }

  /**
   * Update systems
   */
  updateMany(data) {
    return this
      .post(`/updateMany`, data)
  }
}

/**
 * Export singleton instance
 */
export default new SystemApi()
