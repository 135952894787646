
/**
 * Percentage filter
 */
export default function(fraction, options) {

  //Sanitize input
  if (fraction === Infinity || fraction === -Infinity || isNaN(fraction)) {
    fraction = 0
  }

  //Default config
  const defaults = {
    useGrouping: true,
    maximumFractionDigits: 0,
  }

  //Merge options and create formatter
  options = Object.assign({}, defaults, options)
  const formatter = new Intl.NumberFormat('en-NZ', options)

  //Use number filter
  return formatter.format(fraction * 100, options) + '%'
}
