<template>
  <modal-edit
    title="Edit plan details"
    :model="model"
    :params="params"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <grid cols="2" row-spacing="m">
        <group
          label="Plan"
          :validation="$v.model.planId"
        >
          <select-box
            :options="plans"
            :model="model.planId"
            track-by="id"
            label-by="name"
            @change="$set(model, 'planId', $event.value)"
          />
        </group>
      </grid>
      <grid cols="2" row-spacing="m">
        <group
          label="Trial end date"
          :validation="$v.model.trialEndDate"
        >
          <date-picker
            :model="model.trialEndDate"
            :min-date="today"
            :can-clear="true"
            @change="$set(model, 'trialEndDate', $event.date)"
          />
        </group>
        <group
          label="Plan expiration date"
          :validation="$v.model.planExpirationDate"
        >
          <date-picker
            :model="model.planExpirationDate"
            :can-clear="true"
            @change="$set(model, 'planExpirationDate', $event.date)"
          />
        </group>
        <group
          label="Billing interval"
          :validation="$v.model.billingInterval"
        >
          <select-box
            :options="StripeBillingIntervals"
            :model="model.billingInterval"
            @change="$set(model, 'billingInterval', $event.value)"
          />
        </group>
        <group
          label="Payment method"
          :validation="$v.model.collectionMethod"
          :class="{'Group--warning': isInvalid}"
        >
          <select-box
            :options="StripeCollectionMethods"
            :model="model.collectionMethod"
            @change="$set(model, 'collectionMethod', $event.value)"
          />
          <div class="InputHint InputHint--warning" v-if="isInvalid">
            <p>Customer has no saved cards yet</p>
          </div>
        </group>
      </grid>
      <group
        label="Options"
      >
        <toggle
          :model="params.pushToStripe"
          :label="pushToStripeLabel"
          @change="$set(params, 'pushToStripe', $event.value)"
        />
        <toggle
          :model="params.anchorTo1st"
          v-if="params.pushToStripe && !club.stripe.subscriptionId"
          label="Anchor to 1st of the month"
          @change="$set(params, 'anchorTo1st', $event.value)"
        />
        <toggle
          :model="params.prorate"
          v-if="params.pushToStripe && params.anchorTo1st"
          label="Pro-rate and start billing now"
          @change="$set(params, 'prorate', $event.value)"
        />
      </group>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import StripeBillingIntervals from '@/constants/stripe-billing-intervals'
import StripeCollectionMethods from '@/constants/stripe-collection-methods'
import {MONTH} from '@/constants/stripe-billing-intervals'
import {SEND_INVOICE} from '@/constants/stripe-collection-methods'
import {required} from 'vuelidate/lib/validators'
import moment from '@/services/moment'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {

      //Model
      model: {
        planId: null,
        billingInterval: null,
        collectionMethod: null,
        trialEndDate: null,
        planExpirationDate: null,
      },

      //Params
      params: {
        pushToStripe: null,
        anchorTo1st: null,
        prorate: null,
      },

      //Today
      today: moment().startOf('day'),

      //Constants
      StripeBillingIntervals,
      StripeCollectionMethods,
    }
  },
  props: {
    club: {
      type: Object,
    },
    admins: {
      type: Array,
      default: () => [],
    },
    plans: {
      type: Array,
      default: () => [],
    },
    onSave: {
      type: Function,
    },
  },
  computed: {
    pushToStripeLabel() {
      return this.club.stripe.subscriptionId ?
        'Update subscription in Stripe' :
        'Create subscription in Stripe'
    },
    //TEMP: Proper validation/alert error
    isInvalid() {
      const {club, model, params} = this

      //This will error and sub won't get created because customer won't have
      //a default source
      if (
        params.pushToStripe &&
        model.collectionMethod !== SEND_INVOICE &&
        club.stripe.sources.length === 0
      ) {
        return true
      }
      return false
    },
  },
  validations: {
    model: {
      planId: {required},
      billingInterval: {required},
      collectionMethod: {required},
    },
  },
  created() {

    //Get data
    const {club} = this

    //Create model subset
    this.model = {
      planId: club.extract('plan'),
      trialEndDate: club.extract('trialEndDate'),
      planExpirationDate: club.extract('planExpirationDate'),
      billingInterval: club.stripe.billingInterval || MONTH,
      collectionMethod: club.stripe.collectionMethod || SEND_INVOICE,
    }

    //Create params
    this.params = {
      pushToStripe: (club.stripe.sources.length > 0),
      anchorTo1st: false,
      prorate: true,
    }
  },
}
</script>
