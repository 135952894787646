<template>

  <div>
    <div
      :class="$style.wrapper"
    >
      <div :class="$style.iconWrapper">
        <i
          class="Icon"
          :class="{
            [$style.icon]: true,
            [$style[`iconEnabled`]]: apiKey.isEnabled,
            [$style[`iconDisabled`]]: !apiKey.isEnabled
          }"
        >
          vpn_key
        </i>
      </div>
      <div>
        <strong>{{apiKey.name}}</strong>
        <small class="text-muted" :class="$style.small">
          <br>{{apiKey.token}}
          <br>
          <span v-if="apiKey.lastUsed">
            Last used {{apiKey.lastUsed | moment('D MMM YYYY, HH:mm')}}
          </span>
          <span v-else>Never used</span>
        </small>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    apiKey: {
      type: Object,
    },
  },
}
</script>

<style module lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
}
.iconWrapper {
  flex: 0 0 3rem;
  display: flex;
  align-items: center;
}
.iconEnabled {
  color: $green;
}
.iconDisabled {
  color: $red;
}
.small {
  line-height: $line-height;
}
</style>
