<template>
  <div class="Page">
    <page-header
      :title="title"
      :crumbs="crumbs"
    />
    <div class="Dashboard">
      <div class="Metrics">
        <div class="MetricsRow">
          <panel
            class="Metric"
            label="Total MRR"
            :value="totalMRR"
            is-async
            is-currency
            is-prominent
          >
            <small class="text-muted">
              {{avgTotalMRR | currency({fractionCount: 0})}} / paying club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Software MRR"
            :value="softwareMRR"
            is-async
            is-currency
            is-prominent
          >
            <small class="text-muted">
              {{avgSoftwareMRR | currency({fractionCount: 0})}} / paying club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Payments MRR"
            :value="paymentsMRR"
            is-async
            is-currency
            is-prominent
          >
            <small class="text-muted">
              {{avgPaymentsMRR | currency({fractionCount: 0})}} / paying club
            </small>
          </panel>
        </div>

        <div class="MetricsRow">
          <panel
            class="Metric"
            label="Total ARR"
            :value="totalARR"
            is-async
            is-currency
          >
            <small class="text-muted">
              {{avgTotalARR | currency({fractionCount: 0})}} / paying club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Software ARR"
            :value="softwareARR"
            is-async
            is-currency
          >
            <small class="text-muted">
              {{avgSoftwareARR | currency({fractionCount: 0})}} / paying club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Payments ARR"
            :value="paymentsARR"
            is-async
            is-currency
          >
            <small class="text-muted">
              {{avgPaymentsARR | currency({fractionCount: 0})}} / paying club
            </small>
          </panel>
        </div>
        <div class="MetricsRow">
          <panel
            class="Metric"
            label="Clubs"
            :value="numLiveAccounts"
            is-async
            is-number
            is-prominent
          ></panel>
          <panel
            class="Metric"
            label="Paying clubs"
            :value="numPaidAccounts"
            is-async
            is-number
            is-prominent
          ></panel>
          <panel
            class="Metric"
            label="Free accounts"
            :value="numFreeAccounts"
            is-async
            is-number
            is-prominent
          ></panel>
          <panel
            class="Metric"
            label="Trial accounts"
            :value="numTrialAccounts"
            is-async
            is-number
            is-prominent
          ></panel>
        </div>

        <div class="MetricsRow">
          <panel
            class="Metric"
            label="Total members"
            :value="numTotalMembers"
            is-async
            is-number
          >
            <small class="text-muted">
              {{avgTotalMembers | number}} / club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Active members"
            :value="numActiveMembers"
            is-async
            is-number
          >
            <small class="text-muted">
              {{avgActiveMembers | number}} / club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Archived members"
            :value="numArchivedMembers"
            is-async
            is-number
          >
            <small class="text-muted">
              {{avgArchivedMembers | number}} / club
            </small>
          </panel>
        </div>

        <div class="MetricsRow">
          <panel
            class="Metric"
            label="Payments processed"
            :value="totalPaymentsProcessedText"
            is-async
            is-prominent
          >
            <small class="text-muted">
              {{avgPaymentsProcessed | currency({fractionCount: 0})}} / club
              <br>
              {{avgPaymentAmount | currency({fractionCount: 0})}} / payment
            </small>
          </panel>
          <panel
            class="Metric"
            label="Number of payments"
            :value="numOnlinePayments"
            is-async
            is-number
          >
            <small class="text-muted">
              {{avgOnlinePayments | number}} / club
            </small>
          </panel>
          <panel
            class="Metric"
            label="Fees collected"
            :value="totalPlatformFees"
            is-async
            is-currency
            is-prominent
          >
            <small class="text-muted">
              {{avgPlatformFees | currency({fractionCount: 0})}} / club
              <br>
              {{avgPaymentFee | currency({fractionCount: 2})}} / payment
            </small>
          </panel>
          <panel
            class="Metric"
            label="Fee percentage"
            :value="platformFeePercentage"
            is-async
            :is-percentage="true"
          ></panel>
        </div>

        <div class="MetricsRow">
          <panel
            class="Metric"
            label="360 day lead time"
            :value="leadTime360Days"
            is-async
            is-number
            suffix="days"
          ></panel>
          <panel
            class="Metric"
            label="180 day lead time"
            :value="leadTime180Days"
            is-async
            is-number
            suffix="days"
          ></panel>
          <panel
            class="Metric"
            label="60 day lead time"
            :value="leadTime60Days"
            is-async
            is-number
            suffix="days"
          ></panel>
          <panel
            class="Metric"
            label="30 day lead time"
            :value="leadTime30Days"
            is-async
            is-number
            suffix="days"
          ></panel>
        </div>
      </div>

      <panel
        class="Links"
      >
        <service-link
          icon="github.png"
          name="Github"
          link="https://github.com/helloclub"
          description="Source code repository"
        ></service-link>
        <service-link
          icon="productboard.svg"
          name="Productboard"
          link="https://helloclub.productboard.com/feature-board/1209639-feature-organization"
          description="Insights processing, product backlog"
        ></service-link>
        <service-link
          icon="intercom.svg"
          name="Intercom"
          link="https://app.intercom.io/a/apps/gudevjri/inbox"
          description="Customer support, automated messages"
        ></service-link>
        <service-link
          icon="heroku.svg"
          name="Heroku"
          link="https://dashboard.heroku.com/pipelines/4497a33c-5ecd-466e-a8ec-4ab699aaa54e"
          description="Server platform"
        ></service-link>
        <service-link
          icon="netlify.svg"
          name="Netlify"
          link="https://app.netlify.com/"
          description="Client platform, custom domains"
        ></service-link>
        <service-link
          icon="mongodb.svg"
          name="MongoDB"
          link="https://cloud.mongodb.com/"
          description="Database and data backups"
        ></service-link>
        <service-link
          icon="sentry.svg"
          name="Sentry"
          link="https://sentry.io/helloclub/"
          description="Error tracking"
        ></service-link>
        <service-link
          icon="logdna.svg"
          name="Mezmo"
          link="https://app.logdna.com/24dc7b74c7/logs/view/e02473eb70"
          description="Error and request logs"
        ></service-link>
        <service-link
          icon="imgix.svg"
          name="Imgix"
          link="https://dashboard.imgix.com"
          description="Image optimisation"
        ></service-link>
        <service-link
          icon="dataplicity.png"
          name="Dataplicity"
          link="https://www.dataplicity.com/app/"
          description="Remote hardware management"
        ></service-link>
        <service-link
          icon="cloudflare.svg"
          name="Cloudflare"
          link="https://www.cloudflare.com/a/dns/helloclub.com"
          description="DNS and firewall"
        ></service-link>
        <service-link
          icon="aws.svg"
          name="AWS"
          link="https://console.aws.amazon.com/s3/home?region=ap-southeast-2"
          description="File storage"
        ></service-link>
        <service-link
          icon="google-cloud.svg"
          name="Google Cloud"
          link="https://console.cloud.google.com/apis/credentials?project=helloclub-live"
          description="API credentials, OAuth keys"
        ></service-link>
        <service-link
          icon="stripe.svg"
          name="Stripe"
          link="https://dashboard.stripe.com/"
          description="Online payments, subscriptions, invoices"
        ></service-link>
        <service-link
          icon="hubspot.svg"
          name="Hubspot"
          link="https://app.hubspot.com/contacts/5108706/contacts/list/view/all/?"
          description="Leads database"
        ></service-link>
        <service-link
          icon="mailchimp.png"
          name="Mailchimp"
          link="https://us15.admin.mailchimp.com/campaigns/"
          description="Newsletter campaigns"
        ></service-link>
        <service-link
          icon="xero.svg"
          name="Xero"
          link="https://go.xero.com/Dashboard/"
          description="Accounting"
        ></service-link>
      </panel>
    </div>
  </div>
</template>

<script>
import moment from '@/services/moment'
import ClubApi from '@/api/club.api'
import OnlinePaymentsHistoryApi from '@/api/online-payments-history.api'
import Panel from './panel'
import ServiceLink from './service-link'

export default {
  components: {
    Panel,
    ServiceLink,
  },
  data() {
    return {

      //Page data
      title: 'Dashboard',
      crumbs: [
        {
          title: 'Dashboard',
          route: {name: 'dashboard'},
        },
      ],

      //Club counts
      numLiveAccounts: undefined,
      numFreeAccounts: undefined,
      numPaidAccounts: undefined,
      numTrialAccounts: undefined,

      //Member counts
      numTotalMembers: undefined,
      numActiveMembers: undefined,
      numArchivedMembers: undefined,
      avgTotalMembers: undefined,
      avgActiveMembers: undefined,
      avgArchivedMembers: undefined,

      //Online payments
      numOnlinePayments: undefined,
      totalPaymentsProcessed: undefined,
      totalPaymentsProcessedText: undefined,
      totalPlatformFees: undefined,
      totalRebateFees: undefined,
      platformFeePercentage: undefined,
      avgOnlinePayments: undefined,
      avgPaymentsProcessed: undefined,
      avgPlatformFees: undefined,
      avgRebateFees: undefined,
      avgPaymentAmount: undefined,
      avgPaymentFee: undefined,

      //Financials
      totalMRR: undefined,
      totalARR: undefined,
      softwareMRR: undefined,
      softwareARR: undefined,
      paymentsMRR: undefined,
      paymentsARR: undefined,
      avgTotalMRR: undefined,
      avgTotalARR: undefined,
      avgSoftwareMRR: undefined,
      avgSoftwareARR: undefined,
      avgPaymentsMRR: undefined,
      avgPaymentsARR: undefined,

      //Other
      leadTime360Days: undefined,
      leadTime180Days: undefined,
      leadTime60Days: undefined,
      leadTime30Days: undefined,
    }
  },
  created() {

    //Setup page
    this.setupPage()

    //Load data
    this.loadData()
  },
  methods: {

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
      this.$store.dispatch('page/loading', false)
    },

    /**
     * Load data
     */
    async loadData() {

      //Load all async data at once
      await Promise.all([

        //Load club counts
        this.loadNumberOfLiveAccounts(),
        this.loadNumberOfFreeAccounts(),
        this.loadNumberOfPaidAccounts(),
        this.loadNumberOfTrialAccounts(),

        //Load member counts
        this.loadNumberOfActiveMembers(),
        this.loadNumberOfArchivedMembers(),

        //Load online payments history
        this.loadOnlinePaymentsHistory(),

        //Load MRR and average lead time
        this.loadSoftwareMRR(),
        this.loadPaymentsMRR(),
        this.loadLeadTimes(),
      ])

      //Post process
      this.determineTotals()
      this.determineAverages()
    },

    /**
     * Loader for number of live accounts
     */
    async loadNumberOfLiveAccounts() {
      this.numLiveAccounts = await ClubApi
        .count({isLive: true})
    },

    /**
     * Loader for number of free accounts
     */
    async loadNumberOfFreeAccounts() {
      this.numFreeAccounts = await ClubApi
        .count({isLive: true, isFree: true})
    },

    /**
     * Loader for number of paid accounts
     */
    async loadNumberOfPaidAccounts() {
      this.numPaidAccounts = await ClubApi
        .count({isLive: true, isFree: false})
    },

    /**
     * Loader for number of trial accounts
     */
    async loadNumberOfTrialAccounts() {
      this.numTrialAccounts = await ClubApi
        .count({isTrial: true})
    },

    /**
     * Loader for number of active members
     */
    async loadNumberOfActiveMembers() {
      this.numActiveMembers = await ClubApi.aggregate('numActiveMembers')
    },

    /**
     * Loader for number of archived members
     */
    async loadNumberOfArchivedMembers() {
      this.numArchivedMembers = await ClubApi.aggregate('numArchivedMembers')
    },

    /**
     * Loader for online payments data
     */
    async loadOnlinePaymentsHistory() {

      //Get dates
      const fromDate = moment('01-12-2016', 'DD-MM-YYYY').startOf('day')
      const toDate = moment().endOf('day')

      //Load online payments history data
      const data = await OnlinePaymentsHistoryApi
        .aggregate({fromDate, toDate})

      //Extract totals
      const {
        sums: {
          numOnlinePayments,
          totalPaymentsProcessed,
          totalPlatformFees,
          totalRebateFees,
        },
      } = data

      //Platform fee percentage
      const platformFeePercentage = (totalPlatformFees / totalPaymentsProcessed)
      const totalPaymentsProcessedText = '$' + Math.round(
        totalPaymentsProcessed / 100000
      ) / 10 + 'M'

      //Assign values
      Object.assign(this, {
        numOnlinePayments,
        totalPaymentsProcessed,
        totalPaymentsProcessedText,
        totalPlatformFees,
        totalRebateFees,
        platformFeePercentage,
      })
    },

    /**
     * Load lead times
     */
    async loadLeadTimes() {

      //360 day lead time
      this.leadTime360Days = await ClubApi.aggregate('leadTime', 'avg', {
        excludeFree: true,
        maxDaysOnPlan: 360,
      })

      //180 day lead time
      this.leadTime180Days = await ClubApi.aggregate('leadTime', 'avg', {
        excludeFree: true,
        maxDaysOnPlan: 180,
      })

      //60 day lead time
      this.leadTime60Days = await ClubApi.aggregate('leadTime', 'avg', {
        maxDaysOnPlan: 60,
        excludeFree: true,
      })

      //30 day lead time
      this.leadTime30Days = await ClubApi.aggregate('leadTime', 'avg', {
        maxDaysOnPlan: 30,
        excludeFree: true,
      })
    },

    /**
     * Load software MRR
     */
    async loadSoftwareMRR() {

      //Get MRR
      const softwareMRR = await ClubApi.aggregate('monthlyRevenue')

      //Set formatted values
      this.softwareMRR = softwareMRR
      this.softwareARR = 12 * softwareMRR
    },

    /**
     * Load payments MRR
     */
    async loadPaymentsMRR() {

      //Get dates
      const toDate = moment().subtract(1, 'month').endOf('month')
      const fromDate = moment().subtract(6, 'months').startOf('month')

      //Load online payments history data
      const data = await OnlinePaymentsHistoryApi
        .aggregate({fromDate, toDate})

      //Extract totals
      const {
        sums: {
          totalPlatformFees,
          totalRebateFees,
        },
      } = data

      //Determine MRR (average of past 6 months of fees collected)
      const paymentsMRR = (totalPlatformFees + totalRebateFees) / 6

      //Set formatted values
      this.paymentsMRR = paymentsMRR
      this.paymentsARR = 12 * paymentsMRR
    },

    /**
     * Determine totals
     */
    determineTotals() {

      //Get data
      const {
        softwareMRR, paymentsMRR, softwareARR, paymentsARR,
        numActiveMembers, numArchivedMembers,
      } = this

      //Determine totals
      this.totalMRR = softwareMRR + paymentsMRR
      this.totalARR = softwareARR + paymentsARR
      this.numTotalMembers = numActiveMembers + numArchivedMembers
    },

    /**
     * Determine averages
     */
    determineAverages() {

      //Get data
      const {
        totalMRR, totalARR, softwareMRR, softwareARR, paymentsMRR, paymentsARR,
        numPaidAccounts, numLiveAccounts,
        numTotalMembers, numActiveMembers, numArchivedMembers, numOnlinePayments,
        totalPaymentsProcessed, totalPlatformFees, totalRebateFees,
      } = this

      //Determine averages
      this.avgTotalMRR = totalMRR / numPaidAccounts
      this.avgTotalARR = totalARR / numPaidAccounts
      this.avgSoftwareMRR = softwareMRR / numPaidAccounts
      this.avgSoftwareARR = softwareARR / numPaidAccounts
      this.avgPaymentsMRR = paymentsMRR / numPaidAccounts
      this.avgPaymentsARR = paymentsARR / numPaidAccounts
      this.avgTotalMembers = numTotalMembers / numLiveAccounts
      this.avgActiveMembers = numActiveMembers / numLiveAccounts
      this.avgArchivedMembers = numArchivedMembers / numLiveAccounts
      this.avgOnlinePayments = numOnlinePayments / numLiveAccounts
      this.avgPaymentsProcessed = totalPaymentsProcessed / numLiveAccounts
      this.avgPlatformFees = totalPlatformFees / numLiveAccounts
      this.avgRebateFees = totalRebateFees / numLiveAccounts
      this.avgPaymentAmount = totalPaymentsProcessed / numOnlinePayments
      this.avgPaymentFee = totalPlatformFees / numOnlinePayments
    },
  },
}
</script>

<style scoped lang="scss">
.Dashboard {
  display: flex;
}
.Metrics {
  flex: 1;
}
.MetricsRow {
  display: flex;
  margin-bottom: $spacing-m;
}
.Metric {
  flex: 1;
  &:not(:first-child) {
    margin-left: $spacing-m;
  }
}
.Links {
  margin-left: $spacing-m;
  flex: 0 0 20rem;
  grid-row-start: 1;
  grid-row-end: 8;
  grid-column-start: 5;
  grid-column-end: 7;
}
</style>
