<template>
  <div class="Pill">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.Pill {
  font-size: $font-s;
  display: inline-block;
  color: $white;
  background: $oslo;
  padding: $spacing-xxs $spacing-s;
  border-radius: $spacing-m;
}
.Pill--success {
  background: $green;
}
.Pill--danger {
  background: $red;
}
.Pill--warning {
  background: $yellow;
}
</style>
