<template>
  <modal-confirm
    type="danger"
    :title="`Cancel account`"
    :on-confirm="onConfirm"
    :challenge="club.isTrial ? null : club.identifier"
    challenge-hint="the club identifier"
    @close="$emit('close')"
  >
    <p>
      Are you sure you want to cancel the account for <strong>{{club.name}}</strong>?
      <span v-if="club.stripe && club.stripe.subscriptionId"><br>This will also cancel their Stripe subscription at the end of the current period.</span>
    </p>
  </modal-confirm>
</template>

<script>
import ModalConfirm from '@/components/shared/modals/modal-confirm'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalConfirm,
  },
  props: {
    club: {
      type: Object,
    },
    onConfirm: {
      type: Function,
    },
  },
}
</script>
