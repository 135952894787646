import BaseApi from './base.api'
import Country from '@/models/country.model'

/**
 * Country API service
 */
class CountryApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/country',
      dataKey: 'countries',
      Model: Country,
    })
  }
}

/**
 * Export singleton instance
 */
export default new CountryApi()
