<template>
  <div
    :class="$style.wrapper"
  >
    <div :class="$style.iconWrapper">
      <img
        :class="$style.icon"
        :src="icon"
      >
    </div>
    <div>
      <strong>{{integration.name}}</strong>
      <small class="text-muted" :class="$style.small">
        <br>Connected as {{integration.connectedAs || 'unknown'}}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    integration: {
      type: Object,
    },
  },
  computed: {
    icon() {
      const {type} = this.integration
      return `/images/integrations/${type}.png`
    },
  },
}
</script>

<style module lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
}
.iconWrapper {
  flex: 0 0 75px;
  margin-right: $spacing-l;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  max-width: 75px;
  max-height: 36px;
}
.small {
  line-height: $line-height;
}
</style>
