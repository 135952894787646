import NoticesContainer from './notices-container'
import Notice from './notice'
import notice from '@/services/notice'

/**
 * Notice plugin
 */
const Plugin = {

  /**
   * Install plugin
   */
  install(Vue, options) {

    //Merge options
    options = Object.assign({
      componentName: 'notice',
      containerComponentName: 'noticesContainer',
    }, options || {})

    //Get data
    const {componentName, containerComponentName} = options

    //Set instance service
    Vue.prototype.$notice = notice

    //Register components
    Vue.component(containerComponentName, NoticesContainer)
    Vue.component(componentName, Notice)
  },
}

/**
 * Export plugin
 */
export default Plugin
