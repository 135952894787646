<template>
  <div class="PageFilter">
    <i
      class="Icon Icon--faint PageAction-icon clickable"
      :class="{
        'PageAction-icon--inUse': !filter.hasDefaults,
        'PageAction-icon--active': isShowingFilters,
      }"
      ref="trigger"
      @click="toggleFilters()"
    >filter_list</i>
    <div ref="filters">
      <div class="Dropdown Filters" v-if="isShowingFilters">
        <div class="Dropdown-body">
          <slot />
        </div>
        <div class="Dropdown-footer FiltersFooter" @click="clearFilters()">
          <i class="Icon Icon--spaced">close</i> Clear all filters
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {clickHandler} from '@/helpers/ux'

export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowingFilters: false,
    }
  },
  mounted() {

    //Get trigger and handler
    const {trigger, filters} = this.$refs
    const handler = this.hideFilters.bind(this)

    //Create click handler
    this.removeHandler = clickHandler(handler, [trigger, filters])
  },
  unmounted() {
    this.removeHandler()
  },
  methods: {
    toggleFilters() {
      this.isShowingFilters = !this.isShowingFilters
    },
    hideFilters() {
      this.isShowingFilters = false
    },
    clearFilters() {
      this.filter.resetToDefaults()
      this.hideFilters()
    },
  },
}
</script>

<style lang="scss">
.Filters {
  min-width: 200px;
  .Input {
    font-size: $font-s;
  }
}
.FiltersFooter {
  cursor: pointer;
  &:hover {
    color: $black;
  }
}
</style>
