<template>
  <div class="SideBar">
    <img src="@/assets/logo-white.svg" class="SideBarLogo">
    <menu class="SideBarMenu SideBarMenu--main">
      <side-bar-menu-item
        icon="speed"
        route="/dashboard"
        label="Dashboard"
      />
      <side-bar-menu-item
        icon="apartment"
        route="/clubs"
        label="Clubs"
      />
      <side-bar-menu-item
        icon="trending_up"
        route="/plan-history"
        label="Plan history"
      />
      <side-bar-menu-item
        icon="credit_card"
        route="/online-payments-history"
        label="Online payments"
      />
      <side-bar-menu-item
        icon="dvr"
        route="/systems"
        label="Systems"
      />
      <side-bar-menu-item
        icon="shopping_cart"
        route="/orders"
        label="Tag orders"
      />
      <side-bar-menu-item
        icon="feedback"
        route="/feedback"
        label="Feedback"
      />
      <side-bar-menu-item
        icon="http"
        route="/request-logs"
        label="Request logs"
      />
    </menu>
    <menu class="SideBarMenu">
      <li class="SideBarMenuItem">
        <a class="SideBarLink" @click="logout()">
          <i class="Icon" v-tooltip.right="'Logout'">exit_to_app</i>
        </a>
      </li>
    </menu>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SideBarMenuItem from './side-bar-menu-item'

export default {
  components: {
    SideBarMenuItem,
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },
  methods: {
    logout() {
      this.$auth.logout()
    },
  },
}
</script>

<style lang="scss">
.SideBar {
  background: $green;
  height: 100%;
  width: 4rem;
  display: flex;
  z-index: 1000;
  flex-direction: column;
}
.SideBarLogo {
  width: 2rem;
  display: block;
  margin: $spacing-m auto $spacing-xl;
}
.SideBarMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SideBarMenu--main {
  flex: 1;
}
</style>
