
/**
 * Base configuration
 */
export default {

  //App
  app: {
    title: 'Admin Portal',
  },

  //Storage
  storage: {
    prefix: 'hc.admin',
  },

  //Auth
  auth: {
    clientId: 'hc.admin',
    homeRoute: {name: 'dashboard'},
    loginRoute: {name: 'login'},
  },

  //Sentry
  sentry: {
    dsn: '',
    tags: {
      source: 'admin',
    },
    ignoreErrors: [
      `Avoided redundant navigation to current location`,
    ],
  },

  //Error handling
  errors: {
    log: true,
  },
}
