<template>
  <card
    title="API keys"
  >
    <div :class="$style.gridWrapper">
      <grid :rows="apiKeys.length" row-spacing="s">
        <api-key
          v-for="apiKey in apiKeys"
          :key="apiKey.id"
          :api-key="apiKey"
        />
      </grid>
    </div>
  </card>
</template>

<script>
import ApiKeyApi from '@/api/api-key.api'
import ApiKey from '../api-key'

export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ApiKey,
  },
  data() {
    return {
      isSaving: false,
      apiKeys: [],
    }
  },
  async created() {

    //Load api keys
    await this.loadApiKeys()
  },
  methods: {

    /**
     * Load api keys
     */
    async loadApiKeys() {

      //Get data
      const {club} = this
      const fields = [
        'name', 'token', 'isEnabled', 'lastUsed',
      ].join(',')

      //Load api keys
      await ApiKeyApi
        .query({club: club.id, fields})
        .then(data => this.apiKeys = data.apiKeys)
    },
  },
}
</script>

<style module lang="scss">
.gridWrapper {
  margin-top: $spacing-m;
}
</style>
