import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

/**
 * State object
 */
const state = {
}

/**
 * Export as Vuex module
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
