<template>
  <div class="SimpleLabel">
    <label class="SimpleLabel-label">{{label}}</label>
    <div class="SimpleLabel-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
}
</script>

<style lang="scss">
.SimpleLabel {
  display: flex;
  align-items: center;
}
.SimpleLabel-label {
  color: $oslo;
  text-transform: lowercase;
  font-size: $font-s;
  min-width: 9rem;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: $spacing-m;
}
.SimpleLabel-content {
  flex: 2;
  line-height: $line-height;
}
</style>
