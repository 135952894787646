<template>
  <div class="ContextMenu">
    <spinner class="Spinner--primary" v-if="isBusy" />
    <div class="ContextMenu-trigger" :class="{'is-open': isVisible}" @click="toggle()" v-if="!isBusy" ref="trigger">
      <i class="Icon">more_vert</i>
    </div>
    <div class="Dropdown ContextMenu-content" v-if="isVisible">
      <div class="Dropdown-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {clickHandler} from '@/helpers/ux'

export default {
  data() {
    return {
      isBusy: false,
      isVisible: false,
    }
  },
  mounted() {

    //Get trigger and handler
    const {trigger} = this.$refs
    const handler = this.hide.bind(this)

    //Create click handler
    this.removeHandler = clickHandler(handler, trigger)
  },
  unmounted() {
    this.removeHandler()
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible
    },
    hide() {
      this.isVisible = false
    },
  },
}
</script>

<style lang="scss">
.ContextMenu {
  position: relative;
}
.ContextMenu-trigger {
  cursor: pointer;
  color: $oslo;
  &.is-open, &:hover {
    color: $slate;
  }
}
.ContextMenu-content {
  left: auto;
  right: $spacing-m;
  min-width: 7rem;
  margin-bottom: 0;
  margin-top: -$spacing-xs;
  .Icon {
    margin-right: $spacing-xs;
    color: $silver;
  }
}
</style>
