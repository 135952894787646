
/**
 * Boolean icon filter
 */
export default function(str, trueIcon = 'check', falseIcon = 'close') {
  if (str === true || str === 'true') {
    return `<i class="Icon Icon--success">${trueIcon}</i>`
  }
  if (str === false || str === 'false') {
    return `<i class="Icon Icon--danger">${falseIcon}</i>`
  }
  return str
}
