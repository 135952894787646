
/**
 * Mutations for the Session state
 */
export default {

  /**
   * Set logged in user
   */
  setUser(state, user) {
    state.user = user
  },

  /**
   * Set loaded state
   */
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  },
}
