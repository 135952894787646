import BaseApi from './base.api'
import ApiKey from '@/models/api-key.model'

/**
 * API Key API service
 */
class ApiKeyApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/apiKey',
      dataKey: 'apiKeys',
      Model: ApiKey,
    })
  }
}

/**
 * Export singleton instance
 */
export default new ApiKeyApi()
