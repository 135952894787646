<template>
  <modal-edit
    title="Edit club details"
    :model="model"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <grid cols="2" row-spacing="m">
        <group
          label="Identifier"
          :validation="$v.model.identifier"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.identifier"
          >
        </group>
        <group
          label="Type"
          :validation="$v.model.type"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.type"
          >
        </group>
        <group
          label="Name"
          :validation="$v.model.name"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.name"
          >
        </group>
        <group
          label="Short name"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.shortName"
          >
        </group>
        <group
          label="Subdomain"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.subdomain"
          >
        </group>
        <group
          label="Custom domain"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.customDomain"
          >
        </group>
        <group
          label="Country"
        >
          <input
            class="Input"
            type="text"
            :value="country.name"
            readonly
          >
        </group>
        <group
          label="Timezone"
          :validation="$v.model.timezone"
        >
          <select-box
            :options="country.timezones"
            :model="model.timezone"
            @change="$set(model, 'timezone', $event.value)"
          />
        </group>
      </grid>
      <grid cols="2" row-spacing="m">
        <group
          label="Last check in"
          :validation="$v.model.checkInDate"
        >
          <date-picker
            :model="model.checkInDate"
            :can-clear="true"
            @change="$set(model, 'checkInDate', $event.date)"
          />
        </group>
        <group
          label="Source"
          :validation="$v.model.source"
        >
          <input
            class="Input"
            type="text"
            v-model.trim="model.source"
          >
        </group>
      </grid>
      <group
        label="Cancellation reason"
        v-if="club.isCancelled"
        :validation="$v.model.cancelReason"
      >
        <input
          class="Input"
          type="text"
          v-model.trim="model.cancelReason"
        >
      </group>
      <group
        label="Options"
      >
        <toggle
          :model="model.isPartnerAccount"
          label="Partner account"
          @change="$set(model, 'isPartnerAccount', $event.value)"
        />
        <toggle
          :model="model.askForFeedback"
          label="Pro-actively ask members for feedback"
          @change="$set(model, 'askForFeedback', $event.value)"
        />
      </group>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import countries from '@/services/countries'
import {required} from 'vuelidate/lib/validators'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {

      //Model
      model: {
        identifier: null,
        type: null,
        name: null,
        shortName: null,
        subdomain: null,
        customDomain: null,
        timezone: null,
        isPartnerAccount: null,
        askForFeedback: null,
        source: null,
        cancelReason: null,
        checkInDate: null,
      },
    }
  },
  props: {
    club: {
      type: Object,
    },
    onSave: {
      type: Function,
    },
  },
  computed: {
    country() {
      return countries.findByCode(this.club.countryCode)
    },
  },
  validations: {
    model: {
      identifier: {
        required,
      },
      type: {
        required,
      },
      name: {
        required,
      },
      timezone: {
        required,
      },
    },
  },
  created() {

    //Create model subset
    this.model = this.club.extract(Object.keys(this.model))
  },
}
</script>
