import BaseModel from './base.model'
import {FLAG, NUMBER} from '@/constants/permission-types'

/**
 * Permission model
 */
export default class Permission extends BaseModel {

  /**
   * Check if this is a flag
   */
  get isFlag() {
    return (this.type === FLAG)
  }

  /**
   * Check if this is a number
   */
  get isNumber() {
    return (this.type === NUMBER)
  }

  /**
   * Check if a custom value exists for this permission for a given club
   */
  isCustom(club) {

    //No club
    if (!club) {
      return false
    }

    //Get data
    const {identifier} = this
    const {customPermissions} = club

    //No prop
    if (!customPermissions) {
      return false
    }

    //Check if custom
    return (typeof customPermissions[identifier] !== 'undefined')
  }

  /**
   * Get value of a permission for a given club
   */
  getValue(club) {

    //Get data
    const {identifier, defaultValue, values} = this

    //No club
    if (!club) {
      return defaultValue
    }

    //Determine plan ID
    const {plan, customPermissions: custom} = club
    const planId = (typeof plan === 'object') ? plan.id : plan

    //Got a custom value
    if (custom && typeof custom[identifier] !== 'undefined') {
      return custom[identifier]
    }

    //Find a matching value
    const item = values.find(item => item.plans.some(p => p === planId))

    //Found?
    if (item) {
      return item.value
    }

    //Return default value
    return defaultValue
  }
}
