<template>
  <context-menu>
    <router-link
      class="Dropdown-item"
      :to="`/clubs/edit/${club.id}`"
    >
      <i class="Icon">edit</i> Edit
    </router-link>
    <a
      class="Dropdown-item"
      v-if="!club.isCancelled"
      @click="$emit('checkIn')"
    >
      <i class="Icon">emoji_people</i> Check-in
    </a>
    <a
      v-if="!club.isCancelled"
      class="Dropdown-item is-danger"
      @click="$emit('cancel')"
    >
      <i class="Icon">cancel</i> Cancel account
    </a>
    <a
      class="Dropdown-item"
      v-if="club.isCancelled"
      @click="$emit('reactivate')"
    >
      <i class="Icon">refresh</i> Reactivate
    </a>
    <a
      v-if="club.canRemove"
      class="Dropdown-item is-danger"
      @click="$emit('remove')"
    >
      <i class="Icon">delete_forever</i> Remove
    </a>
  </context-menu>
</template>

<script>
import ContextMenu from '@/components/shared/context-menu'

export default {
  emits: [
    'checkIn',
    'cancel',
    'reactivate',
    'remove',
  ],
  components: {
    ContextMenu,
  },
  props: {
    club: {
      type: Object,
    },
  },
}
</script>
