<template>
  <div class="Modal">
    <div :class="`ModalHeader ModalHeader--${type}`">
      <h1>{{title}}</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot />
    </div>
    <div class="ModalFooter">
      <button-bar
        :label-cancel="labelCancel"
        :show-confirm="false"
        @cancel="$emit('close')"
      >
      </button-bar>
    </div>
  </div>
</template>

<script>

export default {
  emits: [
    'close',
  ],
  props: {
    type: {
      type: String,
      default: () => 'success',
    },
    title: {
      type: String,
      default: () => 'Ok',
    },
    labelCancel: {
      type: String,
      default: () => 'Close',
    },
  },
}
</script>
