
/**
 * Online payment methods
 */
export const STRIPE = 'stripe'
export const POLI = 'poli'

/**
 * Default object
 */
export default [
  {
    value: STRIPE,
    label: 'Stripe',
  },
  {
    value: POLI,
    label: 'POLi',
  },
]
