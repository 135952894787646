<template>
  <modal-ok
    title="Request log details"
    @close="$emit('close')"
  >
    <strong :class="$style.title">Query string parameters</strong>
    <pre :class="$style.pre" v-if="requestLog.query">{{JSON.stringify(requestLog.query, null, 2)}}</pre>
    <pre :class="$style.pre" v-else>None</pre>

    <br>

    <strong :class="$style.title">Body</strong>
    <pre :class="$style.pre" v-if="requestLog.body">{{JSON.stringify(requestLog.body, null, 2)}}</pre>
    <pre :class="$style.pre" v-else>None</pre>
  </modal-ok>
</template>

<script>
import ModalOk from '@/components/shared/modals/modal-ok'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalOk,
  },
  props: {
    requestLog: {
      type: Object,
    },
  },
}
</script>

<style module lang="scss">
.title {
  margin-bottom: $spacing-m;
}
.pre {
  background: $porcelain;
  padding: $spacing-s;
}
</style>
