<template>
  <div class="PermissionValue">
    <i class="Icon Icon--success" v-if="isTrue">check</i>
    <i class="Icon Icon--danger" v-else-if="isFalse">close</i>
    <span v-else-if="isNull">None</span>
    <span v-else>{{value}} {{permission.unit}}</span>
    <i class="Icon Icon--xs Icon--faint PermissionValue-customIndicator" v-if="isCustom" v-tooltip.bottom="'Custom permission'">warning</i>
  </div>
</template>

<script>
export default {
  props: {
    club: {
      type: Object,
    },
    permission: {
      type: Object,
    },
  },
  computed: {
    value() {
      return this.permission.getValue(this.club)
    },
    isCustom() {
      return this.permission.isCustom(this.club)
    },
    isTrue() {
      return (this.value === true)
    },
    isFalse() {
      return (this.value === false)
    },
    isNull() {
      return (this.value === null)
    },
  },
}
</script>

<style lang="scss">
.PermissionValue {
  display: flex;
  align-items: center;
}
.PermissionValue-customIndicator {
  margin-left: $spacing-xxs;
}
</style>
