import CountryApi from '@/api/country.api'

/**
 * Countries service class
 */
class CountriesService {

  /**
   * Constructor
   */
  constructor() {
    this.countries = []
  }

  /**
   * Load countries
   */
  async load() {
    this.countries = await CountryApi.list()
    this.createMap()
  }

  /**
   * Get list of all countries
   */
  all() {
    return this.countries
  }

  /**
   * Create map based on countries
   */
  createMap() {
    this.map = this.countries.reduce((map, country) => {
      const code = this.parseCode(country.code)
      map[code] = country
      return map
    }, {})
  }

  /**
   * Find country by code
   */
  findByCode(code) {

    //No map yet
    if (!this.map) {
      return null
    }

    //Find country by code in map
    code = this.parseCode(code)
    return this.map[code]
  }

  /**
   * Ensure country code is consistent
   */
  parseCode(code) {
    return code.toUpperCase()
  }
}

/**
 * Export singleton instance
 */
export default new CountriesService()
