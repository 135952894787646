import BaseModel from './base.model'

/**
 * Member model
 */
export default class Member extends BaseModel {

  /**
   * Name
   */
  get name() {
    const {firstName, lastName} = this
    return `${firstName || ''} ${lastName || ''}`.trim()
  }
  set name(name) {
    return //TODO: virtuals sent from server or not?!?!
  }
}
