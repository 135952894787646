import Vue from 'vue'
import moment from '@/services/moment'

/**
 * Check if given string is a ISO 8601 date string,
 * Returns a moment if it is and null if it's not
 */
export function dateStringToMoment(value) {
  let regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/
  if (value.match(regex)) {
    let date = moment(value, moment.ISO_8601, true)
    if (date.isValid()) {
      return date
    }
  }
  return null
}

/**
 * Test for MongoDB object ID
 */
export function isId(str) {
  return str.match(/^[a-f\d]{24}$/i)
}

/**
 * Helper to copy a property
 */
export function copyProperty(obj, key, modelToId = false) {
  if (Array.isArray(obj[key])) {
    const arr = obj[key]
    return arr.map((value, key) => copyProperty(arr, key))
  }
  if (obj[key] === null) {
    return null
  }
  if (obj[key] instanceof BaseModel && modelToId) {
    return obj[key].id
  }
  if (obj[key] && typeof obj[key].clone === 'function') {
    return obj[key].clone()
  }
  if (typeof obj[key] === 'object') {
    return Object.assign({}, obj[key])
  }
  return obj[key]
}

/**
 * Extract subset of properties from an object
 */
export function extract(obj, keys, modelToId = true) {

  //If string given, just return copy of one property
  if (typeof keys === 'string') {
    return copyProperty(obj, keys, modelToId)
  }

  //Initialize object
  const extract = {}

  //No keys given? Iterate all object keys
  if (!Array.isArray(keys) || !keys.length) {
    keys = Object.keys(obj)
  }

  //Loop keys
  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      extract[key] = copyProperty(obj, key, modelToId)
    }
  }

  //Return resulting object
  return extract
}

/**
 * Base model
 */
export default class BaseModel {

  /**
   * Constructor
   */
  constructor(data, parent) {

    //Define parent property
    let _parent = parent
    Object.defineProperty(this, '$parent', {
      enumerable: false,
      get() {
        return _parent
      },
      set(parent) {
        _parent = parent
      },
    })

    //Submodels configuration
    Object.defineProperty(this, 'subModels', {
      enumerable: false,
      writable: true,
    })

    //Setup model
    this.setup()

    //Load data
    this.fromJSON(data)
  }

  /**
   * Setup
   */
  setup() {

    //Sub models
    this.subModels = {}
  }

  /**
   * Convert a property to a model
   */
  convertToModel(key, Model, isArray) {

    //If undefined, check what we were expecting
    if (typeof this[key] === 'undefined') {
      if (isArray) {
        this[key] = []
      }
      else {
        this[key] = null
      }
    }

    //If no model specified or if empty, we're done
    if (!Model || !this[key]) {
      return
    }

    //String ID only given?
    if (typeof this[key] === 'string' && isId(this[key])) {
      this[key] = Model.createFrom({id: this[key]}, this)
      return
    }

    //Convert to model
    this[key] = Model.createFrom(this[key], this)
  }

  /**
   * From JSON converter
   */
  fromJSON(json) {

    //Copy data
    if (json && typeof json === 'object') {
      for (const key in json) {
        if (Object.prototype.hasOwnProperty.call(json, key)) {
          Vue.set(this, key, BaseModel.valueFromJSON(json[key]))
        }
      }
    }

    //Process submodels
    const {subModels} = this
    for (const key in subModels) {
      const isArray = Array.isArray(subModels[key])
      const Model = isArray ? subModels[key][0] : subModels[key]
      this.convertToModel(key, Model, isArray)
    }

    //Return self
    return this
  }

  /**
   * To JSON converter
   */
  toJSON(data) {

    //Initialize JSON
    const json = {}

    //Copy extra given data
    if (data && typeof data === 'object') {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          json[key] = BaseModel.valueToJSON(data[key])
        }
      }
    }

    //Copy our own data, but don't overwrite given data
    for (const key in this) {
      const hasInSelf = Object.prototype.hasOwnProperty.call(this, key)
      const hasInJson = Object.prototype.hasOwnProperty.call(json, key)
      if (hasInSelf && !hasInJson) {
        json[key] = BaseModel.valueToJSON(this[key])
      }
    }

    //Id's only for sub models
    const {subModels} = this
    for (const key in subModels) {
      if (json[key]) {
        json[key] = BaseModel.onlyId(json[key])
      }
    }

    //Return JSON
    return json
  }

  /**
   * Extract subset of properties
   */
  extract(keys, modelToId) {
    return extract(this, keys, modelToId)
  }

  /**
   * Clone model
   */
  clone(stripId = false) {

    //Get model and create clone
    const Model = this.constructor
    const clone = new Model(null, this.$parent)

    //Copy our own properties
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        if (key === 'id' && stripId) {
          continue
        }
        clone[key] = copyProperty(this, key)
      }
    }

    //Return clone
    return clone
  }

  /**
   * Check if this model is the same as another (by ID)
   */
  isSame(model) {
    if (!model) {
      return false
    }
    if (typeof model === 'string') {
      return (this.id === model)
    }
    if (typeof model === 'object') {
      if (model === this) {
        return true
      }
      return (this.id && model.id && this.id === model.id)
    }
    return false
  }

  /**
   * Helper to convert a value from JSON
   */
  static valueFromJSON(value) {
    if (Array.isArray(value)) {
      return value.map(BaseModel.valueFromJSON)
    }
    else if (typeof value === 'string') {
      return dateStringToMoment(value) || value
    }
    else if (value && typeof value === 'object') {
      if (value._isAMomentObject) {
        return value.clone()
      }
      const copy = {}
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          copy[key] = BaseModel.valueFromJSON(value[key])
        }
      }
      return copy
    }
    return value
  }

  /**
   * Helper to convert a value to JSON
   */
  static valueToJSON(value) {
    if (Array.isArray(value)) {
      return value.map(BaseModel.valueToJSON)
    }
    else if (value && typeof value === 'object') {
      if (typeof value.toJSON === 'function') {
        return value.toJSON()
      }
      const copy = {}
      for (let key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          copy[key] = BaseModel.valueToJSON(value[key])
        }
      }
      return copy
    }
    return value
  }

  /**
   * Instantiate models from given data
   */
  static createFrom(data, parent = null, key = null) {

    //Array of data
    if (Array.isArray(data)) {
      return data.map(item => this.createFrom(item, parent, key))
    }

    //Data object with model present in specific key
    if (typeof data === 'object' && key) {
      data[key] = this.createFrom(data[key], parent)
      return data
    }

    //Single model
    return new this(data, parent)
  }

  /**
   * Only ID
   */
  static onlyId(obj) {
    if (Array.isArray(obj)) {
      return obj.map(this.onlyId)
    }
    if (!obj || typeof obj !== 'object' || !obj.id) {
      return obj
    }
    return obj.id
  }
}
