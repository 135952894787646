<template>
  <card
    title="Plan details"
  >
    <template v-slot:title>
      <i
        class="Icon Icon--faint Icon--s Icon--inline clickable"
        @click="edit()"
        v-tooltip.bottom="'Edit plan details'"
      >edit</i>
    </template>
    <grid rows="8" row-spacing="xs">
      <icon-label icon="bookmark" label="Plan" :icon-class="`PlanIcon--${club.plan.identifier}`">
        {{club.plan.name}}
      </icon-label>
      <icon-label icon="bar_chart" label="Monthly revenue">
        {{club.monthlyRevenue | currency}}
        <small class="text-muted" v-if="club.stripe.monthlyFee && club.currencyCode !== club.stripe.currencyCode">
          ({{club.stripe.currencyCode}}{{club.stripe.monthlyFee | currency({symbol: ''})}})
        </small>
      </icon-label>
      <icon-label icon="schedule" label="Billing interval">
        {{club.isPayingYearly ? 'Yearly' : 'Monthly'}}
      </icon-label>
      <icon-label icon="credit_card" label="Payment method">
        <span v-if="club.isPayingByInvoice">Invoice</span>
        <span v-else-if="club.defaultSource && club.defaultSource.card">
          <img class="BillingSource-icon" :src="`/images/payment/${club.defaultSourceBrand}.png`">
          {{club.defaultSource.brand}} ***{{club.defaultSource.last4}}
        </span>
        <span v-else>None</span>
      </icon-label>
      <icon-label icon="hourglass_bottom" label="Trial end date">
        <span v-if="club.trialEndDate">{{club.trialEndDate | moment}}</span>
        <span v-else>None</span>
      </icon-label>
      <icon-label icon="today" label="Plan start date">
        <span v-if="club.stripe.currentPeriodStart">{{club.stripe.currentPeriodStart | moment}}</span>
        <span v-else>None</span>
      </icon-label>
      <icon-label icon="event" label="Plan end date">
        <span v-if="club.stripe.currentPeriodEnd">{{club.stripe.currentPeriodEnd | moment}}</span>
        <span v-else>None</span>
      </icon-label>
      <icon-label icon="event_busy" label="Plan expiration date">
        <span v-if="club.planExpirationDate">{{club.planExpirationDate | moment}}</span>
        <span v-else>None</span>
      </icon-label>
    </grid>
  </card>
</template>

<script>
import ModalEditPlanDetails from '../modals/edit-plan-details'

export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
    plans: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

      //Flags
      isSaving: false,
    }
  },
  methods: {

    /**
     * Edit plan details
     */
    edit() {

      //Get data
      const {club, plans, admins} = this

      //Create save handler
      const onSave = async (model, params) => {
        await club.changePlan(model, params)
      }

      //Open editing modal
      this.$modal.show(ModalEditPlanDetails, {
        club, plans, admins, onSave,
      })
    },
  },
}
</script>

<style lang="scss">
.BillingSource-icon {
  height: 15px;
  width: auto;
  margin-right: $spacing-xxs;
  vertical-align: -2px;
}
</style>
