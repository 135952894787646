<template>
  <div class="Card">
    <h2 class="Card-header">
      {{title}}
      <slot name="title" />
      <spinner class="Spinner--inline" v-if="hasTitleSpinner"/>
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '',
    },
    hasTitleSpinner: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss">
.Card {
  background: $athens;
  padding: $spacing-l;
  @include verticallySpaced;
}
.Cards {
  @include verticallySpaced;
}
.Cards--grid {
  display: grid;
  grid-column-gap: $spacing-m;
  grid-row-gap: $spacing-m;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  .Card {
    margin-top: 0;
  }
}
.Cards--sideBySide {
  display: flex;
  .Card {
    flex: 1;
    margin-top: 0;
  }
  :first-child {
    margin-right: $spacing-xs;
  }
  :last-child {
    margin-left: $spacing-xs;
  }
}
.Card-header {
  .Icon--inline {
    padding: 0 $spacing-xxs;
  }
}
</style>
