<template>
  <card
    :title="club.name"
  >
    <template v-slot:title>
      <i
        class="Icon Icon--faint Icon--s Icon--inline clickable"
        @click="edit()"
        v-tooltip.bottom="'Edit club details'"
      >edit</i>
      <club-links :club="club" />
    </template>
    <grid rows="6" row-spacing="xs">
      <icon-label icon="storage" label="Id">
        {{club.id}}
      </icon-label>
      <icon-label icon="fingerprint" label="Identifier">
        {{club.identifier}}
      </icon-label>
      <icon-label icon="category" label="Type">
        {{club.type}}
      </icon-label>
      <icon-label icon="alternate_email" label="Email">
        <a v-if="club.email" :href="`mailto:${club.email}`">{{club.email}}</a>
        <span v-else>–</span>
      </icon-label>
      <icon-label icon="phone" label="Phone">
        <a v-if="club.phone" :href="`tel:${club.phone}`">{{club.phone}}</a>
        <span v-else>–</span>
      </icon-label>
      <icon-label icon="cloud" label="Domain">
        {{club.domain}}
      </icon-label>

      <icon-label icon="lens" label="Status">
        <span v-if="club.isCancelled">
          Cancelled on {{club.cancelDate | moment}}
        </span>
        <span v-else-if="club.isTrial">
          Trial
          <span v-if="club.trialEndDate">(until {{club.trialEndDate | moment}})</span>
        </span>
        <span v-else>
          Active
          <small class="text-muted" v-if="club.stripe.currentPeriodEnd">(ends {{club.stripe.currentPeriodEnd | moment}})</small>
        </span>
      </icon-label>
      <icon-label icon="build" label="Setup progress">
        <span
          :class="{
            'text-danger': club.setup.progress < 50,
            'text-warning': club.setup.progress >= 50 && club.setup.progress < 75,
            'text-success': club.setup.progress >= 75,
          }"
        >{{club.setup.progress}}%</span>
      </icon-label>
      <icon-label icon="star" label="Engagement score">
        <span
          :class="{
            'text-danger': club.engagement.score < 0.4,
            'text-warning': club.engagement.score >= 0.4 && club.engagement.score < 0.6,
            'text-success': club.engagement.score >= 0.6,
          }"
        >
          <v-popover trigger="click" placement="bottom" class="clickable">
            {{club.engagement.score | percentage({maximumFractionDigits: 2})}}
            <template v-slot:popover>
              <div class="Tooltip Tooltip--list" role="tooltip">
                <div class="Tooltip-body">
                  <div v-for="factor in club.engagement.factors" :key="factor.type">
                    {{factor.type | label(EngagementFactors)}}:
                    {{factor.score | percentage({maximumFractionDigits: 2})}}
                  </div>
                </div>
              </div>
            </template>
          </v-popover>
        </span>
      </icon-label>
      <icon-label icon="cake" label="Signed up">
        {{club.signUpDate | moment}}
        <small class="text-muted">({{club.signUpDate | moment('fromNow')}})</small>
      </icon-label>
      <icon-label icon="event_available" label="Last active">
        {{club.lastActive | moment}}
        <small class="text-muted">({{club.lastActive | moment('fromNow')}})</small>
      </icon-label>
      <icon-label icon="emoji_people" label="Last check-in">
        <span v-if="club.checkInDate">
          <span
            class="clickable"
            @click="checkIn()"
          >
            {{club.checkInDate | moment}}
          </span>
          <small class="text-muted">({{club.checkInDate | moment('fromNow')}})</small>
        </span>
        <span
          v-else
          class="clickable text-danger"
          @click="checkIn()"
        >Never</span>
      </icon-label>

      <icon-label icon="people" label="Active members">
        <span
          :class="{
            'text-warning': (
              club.numActiveMembers >= club.permissions.memberSoftLimit &&
              club.numActiveMembers < club.permissions.memberHardLimit
            ),
            'text-danger': club.numActiveMembers >= club.permissions.memberHardLimit,
          }"
        >{{club.numActiveMembers}}</span>
        <small class="text-muted"> / {{club.permissions.memberSoftLimit}}</small>
      </icon-label>
      <icon-label icon="language" label="Country">
        {{club.countryCode | country('flag')}}
        {{club.countryCode | country('name')}}
      </icon-label>
      <icon-label icon="schedule" label="Timezone">
        {{club.timezone | timezone}}
      </icon-label>
      <icon-label icon="attach_money" label="Currency">
        {{club.currencyCode}}
        <small class="text-muted">{{club.currencyCode | currencySymbol}}</small>
      </icon-label>
      <icon-label icon="input" label="Source">
        {{club.source || 'Unknown'}}
      </icon-label>
      <icon-label icon="person_search" label="Approx. members" v-if="club.approxMembers">
        {{club.approxMembers}}
      </icon-label>
    </grid>
  </card>
</template>

<script>
import moment from '@/services/moment'
import EngagementFactors from '@/constants/engagement-factors'
import ModalEditDetails from '../modals/edit-details'
import ClubLinks from '../club-links'

export default {
  components: {
    ClubLinks,
  },
  props: {
    club: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {

      //Flags
      isSaving: false,
      EngagementFactors,
    }
  },
  methods: {

    /**
     * Check-in
     */
    async checkIn() {

      //Create new date
      const {club} = this
      const checkInDate = moment()

      //Update check-in date
      await club.patch({checkInDate})
      this.$notice.show(`Checked in!`)
    },

    /**
     * Edit details
     */
    edit() {

      //Get club
      const {club} = this

      //Create save handler
      const onSave = async (model) => {
        await club.patch(model)
      }

      //Open editing modal
      this.$modal.show(ModalEditDetails, {
        club, onSave,
      })
    },
  },
}
</script>

<style lang="scss">
</style>
