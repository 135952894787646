<template>
  <modal-edit
    title="Edit migration details"
    :model="model"
    :validation="$v"
    :on-save="onSave"
    @close="$emit('close')"
  >
    <div class="Form">
      <grid cols="2" row-spacing="m">
        <group label="Flagged for migration">
        <select-box
            class="SelectBox--xs"
            input-class="Input--onGray"
            :options="YesNo"
            :is-nullable="true"
            :is-null-undefined="true"
            :model="model.willBeMigratedToFrello"
            @change="$set(model, 'willBeMigratedToFrello', $event.value)"
          />
        </group>
        <group label="Migration date">
          <date-picker
            :model="model.frelloMigrationDate"
            :can-clear="true"
            @change="$set(model, 'frelloMigrationDate', $event.date)"
          />
        </group>
      </grid>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modals/modal-edit'
import YesNo from '@/constants/yes-no'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalEdit,
  },
  data() {
    return {

      //Model
      model: {
        willBeMigratedToFrello: null,
        frelloMigrationDate: null,
      },

      //Constants
      YesNo,
    }
  },
  props: {
    club: {
      type: Object,
    },
    onSave: {
      type: Function,
    },
  },
  created() {

    //Create model subset
    this.model = this.club.extract(Object.keys(this.model))
  },
}
</script>
