<template>
  <div class="CheckBoxesGroup">
    <label
      class="CheckBox"
      v-for="(option, index) in options"
      :key="option[bindKey]"
      :class="{
        checked: isChecked(option, index),
        disabled: isDisabled,
      }"
      @click="selectOption(option, index)"
    >
      <i
        class="Icon CheckBox-icon Icon--success"
        v-if="isChecked(option, index)"
      >radio_button_checked</i>
      <i
        class="Icon CheckBox-icon Icon--faint"
        v-else
      >radio_button_unchecked</i>
      {{getLabelValue(option)}}
    </label>
  </div>
</template>

<script>

export default {
  emits: [
    'change',
  ],
  props: {
    model: {
      type: [String, Number, Object, Boolean],
      default: () => null,
      validator: prop => (
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        typeof prop === 'object' ||
        typeof prop === 'boolean' ||
        prop === null
      ),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: () => undefined,
    },
    trackBy: {
      type: String,
      default: () => undefined,
    },
    asObject: {
      type: Boolean,
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    bindKey: {
      type: String,
      default: () => 'value',
    },
  },
  computed: {
    labelKey() {
      if (typeof this.labelBy !== 'undefined') {
        return this.labelBy
      }
      return this.determineKey('label', ['label', 'name'])
    },
    trackKey() {
      if (typeof this.trackBy !== 'undefined') {
        return this.trackBy
      }
      return this.determineKey('track', ['value', 'id'])
    },
  },
  methods: {

    /**
     * Select an option
     */
    selectOption(option, index) {

      //Get the new model value and call on change handler
      const value = this.getModelValue(option, index)
      this.$emit('change', {value, option, index})
    },

    /**
     * Check if an option is checked
     */
    isChecked(option, index) {

      //Get data
      const {model} = this

      //Get option value
      const optionValue = this.getTrackingValue(option, index)
      const modelValue = this.getTrackingValue(model, model)

      //Compare
      return (modelValue === optionValue)
    },

    /**
     * Determine key based on options
     */
    determineKey(type, candidates) {

      //Must have options
      if (!this.options || this.options.length === 0) {
        return null
      }

      //Get first option and check if it's an object
      const first = this.options[0]
      if (typeof first !== 'object') {
        return null
      }

      //Find key from candidates
      const key = candidates.find(key => typeof first[key] !== 'undefined')
      if (key) {
        return key
      }

      //Unable to determine
      throw new Error(`Cannot deermine ${type} key`)
    },

    /**
     * Get the model value
     */
    getModelValue(option, index) {

      //Get data
      const {asObject} = this

      //If returning as object, return the selected option
      if (asObject) {
        return option
      }

      //Otherwise, return the tracking value of the given option
      return this.getTrackingValue(option, index)
    },

    /**
     * elper to get the tracking value of an option
     */
    getTrackingValue(option, index) {

      //Get data
      const {trackKey} = this

      //Tracking by index?
      if (trackKey === '$index') {
        return index
      }

      //Null
      if (option === null) {
        return null
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option
      }

      //Validate property
      if (typeof option[trackKey] === 'undefined') {
        throw new Error(`Unknown property '${trackKey}' for check boxes`)
      }

      //Return the property
      return option[trackKey]
    },

    /**
     * Get label value of an option
     */
    getLabelValue(option) {

      //Get data
      const {labelKey} = this

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option
      }

      //Validate property
      if (typeof option[labelKey] === 'undefined') {
        throw new Error(`Unknown property '${labelKey}' for check boxes label`)
      }

      //Return the property
      return option[labelKey]
    },
  },
}
</script>

<style lang="scss">
</style>
