import merge from 'deepmerge'
import base from './base'

/**
 * Production environment configuration
 */
export default merge.all([base, {

  //TEMP club identifier so we can login
  club: 'do-not-delete',

  //Admin portal
  portal: {
    baseUrl: 'https://admin.helloclub.com',
  },

  //App
  app: {
    baseUrl: 'https://%hostname%',
  },

  //API
  api: {
    baseUrl: 'https://api.helloclub.com',
  },

  //Domains
  domains: {
    base: 'helloclub.com',
    generic: 'app.helloclub.com',
  },

  //Sentry
  sentry: {
    dsn: `https://dd3b42c0dc2b4708993ebd3c9ccb0e2f@o110800.ingest.sentry.io/5379332`,
  },
}])
