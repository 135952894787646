<template>
  <modal-confirm
    :title="`Reactivate ${club.type} account`"
    :on-confirm="onConfirm"
    @close="$emit('close')"
  >
    <p>Are you sure you want to reactivate the account for <strong>{{club.name}}</strong>?</p>
  </modal-confirm>
</template>

<script>
import ModalConfirm from '@/components/shared/modals/modal-confirm'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalConfirm,
  },
  props: {
    club: {
      type: Object,
    },
    onConfirm: {
      type: Function,
    },
  },
}
</script>
