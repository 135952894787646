
/**
 * Setup page data
 */
export const setup = async ({commit}, data) => {

  //Get data
  const {title} = data

  //Commit
  commit('setPageTitle', title)
}

/**
 * Clear page data
 */
export const clear = async ({commit}) => {

  //Clear data
  commit('clearPageTitle')
}

/**
 * Flag page as loading
 */
export const loading = async ({commit}, isLoading) => {

  //Set loaded
  commit('setLoading', isLoading)
}
