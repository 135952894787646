
/**
 * Stripe collection methods
 */
export const CHARGE_AUTOMATICALLY = 'charge_automatically'
export const SEND_INVOICE = 'send_invoice'

/**
 * Default object
 */
export default [
  {
    value: CHARGE_AUTOMATICALLY,
    label: 'Card',
  },
  {
    value: SEND_INVOICE,
    label: 'Invoice',
  },
]
