<template>
  <div class="CheckBoxGroup">
    <label
      class="CheckBox"
      v-for="(option, index) in options"
      :key="option[bindKey]"
      :class="{
        checked: isChecked(option, index),
        disabled: isDisabled,
      }"
      @click="toggle(option, index)"
    >
      <i
        class="Icon CheckBox-icon Icon--success"
        v-if="isChecked(option, index)"
      >check_box</i>
      <i
        class="Icon CheckBox-icon Icon--faint"
        v-else
      >check_box_outline_blank</i>
      {{getLabelValue(option)}}
    </label>
  </div>
</template>

<script>

export default {
  emits: [
    'change',
  ],
  props: {
    model: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: () => undefined,
    },
    trackBy: {
      type: String,
      default: () => undefined,
    },
    asObject: {
      type: Boolean,
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    bindKey: {
      type: String,
      default: () => 'value',
    },
  },
  computed: {
    labelKey() {
      if (typeof this.labelBy !== 'undefined') {
        return this.labelBy
      }
      return this.determineKey('label', ['label', 'name'])
    },
    trackKey() {
      if (typeof this.trackBy !== 'undefined') {
        return this.trackBy
      }
      return this.determineKey('track', ['value', 'id'])
    },
  },
  methods: {

    /**
     * Toggle an option
     */
    toggle(option, index) {

      //Get data
      const {asObject, model} = this

      //Initialize value for model array
      let value = []
      if (Array.isArray(model)) {
        value = model.map(item => item)
      }

      //Check if currently checked and get option value
      const isChecked = this.isChecked(option, index)
      const optionValue = this.getTrackingValue(option, index)

      //If checked, remove from target model, otherwise add
      if (isChecked) {
        const i = value.findIndex(model => {
          const modelValue = this.getTrackingValue(model, model)
          return (modelValue === optionValue)
        })
        value.splice(i, 1)
      }
      else {
        value.push(asObject ? option : optionValue)
      }

      //Emit change
      this.$emit('change', {value})
    },

    /**
     * Check if an option is checked
     */
    isChecked(option, index) {

      //Get data
      const {model} = this

      //Nothing selected
      if (!Array.isArray(model) || model.length === 0) {
        return false
      }

      //Get option value
      const optionValue = this.getTrackingValue(option, index)

      //See if present in model values
      return model.some(model => {
        const modelValue = this.getTrackingValue(model, model)
        return (modelValue === optionValue)
      })
    },

    /**
     * Determine key based on options
     */
    determineKey(type, candidates) {

      //Must have options
      if (!this.options || this.options.length === 0) {
        return null
      }

      //Get first option and check if it's an object
      const first = this.options[0]
      if (typeof first !== 'object') {
        return null
      }

      //Find key from candidates
      const key = candidates.find(key => typeof first[key] !== 'undefined')
      if (key) {
        return key
      }

      //Unable to determine
      throw new Error(`Cannot determine ${type} key`)
    },

    /**
     * Helper to get the tracking value of an option
     */
    getTrackingValue(option, index) {

      //Get data
      const {trackKey} = this

      //Tracking by index?
      if (trackKey === '$index') {
        return index
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option
      }

      //Validate property
      if (typeof option[trackKey] === 'undefined') {
        throw new Error(`Unknown property '${trackKey}' for check boxes`)
      }

      //Return the property
      return option[trackKey]
    },

    /**
     * Get label value of an option
     */
    getLabelValue(option) {

      //Get data
      const {labelKey} = this

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option
      }

      //Validate property
      if (typeof option[labelKey] === 'undefined') {
        throw new Error(`Unknown property '${labelKey}' for check boxes label`)
      }

      //Return the property
      return option[labelKey]
    },
  },
}
</script>

<style lang="scss">
.CheckBoxGroup {
  display: flex;
  flex-direction: column;
}
.CheckBoxGroup--inline {
  @include minWidth-xs {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .CheckBox {
    white-space: nowrap;
    @include minWidth-xs {
      display: inline-flex;
      margin-right: $spacing-l;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
