import ClientError from './client'

/**
 * Not authenticated error
 */
export default class NotAuthenticatedError extends ClientError {

  /**
   * Constructor
   */
  constructor(message, data) {

    //Parent constructor
    super(message, data, 401)
  }
}
