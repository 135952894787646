<template>
  <div class="Tick">
    <i class="Icon" :class="classTruthy" v-if="isTruthy">{{iconTruthy}}</i>
    <i class="Icon" :class="classFalsey" v-else>{{iconFalsey}}</i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number, String],
      default: () => null,
    },
    classTruthy: {
      type: String,
      default: () => 'Icon--success',
    },
    classFalsey: {
      type: String,
      default: () => 'Icon--danger',
    },
    iconTruthy: {
      type: String,
      default: () => 'check',
    },
    iconFalsey: {
      type: String,
      default: () => 'close',
    },
  },
  computed: {
    isTruthy() {
      return !!this.value
    },
  },
}
</script>

<style lang="scss">
.Tick {
  display: inline-block;
}
</style>
