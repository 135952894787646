<template>
  <div class="FilterWrapper">
    <div
      class="FilterItem Dropdown-item"
      :class="{'FilterItem--active': !isDefault}"
      ref="trigger"
      @click="toggleSubFilter()"
    >
      <i
        v-if="icon"
        class="Icon FilterItem-icon"
        :class="{'is-active': !isDefault}"
      >
        {{icon}}
      </i>
      <span class="FilterItem-label">{{label}}</span>
      <i class="Icon FilterItem-iconExpand">chevron_right</i>
    </div>
    <div ref="subfilters">
      <div class="Dropdown SubFilter" v-if="isShowingSubFilter">
        <div class="Dropdown-body">
          <div class="Dropdown-item Dropdown-item--static">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {clickHandler} from '@/helpers/ux'

export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    filterKey: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    isShowingSubFilter: false,
  }),
  computed: {
    isDefault() {
      return this.filter.isDefault(this.filterKey)
    },
  },
  mounted() {

    //Get trigger and handler
    const {trigger, subfilters} = this.$refs
    const handler = this.hideSubFilter.bind(this)

    //Create click handler
    this.removeHandler = clickHandler(handler, [trigger, subfilters])
  },
  unmounted() {
    this.removeHandler()
  },
  methods: {
    toggleSubFilter() {
      this.isShowingSubFilter = !this.isShowingSubFilter
    },
    hideSubFilter() {
      this.isShowingSubFilter = false
    },
  },
}
</script>
<style lang="scss">
.FilterWrapper {
  position: relative;
}
.FilterItem-label {
  flex: 1;
}
.FilterItem-icon {
  margin-right: $spacing-xs;
  color: $silver;
  &.is-active {
    color: $green;
  }
}
.FilterItem-iconExpand {
  color: $silver;
}
.FilterItem {
  &.is-active {
    font-weight: $bold;
  }
}
.SubFilter {
  left: 100%;
  top: $spacing-xs;
  margin-left: -$spacing-m;
  min-width: 200px;
}
</style>
