
/**
 * Simple yes/no array for dropdowns
 */
export const YES = true
export const NO = false

/**
 * Default object
 */
export default [
  {
    value: YES,
    label: 'Yes',
  },
  {
    value: NO,
    label: 'No',
  },
]
