import BaseApi from './base.api'

/**
 * Online payments history API service
 */
class OnlinePaymentsHistoryApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/onlinePaymentsHistory',
      dataKey: 'items',
    })
  }

  /**
	 * Aggregate
	 */
  aggregate(filter) {
    return this
      .get(`aggregate`, filter)
  }
}

/**
 * Export singleton instance
 */
export default new OnlinePaymentsHistoryApi()
