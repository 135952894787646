<template>
  <label
    class="CheckBox"
    :class="{
      checked: isChecked,
      disabled: isDisabled,
    }"
    @click="toggle()"
  >
    <i class="Icon CheckBox-icon Icon--success" v-if="isChecked">check_box</i>
    <i class="Icon CheckBox-icon Icon--faint" v-else>check_box_outline_blank</i>
    <slot />
  </label>
</template>

<script>

export default {
  emits: [
    'change',
  ],
  props: {
    model: {
      type: [Boolean, Number, String, Array],
      default: () => null,
    },
    checkedValue: {
      type: [Boolean, Number, String],
      default: () => true,
    },
    uncheckedValue: {
      type: [Boolean, Number, String],
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    isArray: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    isChecked() {
      const {model, checkedValue, isArray} = this
      if (isArray && Array.isArray(model)) {
        return model.includes(checkedValue)
      }
      else if (isArray) {
        return false
      }
      return (model === checkedValue)
    },
  },
  methods: {

    /**
     * Toggle
     */
    toggle() {

      //Get data
      const {isDisabled, model, checkedValue, uncheckedValue, isArray} = this

      //Don't toggle when disabled
      if (isDisabled) {
        return
      }

      //Initialize value
      let value

      //Array model
      if (isArray) {
        value = (model || []).map(item => item)
        const i = value.findIndex(item => item === checkedValue)
        if (i !== -1) {
          value.splice(i, 1)
        }
        else {
          value.push(checkedValue)
        }
      }

      //Other
      else {
        value = (model === checkedValue) ? uncheckedValue : checkedValue
      }

      //Emit change
      this.$emit('change', {value})
    },
  },
}
</script>

<style lang="scss">
.CheckBox {
  display: flex;
  align-items: center;
  padding: $spacing-xxs 0;
  cursor: pointer;
}
.CheckBox-icon {
  margin-right: $spacing-xxs;
}
</style>
