import vueModal from 'vue-js-modal'
import modal from '@/services/modal'

/**
 * Modal plugin
 */
const Plugin = {

  /**
   * Install plugin
   */
  install(Vue, options) {

    //First, install the vue modal plugin and pass through the options
    Vue.use(vueModal, options)

    //Get defaults from options
    const {defaults} = options

    //Configure service
    modal.setDefaults(defaults)
    modal.setService(Vue.prototype.$modal)

    //Overwrite service now
    Vue.prototype.$modal = modal
  },
}

/**
 * Export plugin
 */
export default Plugin
