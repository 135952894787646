import http from '@/services/http'
import {join} from '@/helpers/path'

/**
 * Base API class
 */
export default class BaseApi {

  /**
   * Constructor
   */
  constructor(config) {

    //Get config
    const {basePath, dataKey, Model} = config

    //Set properties from config
    this.setBasePath(basePath)
    this.setDataKey(dataKey)
    this.setModel(Model)
  }

  /**
   * Set base path
   */
  setBasePath(path = '/') {
    if (path.charAt(0) !== '/') {
      path = `/${path}`
    }
    this.basePath = path
  }

  /**
   * Set data key
   */
  setDataKey(dataKey = null) {
    this.dataKey = dataKey
  }

  /**
   * Set model
   */
  setModel(Model = null) {
    this.Model = Model
  }

  /**************************************************************************
   * Generic methods
   ***/

  /**
   * Query
   */
  query(filter) {
    const {dataKey, Model} = this
    return this
      .get(`/`, filter)
      .then(data => {
        if (Model) {
          return Model.createFrom(data, null, dataKey)
        }
        return data
      })
  }

  /**
	 * Get list of items
	 */
  list(filter) {
    const {dataKey} = this
    return this
      .query(filter)
      .then(data => data[dataKey])
  }

  /**
	 * Count
	 */
  count(filter) {
    return this
      .get(`count`, filter)
      .then(data => data.total)
  }

  /**
	 * Find by ID
	 */
  findById(id) {

    //No ID
    if (!id) {
      throw new Error(`Missing ID`)
    }

    //Do request
    const {Model} = this
    return this
      .get(`${id}`)
      .then(data => {
        if (Model) {
          return Model.createFrom(data)
        }
        return data
      })
  }

  /**
   * Update
   */
  update(id, data, params) {

    //No ID or property
    if (!id) {
      throw new Error(`Missing ID`)
    }

    //Patch
    return this
      .patch(`${id}`, data, {params})
  }

  /**
   * Remove
   */
  remove(id) {

    //No ID
    if (!id) {
      throw new Error(`Missing ID`)
    }

    //Remove
    return this
      .delete(`${id}`)
  }

  /**************************************************************************
   * Helper wrappers that build the correct path
   ***/

  /**
   * Helper wrapper for GET requests
   */
  get(path, ...args) {
    const url = join(this.basePath, path)
    return http.get(url, ...args)
  }

  /**
   * Helper wrapper for POST requests
   */
  post(path, ...args) {
    const url = join(this.basePath, path)
    return http.post(url, ...args)
  }

  /**
   * Helper wrapper for DELETE requests
   */
  delete(path, ...args) {
    const url = join(this.basePath, path)
    return http.delete(url, ...args)
  }

  /**
   * Helper wrapper for PUT requests
   */
  put(path, ...args) {
    const url = join(this.basePath, path)
    return http.put(url, ...args)
  }

  /**
   * Helper wrapper for PATCH requests
   */
  patch(path, ...args) {
    const url = join(this.basePath, path)
    return http.patch(url, ...args).then(data => {
      return data
    })
  }
}
