<template>
  <div class="DatePicker">
    <div
      class="InputWrapper clickable"
      @click="toggleCalendar()"
    >
      <input
        readonly
        :disabled="isDisabled"
        class="Input Input--date"
        type="text"
        v-model="dateFormatted"
      >
      <span class="Input-icon Input-icon--datePicker">
        <i class="Icon">date_range</i>
      </span>
    </div>
    <div class="Dropdown Calendar" v-if="isShowingCalendar">
      <div class="Calendar-header">
        <span
          class="Calendar-headerButton"
          :class="{'is-disabled': !canBrowsePrevMonth}"
          @click="prevMonth()"
        >
          <i class="Icon">chevron_left</i>
        </span>
        <span class="Calendar-headerTitle">{{title}}</span>
        <span
          class="Calendar-headerButton"
          :class="{'is-disabled': !canBrowseNextMonth}"
          @click="nextMonth()"
        >
          <i class="Icon">chevron_right</i>
        </span>
      </div>
      <ul class="Calendar-list Calendar-list--weekdays">
        <li
          v-for="weekday in weekdays"
          :key="weekday"
        >
          {{weekday}}
        </li>
      </ul>
      <ol class="Calendar-list Calendar-list--days">
        <li
          @click="selectDate(day.date)"
          v-for="day in days"
          :key="day.id"
        >
          <span
            :class="{'is-date': !!day.date, 'is-disabled': day.isDisabled, 'is-today': day.isToday, 'is-selected': isSelected(day.date)}"
          >
            {{day.label}}
          </span>
        </li>
      </ol>
      <div class="CalendarButtons">
        <button
          class="Button Button--s Button--muted"
          v-if="canSetToday"
          @click="setToday()"
        >
          <i class="Icon Icon--s">event</i>
          Today
        </button>
        <button
          class="Button Button--s Button--muted"
          v-if="canClear && model"
          @click="clear()"
        >
          <i class="Icon Icon--s">close</i>
          Clear
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from '@/services/moment'
import {dateFormatStandard} from '@/constants/date-formats'

export default {
  emits: [
    'change',
  ],
  data() {
    return {
      isShowingCalendar: false,
      viewDate: null,
      weekdays: moment.wkDaysShort(),
    }
  },
  props: {
    model: {
      type: Object,
    },
    minDate: {
      type: Object,
    },
    maxDate: {
      type: Object,
    },
    titleFormat: {
      type: String,
      default: () => 'MMMM YYYY',
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    canClear: {
      type: Boolean,
      default: () => false,
    },
    startOf: {
      type: String,
      default: () => 'day',
    },
    endOf: {
      type: String,
      default: () => '',
    },
    dateFormat: {
      type: String,
      default: () => dateFormatStandard,
    },
  },
  computed: {
    dateFormatted() {
      return this.model ? this.model.format(this.dateFormat) : ''
    },
    days() {
      return this.generateDays()
    },
    title() {
      return this.viewDate.format(this.titleFormat)
    },
    canSetToday() {
      return (
        (!this.minDate || moment().isSameOrAfter(this.minDate, 'day')) &&
        (!this.maxDate || moment().isSameOrBefore(this.maxDate, 'day'))
      )
    },
    canBrowseNextMonth() {
      return (
        !this.maxDate || (this.maxDate &&
        this.viewDate.isBefore(this.maxDate, 'month'))
      )
    },
    canBrowsePrevMonth() {
      return (
        !this.minDate || (this.minDate &&
        this.viewDate.isAfter(this.minDate, 'month'))
      )
    },
  },
  created() {

    //Initialize view date and validate
    this.setViewDate(this.model || this.minDate)
  },
  methods: {

    /**
     * Set new date
     */
    setNewDate(date) {

      //Set start/end of period if needed
      if (date) {
        if (this.startOf) {
          date.startOf(this.startOf)
        }
        else if (this.endOf) {
          date.endOf(this.endOf)
        }
      }

      //Emit change
      this.$emit('change', {date})

      //Set view date and toggle calendar
      this.setViewDate(date)
      this.toggleCalendar()
    },

    /**
     * Set view date
     */
    setViewDate(date) {

      //No date given
      if (!date) {
        this.viewDate = moment().startOf('month')
        return
      }

      //Clone date
      this.viewDate = date.clone().startOf('month')
    },

    /**
     * Validate min/max dates
     */
    validate() {
      if (this.model) {
        if (this.minDate && this.model.isBefore(this.minDate)) {
          // this.setNewDate(this.minDate.clone());
        }
        if (this.maxDate && this.model.isAfter(this.maxDate)) {
          // this.setNewDate(this.maxDate.clone());
        }
      }
    },

    /**
     * Toggle calendar
     */
    toggleCalendar() {

      //Already showing
      if (this.isShowingCalendar) {
        this.isShowingCalendar = false
        return
      }

      //Not when disabled
      if (this.isDisabled) {
        return
      }

      //Show calendar
      this.isShowingCalendar = true
    },

    /**
     * Clear
     */
    clear() {
      this.setNewDate(null)
    },

    /**
     * Set today
     */
    setToday() {
      const date = moment().startOf('minute')
      this.selectDate(date, false)
    },

    /**
     * Next month
     */
    nextMonth() {
      if (this.canBrowseNextMonth) {
        this.viewDate = this.viewDate.clone().add(1, 'month')
      }
    },

    /**
     * Previous month
     */
    prevMonth() {
      if (this.canBrowsePrevMonth) {
        this.viewDate = this.viewDate.clone().subtract(1, 'month')
      }
    },

    /**
     * Select date
     */
    selectDate(date, close) {

      //No date?
      if (!date) {
        return
      }

      //Check if possible
      if (this.minDate && date.isBefore(this.minDate, 'day')) {
        return
      }
      if (this.maxDate && date.isAfter(this.maxDate, 'day')) {
        return
      }

      //Set new date
      this.setNewDate(date.clone(), close)
    },

    /**
     * Check if a date is the selected date
     */
    isSelected(date) {
      if (!date || !this.model) {
        return false
      }
      return this.model.isSame(date, 'day')
    },

    /**
     * Generate the days
     */
    generateDays() {

      //Initialize days
      const days = []

      //Get number of days and weekday of the first day
      const today = moment()
      const period = this.viewDate.format('MMYYYY')
      const noDays = this.viewDate.daysInMonth()
      const firstDay = this.viewDate.isoWeekday()

      //Filler days of previous month
      for (let n = 1; n < firstDay; n++) {
        days.push({
          id: period + '0' + n,
          label: '',
        })
      }

      //Actual days
      let date = this.viewDate.clone().subtract(1, 'day')
      for (let d = 1; d <= noDays; d++) {

        //Increment to next day and create day object
        date.add(1, 'day')
        let day = {
          id: period + d,
          date: date.clone(),
          label: d,
          isDisabled: false,
          isToday: today.isSame(date, 'day'),
        }

        //Check if disabled
        if (this.minDate && date.isBefore(this.minDate, 'day')) {
          day.isDisabled = true
        }
        if (this.maxDate && date.isAfter(this.maxDate, 'day')) {
          day.isDisabled = true
        }

        //Add day object
        days.push(day)
      }

      //Get weekday of last day
      let lastDay = date.isoWeekday()

      //Filler days of next month
      for (let m = lastDay + 1; m <= 7; m++) {
        days.push({
          id: period + '4' + m,
          label: '',
        })
      }

      //Return days
      return days
    },
  },
}
</script>
<style lang="scss">

//Calendar variables
$calendarCellSize: 2rem;
$calendarCellMargin: .1rem;

//Date pciker
.DatePicker {
  width: 100%;
  display: inline-block;
  position: relative;
}

.Input--date[readonly] {
  color: $slate;
  &:focus {
    border-bottom: 1px solid $silver;
  }
  & + .Input-icon--datePicker {
    cursor: pointer;
    color: $oslo;
  }
}

//Calendar
.Calendar {
  margin-top: $spacing-xs;
  padding: $spacing-xs;
  width: 100%;
  max-height: none;
  min-width: 15rem;
}
.Calendar-header {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Calendar-headerTitle {
  flex: 1;
  font-weight: $bold;
}
.Calendar-headerButton {
  cursor: pointer;
  text-align: center;
  &.is-disabled {
    cursor: default;
    color: $silver;
  }
}
.Calendar-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  li {
    float: left;
    width: calc(100% / 7 - (2 * #{$calendarCellMargin}));
    max-width: $calendarCellSize;
    margin: $calendarCellMargin;
    text-align: center;
    &:nth-child(7n+1) {
      clear: left;
    }
  }
}
.Calendar-list--weekdays {
  font-size: $font-s;
  color: $oslo;
  border-bottom: 1px solid $athens;
  li {
    height: $line-height;
    line-height: $line-height;
  }
}
.Calendar-list--days {
  padding-top: $spacing-xxs;
  padding-bottom: $spacing-xs;
  li {
    position: relative;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-s;
    &.is-date {
      cursor: pointer;
      &:hover:not(.is-selected):not(.is-disabled) {
        background: $sparta;
      }
    }
    &.is-disabled {
      cursor: default;
      color: $silver;
    }
    &.is-today:not(.is-disabled) {
      background: $sparta;
      font-weight: $bold;
    }
    &.is-selected {
      background: $green !important;
      color: $white !important;
    }
  }
}
</style>
