<template>
  <div v-if="filter">

    <!-- Who for -->
    <filter-item
      :filter="filter"
      filter-key="whoFor"
      label="Feedback for"
      icon="domain"
    >
      <radio-buttons
        :model="filter.whoFor"
        :options="whoForOptions"
        @change="filter.update('whoFor', $event.value)"
      />
    </filter-item>

    <!-- Shown on website -->
    <filter-item
      :filter="filter"
      filter-key="showOnWebsite"
      label="On website"
      icon="link"
    >
      <radio-buttons
        :model="filter.showOnWebsite"
        :options="showOnWebsiteOptions"
        @change="filter.update('showOnWebsite', $event.value)"
      />
    </filter-item>
  </div>
</template>

<script>
import FeedbackFor from '@/constants/feedback-for'
import FilterService from '@/services/filter'

export default {
  data: () => ({
    filter: FilterService.get('feedback'),
    whoForOptions: FeedbackFor,
    showOnWebsiteOptions: [
      {
        value: null,
        label: 'Either',
      },
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  }),
}
</script>
