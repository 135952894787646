import BaseError from './base'

/**
 * Response error
 */
export default class ResponseError extends BaseError {

  /**
   * Constructor
   */
  constructor(message, data, status) {

    //Response given as message?
    if (typeof message === 'object') {
      const response = message

      //Set vars
      message = response.data.message
      data = response.data.data || null
      status = status || response.status
    }

    //Parent constructor
    super(message, data, status)

    //Ignore response errors in Sentry and in unhandled rejections
    this.ignoreInSentry = true
    this.ignoreUnhandledRejection = true
  }
}
