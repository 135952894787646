<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="true"
    >
      <template v-slot:filters>
        <feedback-filters />
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--message">
          Feedback
        </div>
        <div class="Table-col Table-col--sentiment">
          <!-- Intentionally flipped -->
        </div>
        <div class="Table-col Table-col--member">
          Member
        </div>
        <div class="Table-col Table-col--date">
          <sort
            :sort="filter.sort"
            field="date"
            @toggle="filter.update('sort', $event.sort)"
          >Date</sort>
        </div>
        <div class="Table-col Table-col--showOnWebsite">
          <sort
            :sort="filter.sort"
            field="showOnWebsite"
            @toggle="filter.update('sort', $event.sort)"
          >On website</sort>
        </div>
        <div class="Table-col Table-col--options">&nbsp;</div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--sentiment"><span/></div>
          <div class="Table-col Table-col--message"><span/></div>
          <div class="Table-col Table-col--member"><span/><small/></div>
          <div class="Table-col Table-col--date"><span/><small/></div>
          <div class="Table-col Table-col--showOnWebsite"><span/></div>
          <div class="Table-col Table-col--options">
            <i class="Icon">more_vert</i>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="item in feedback"
          :key="item.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--sentiment">
            {{item.sentiment}}
          </div>
          <div class="Table-col Table-col--message clickable" @click="edit(item)">
            {{item.message}}
          </div>
          <div class="Table-col Table-col--member">
            {{item.member ? item.member.name : 'Unknown'}}
            <a
              v-if="item.member && item.member.email"
              :href="`mailto:${item.member.email}`"
              target="_blank"
            >
              <i class="Icon Icon--s Icon--inline Icon--faint">mail</i>
            </a>
            <small class="Table-sub">
              {{item.club ? item.club.name : 'Unknown'}}
            </small>
          </div>
          <div class="Table-col Table-col--date">
            {{item.date | moment}}
            <small class="Table-sub">
              {{item.date | moment('fromNow')}}
            </small>
          </div>
          <div class="Table-col Table-col--showOnWebsite">
            <toggle
              :model="item.showOnWebsite"
              @change="updateShowOnWebsite(item, $event.value)"
            />
          </div>
          <div class="Table-col Table-col--options">
            <feedback-options
              :feedback="item"
              @edit="edit(item)"
              @remove="remove(item)"
            ></feedback-options>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>
  </div>
</template>

<script>
import FeedbackApi from '@/api/feedback.api'
import {APP} from '@/constants/feedback-for'
import FeedbackOptions from './feedback-options'
import FeedbackFilters from './feedback-filters'
import FeedbackEdit from './modals/edit'
import FeedbackRemove from './modals/remove'

export default {
  components: {
    FeedbackOptions,
    FeedbackFilters,
  },
  data() {
    return {

      //Page data
      title: 'Feedback',
      type: 'feedback',

      //Pagination
      page: 1,
      itemsPerPage: 50,
      totalItems: 0,

      //Flags
      isLoading: true,

      //Fields to query
      fields: [
        'club', 'member', 'message', 'sentiment', 'date',
        'userAgent', 'showOnWebsite',
      ],

      //Data
      feedback: [],
    }
  },
  computed: {
    hasItems() {
      return this.feedback.length > 0
    },
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await this.loadFeedback()

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('feedback')

      //Set defaults
      this.filter.setDefaults({
        whoFor: APP,
        search: '',
        sort: '-date',
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadFeedback()
    },

    /**
     * Load feedback
     */
    async loadFeedback(extra) {

      //Clear current list and flag as loading
      this.feedback = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter(extra)

      //Query feedbacks
      await FeedbackApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()
      const fields = this.fields.join(',')
      const {page, itemsPerPage} = this

      //Append fields
      if (fields) {
        filter.fields = fields
      }

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage
        filter.offset = (page - 1) * itemsPerPage
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {meta, feedback} = data

      //Set properties and flags
      this.feedback = feedback
      this.totalItems = meta.total
    },

    /**
     * Update show on website flag
     */
    updateShowOnWebsite(feedback, showOnWebsite) {

      //Update on item directly to get snappy feedback and patch in background
      feedback.showOnWebsite = showOnWebsite
      feedback.patch({showOnWebsite})
    },

    /**
     * Edit
     */
    edit(feedback) {

      //Create save handler
      const onSave = data => feedback.patch(data)

      //Open editing modal
      this.$modal.show(FeedbackEdit, {
        feedback, onSave,
      })
    },

    /**
     * Remove
     */
    remove(feedback) {

      //Create handler
      const onConfirm = () => feedback
        .remove()
        .then(() => this.onRemoved(feedback))

      //Open confirmation modal
      this.$modal.show(FeedbackRemove, {
        feedback, onConfirm,
      })
    },

    /**
     * On removed handler
     */
    onRemoved(item) {

      //Show notice
      this.$notice.show(`Feedback removed`)

      //Remove item from list
      const i = this.feedback.findIndex(c => c.isSame(item))
      if (i !== -1) {
        this.feedback.splice(i, 1)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.Table-col--message {
  flex: 1;
  overflow: hidden;
  max-height: 3.25rem;
}
.Table-col--sentiment {
  flex: 0 0 2rem;
}
.Table-col--member {
  flex: 0 0 15rem;
}
.Table-col--date {
  flex: 0 0 8.5rem;
}
.Table-col--showOnWebsite {
  flex: 0 0 7rem;
}
</style>
