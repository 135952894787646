<template>
  <div v-if="filter">

    <!-- Type -->
    <filter-item
      :filter="filter"
      filter-key="type"
      label="Type"
      icon="domain"
    >
      <div class="CheckBoxGroup">
        <check-box
          v-for="type in typeOptions"
          :key="type.value"
          :model="filter.type"
          :checked-value="type.value"
          :is-array="true"
          @change="filter.update('type', $event.value)"
        >
          {{type.label}}
        </check-box>
      </div>
    </filter-item>

  </div>
</template>

<script>
import FilterService from '@/services/filter'
import moment from '@/services/moment'

export default {
  data: () => ({
    filter: FilterService.get('system'),
    typeOptions: [
      {
        value: 'ict',
        label: 'ICT',
      },
      {
        value: 'dynalite',
        label: 'Dynalite',
      },
      {
        value: 'he900',
        label: 'HE900',
      },
      {
        value: 'he600',
        label: 'HE600',
      },
    ],

    //Today
    today: moment().startOf('day'),
  }),
}
</script>
