import BaseApi from './base.api'

/**
 * Club API service
 */
class AuthApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/auth',
    })
  }

  /**
	 * Obtain token
	 */
  async token(data) {
    return this
      .post(`token`, data, {
        ignore401: true,
        withCredentials: true,
      })
      .then(data => {
        const {
          access_token: accessToken,
          token_type: tokenType,
          expires_in: expiresIn,
          scope,
        } = data
        return {accessToken, tokenType, expiresIn, scope}
      })
  }

  /**
   * Revoke current refresh token
   */
  revoke() {
    return this
      .post(`revoke`, null, {
        ignore401: true,
        ignoreCache: true,
      })
  }

  /**
   * Revoke all refresh tokens
   */
  revokeAll() {
    return this
      .post(`revoke/all`, null, {
        ignore401: true,
        ignoreCache: true,
      })
  }
}

/**
 * Export singleton instance
 */
export default new AuthApi()
