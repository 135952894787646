
/**
 * Helper to join paths
 */
export function join(...args) {
  const paths = []
  for (let path of args) {
    if (path === null || path === undefined) {
      continue
    }
    path = String(path)
    if (path !== '') {
      paths.push(path)
    }
  }
  return paths
    .join('/')
    .replace(/([^:])(\/\/+)/g, '$1/')
    .replace(/^\/\//, '/')
}
