import merge from 'deepmerge'
import base from './base'

/**
 * Development environment configuration
 */
export default merge.all([base, {

  //TEMP club identifier so we can login
  club: 'mock',

  //Admin portal
  portal: {
    baseUrl: 'http://localhost:8084',
  },

  //App
  app: {
    baseUrl: 'http://localhost:8080',
  },

  //API
  api: {
    baseUrl: 'http://localhost:8081/api',
  },

  //Domains
  domains: {
    base: 'helloclub.com',
    generic: 'app.helloclub.com',
  },
}])
