<template>
  <card
    title="Account owner"
  >
    <template v-slot:title>
      <i
        class="Icon Icon--faint Icon--s Icon--inline clickable"
        @click="edit()"
        v-tooltip.bottom="'Edit account owner'"
      >edit</i>
    </template>
    <grid rows="2" row-spacing="xs">
      <icon-label icon="admin_panel_settings" label="Account owner">
        {{club.accountOwner.name}}
      </icon-label>
      <icon-label icon="alternate_email" label="Email">
        <a :href="`mailto:${club.accountOwner.email}`" v-if="club.accountOwner.email">{{club.accountOwner.email}}</a>
        <span v-else>None</span>
      </icon-label>
    </grid>
  </card>
</template>

<script>
import ModalEditAccountOwner from '../modals/edit-account-owner'

export default {
  props: {
    club: {
      type: Object,
      default: () => null,
    },
    admins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

      //Flags
      isSaving: false,
    }
  },
  methods: {

    /**
     * Edit plan details
     */
    edit() {

      //Get data
      const {club, admins} = this

      //Create save handler
      const onSave = async (model) => {
        await club.patch(model)
      }

      //Open editing modal
      this.$modal.show(ModalEditAccountOwner, {
        club, admins, onSave,
      })
    },
  },
}
</script>

<style lang="scss">
</style>
