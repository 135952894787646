<template>
  <modal-confirm
    type="danger"
    :title="`Remove ${club.type}`"
    label-confirm="Remove"
    :on-confirm="onConfirm"
    :challenge="club.isTrial ? null : club.identifier"
    challenge-hint="the club identifier"
    @close="$emit('close')"
  >
    <p>Are you sure you want to remove the {{club.type}} <strong>{{club.name}}</strong>?</p>
  </modal-confirm>
</template>

<script>
import ModalConfirm from '@/components/shared/modals/modal-confirm'

export default {
  emits: [
    'close',
  ],
  components: {
    ModalConfirm,
  },
  props: {
    club: {
      type: Object,
    },
    onConfirm: {
      type: Function,
    },
  },
}
</script>
