<template>
  <div
    class="PageSearch Input Input--faux"
    :class="{'PageSearch--isFocussed': isFocusOnSearch}"
  >
    <i
      class="Icon Icon--faint PageAction-icon clickable"
      @click="search()"
    >search</i>
    <input
      type="search"
      ref="searchInput"
      class="Input Input--embedded"
      :placeholder="`Search ${plural}`"
      v-model="query"
      v-debounce:500ms="search"
      @search="search()"
      @focus="isFocusOnSearch = true"
      @blur="isFocusOnSearch = false"
    >
  </div>
</template>

<script>

export default {
  props: {
    filter: {
      type: Object,
      default: () => null,
    },
    plural: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      query: '',
      isFocusOnSearch: false,
    }
  },
  methods: {

    /**
     * Santise
     */
    sanitise(query) {
      const match = query.match(/ObjectId\("([a-f0-9]{24})"\)/)
      if (match) {
        return match[1]
      }
      return query
    },

    /**
     * Search
     */
    search() {

      //Focus if there's no search input
      if (!this.query) {
        this.$refs.searchInput.focus()
      }

      //Santise input
      this.query = this.sanitise(this.query)

      //Nothing changed
      if (this.filter.search === this.query) {
        return
      }

      //Update filter
      this.filter.update('search', this.query)
    },
  },
}
</script>

<style lang="scss">
.PageSearch {
  margin-left: $spacing-m;
  width: 100%;
  position: relative;
  border-bottom: 0;
  background-color: $athens;
  border-radius: $radius-s;
  transition: background-color .2s ease-in-out;
  .Icon {
    margin-left: $spacing-s;
    &:hover {
      color: $oslo;
    }
  }
}
.PageSearch:hover, .PageSearch--isFocussed {
  background-color: $white;
  .Icon {
    color: $oslo;
  }
}
</style>
