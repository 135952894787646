import BaseApi from './base.api'
import RequestLog from '@/models/request-log.model'

/**
 * Request log API service
 */
class RequestLogApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/requestLog',
      dataKey: 'requestLogs',
      Model: RequestLog,
    })
  }
}

/**
 * Export singleton instance
 */
export default new RequestLogApi()
