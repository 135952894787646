
/**
 * Modal service
 */
class ModalService {

  /**
   * Set defaults
   */
  setDefaults(defaults = {}) {
    this.defaults = defaults
  }

  /**
   * Set service
   */
  setService(service) {
    this.service = service
  }

  /**
   * Show a modal
   */
  show(modal, props, params, events) {

    //Merge defaults into params
    params = Object.assign({}, this.defaults, params || {})

    //Call underlying service
    this.service.show(modal, props, params, events)
  }

  /**
   * Hide
   */
  hide(name, params) {
    this.service.hide(name, params)
  }

  /**
   * Toggle
   */
  toggle(name, params) {
    this.service.toggle(name, params)
  }
}

/**
 * Export singleton instance
 */
export default new ModalService()
