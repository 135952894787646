import BaseModel from './base.model'
import Club from './club.model'
import Member from './member.model'
import FeedbackApi from '@/api/feedback.api'

/**
 * Feedback model
 */
export default class Feedback extends BaseModel {

  /**
   * Setup
   */
  setup() {

    //Parent method
    super.setup()

    //Sub models
    this.subModels = {
      club: Club,
      member: Member,
    }
  }

  /**************************************************************************
   * API methods
   ***/

  /**
   * Save
   */
  save(data) {
    if (!this.id) {
      throw new Error(`Cannot create new feedback`)
    }
    return this.update(data)
  }

  /**
   * Update
   */
  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data)
    const {id} = this

    //Update
    return FeedbackApi
      .update(id, json)
      .then(data => this.fromJSON(data))
  }

  /**
   * Patch
   */
  patch(data) {

    //Get ID
    const {id} = this

    //Update
    return FeedbackApi
      .update(id, data)
      .then(data => this.fromJSON(data))
  }

  /**
   * Remove
   */
  remove() {

    //Get ID
    const {id} = this

    //Update
    return FeedbackApi
      .remove(id)
  }
}
