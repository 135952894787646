<template>
  <a class="ServiceLink" :href="link">
    <div class="ServiceLink-icon">
      <img :src="`/images/services/${icon}`">
    </div>
    <div class="ServiceLink-text">
      <span class="ServiceLink-label">{{name}}</span>
      <small class="ServiceLink-description">{{description}}</small>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    link: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="scss">
.ServiceLink {
  display: flex;
  @include verticallySpaced;
  color: $slate;
  align-items: center;
}
.ServiceLink-icon {
  flex: 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
}
.ServiceLink-text {
  display: flex;
  flex-direction: column;
  margin-left: $spacing-m;
}
.ServiceLink-label {
  font-weight: $bold;
  padding-bottom: $spacing-xxs;
}
.ServiceLink-description {
  color: $oslo;
}
</style>
