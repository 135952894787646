import BaseApi from './base.api'
import Member from '@/models/member.model'

/**
 * Member API service
 */
class MemberApi extends BaseApi {

  /**
   * Constructor
   */
  constructor() {

    //Call parent constructor
    super({
      basePath: '/member',
      dataKey: 'members',
      Model: Member,
    })
  }
}

/**
 * Export singleton instance
 */
export default new MemberApi()
