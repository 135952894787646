
/**
 * Stripe billing intervals
 */
export const MONTH = 'month'
export const YEAR = 'year'

/**
 * Default object
 */
export default [
  {
    value: MONTH,
    label: 'Monthly',
  },
  {
    value: YEAR,
    label: 'Yearly',
  },
]
