<template>
  <div class="Page">
    <page-header
      :title="title"
      :type="type"
      :crumbs="crumbs"
      :filter="filter"
      :total-items="totalItems"
      :is-loading="isLoading"
      :show-search="true"
    >
      <template v-slot:filters>
        <system-filters />
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div class="Block">
      <button
        class="Button Button--primary"
        @click="showUpdateSystemsModal()"
        :disabled="isUpdating"
      >
        Update all systems
        <spinner v-if="isUpdating" />
      </button>
    </div>

    <div class="Table">
      <div class="Table-header">
        <div class="Table-col Table-col--name">
          Name
        </div>
        <div class="Table-col Table-col--type">
          Type
        </div>
        <div class="Table-col Table-col--features">
          Features
        </div>
        <div class="Table-col Table-col--tags">
          Tags
        </div>
        <div class="Table-col Table-col--doors">
          Doors
        </div>
      </div>
      <div v-if="isLoading">
        <div
          v-for="index in 10"
          :key="index"
          class="Table-row Table-row--mock"
        >
          <div class="Table-col Table-col--name"><span/></div>
          <div class="Table-col Table-col--type"><span/></div>
          <div class="Table-col Table-col--features"><span/></div>
          <div class="Table-col Table-col--tags"><span/></div>
          <div class="Table-col Table-col--doors"><span/></div>
          <div class="Table-col Table-col--options">
            <i class="Icon">more_vert</i>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="system in systems"
          :key="system.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--name">
             {{system.name}}
            <br>
            <small class="Table-sub">
              {{system.channel}}
              <router-link
                class="Table-link"
                :to="`/clubs/edit/${system.club}`"
                @click.stop
              >
                <i class="Icon Icon--xs Icon--inline Icon--faint">apartment</i>
              </router-link>
            </small>
          </div>
          <div class="Table-col Table-col--type">
            <pill class="TypePill" :class="`TypePill--${system.type}`">
              {{readableTypeName(system)}}
            </pill>
          </div>
          <div class="Table-col Table-col--features">
            <i class="Icon Icon--muted" v-if="system.hasDoorControl" v-tooltip.bottom="'Door control'">meeting_room</i>
            <i class="Icon Icon--muted" v-if="system.hasLightControl" v-tooltip.bottom="'Light control'">tungsten</i>
          </div>
          <div class="Table-col Table-col--tags">
            <i
              class="Icon Icon--muted"
              v-for="tagType in system.tagTypes"
              :key="tagType.value"
              v-tooltip.bottom="tagType"
            >
              {{tagTypeIcon(tagType)}}
            </i>
          </div>
          <div class="Table-col Table-col--doors">
            <span v-for="(door, index) in system.doors" :key="door.value">{{door.name}}<span v-if="index + 1 < system.doors.length">, &nbsp;</span></span>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>
  </div>
</template>

<script>
import SystemApi from '@/api/system.api'
import SystemFilters from './system-filters'
import ModalUpdateSystems from './modals/update-systems'

export default {
  components: {
    SystemFilters,
  },
  data() {
    return {

      //Page data
      title: 'Systems',
      type: 'system',

      //Pagination
      page: 1,
      itemsPerPage: 50,
      totalItems: 0,

      //Flags
      isLoading: true,
      isUpdating: false,

      //Data
      systems: [],
    }
  },
  computed: {
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    crumbs() {
      return [
        {
          title: this.title,
          route: {name: this.$route.name},
        },
      ]
    },
  },
  async created() {

    //Setup filter and page
    this.setupFilter()
    this.setupPage()

    //Load data
    await this.loadSystems()

    //Dispatch page loading event
    this.$store.dispatch('page/loading', false)
  },
  methods: {

    /**
     * Setup filter
     */
    setupFilter() {

      //Get filter
      this.filter = this.$filter.get('system')

      //Set defaults
      this.filter.setDefaults({
      })

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1)
      })
    },

    readableTypeName(item) {
      const {type, version} = item
      if (type === 'dynalite') {
        return 'Dynalite'
      }
      if (type === 'he900') {
        if (version === 2) {
          return 'Hawkeye 900 V2'
        }
        else {
          return 'Hawkeye 900 V1'
        }
      }
      if (type === 'he600') {
        return 'Hawkeye 600'
      }
      return 'ICT'
    },

    tagTypeIcon(tagType) {
      switch (tagType) {
        case 'card':
          return 'credit_card'
        case 'pin':
          return 'dialpad'
        case 'tag':
          return 'contactless'
        case 'nfc':
          return 'speaker_phone'
        case 'ibutton':
        default:
          return 'vpn_key'
      }
    },

    /**
     * Setup page
     */
    setupPage() {

      //Setup page details
      const {title} = this
      this.$store.dispatch('page/setup', {title})
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page)
      this.loadSystems()
    },

    /**
     * Load systems
     */
    async loadSystems() {

      //Clear current list and flag as loading
      this.systems = []
      this.isLoading = true

      //Get filter and query data
      const filter = this.makeFilter()

      //Query request logs
      await SystemApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false)
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON()
      const {page, itemsPerPage} = this

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage
        filter.offset = (page - 1) * itemsPerPage
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra)
      }

      //Return filter
      return filter
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {meta, systems} = data

      //Set properties and flags
      this.systems = systems
      this.totalItems = meta.total
    },

    /**
     * Load systems
     */
    async showUpdateSystemsModal() {

      //Extract data
      const {filter} = this

      //Set flag
      this.isUpdating = true

      const onSave = data => {
        SystemApi
          .updateMany({types: data.types})
          .finally(() => this.isUpdating = false)
      }

      //Open modal
      this.$modal
        .show(ModalUpdateSystems, {
          filter, onSave,
        })
    },
  },
}
</script>

<style scoped lang="scss">
.Table-col--name {
  max-width: 15rem;
}
.Table-col--type {
  max-width: 10rem;
}
.Table-col--features {
  max-width: 7rem;
}
.Table-col--tags {
  max-width: 10rem;
}

.TypePill--ict {
  background: $green;
}
.TypePill--dynalite {
  background: $blue;
}
.TypePill--he900 {
  background: $yellow;
}
.TypePill--he600 {
  background: $red;
}
</style>
